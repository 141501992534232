import React, { useContext } from 'react';
import { Helmet } from "react-helmet-async";
import { WhiteLabelConfigurationContext } from 'src/contexts/ClientConfiguration';


const MetaTags = ({ settings, theme }) => {
    const metaFields = [
        { name: 'title', key: 'META_TITLE' },
        { name: 'description', key: 'META_DESCRIPTION' },
        { name: 'tagline', key: 'TAGLINE' }
      ];
      const { metaTitle, metaDescription,tagline, faviconUrl } = useContext(WhiteLabelConfigurationContext);
  
    return (
      <Helmet>
        {/* Color scheme and theme color meta tags */}
        <meta name="title" content={metaTitle} />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="tagline" content={tagline} />
        <link rel="icon" type="image/png" href={faviconUrl} />
        <link rel="icon" type="image/png" href={faviconUrl} sizes="16x16" />
        <link rel="icon" type="image/png" href={faviconUrl} sizes="32x32" />


        <meta name="color-scheme" content={settings?.paletteMode} />
        <meta name="theme-color" content={theme?.palette?.neutral[900]} />
  
        {/* Dynamic meta tags based on the whiteLabelConfigurationData */}
        {/* {metaFields.map(({ name, key }) => {
          const content = whiteLabelConfigurationData?.[key] ?? null;
          return content && (
            <meta key={name} name={name} content={content} />
          );
        })} */}
      </Helmet>
    );
  };
  
  export default MetaTags;