import { BlobServiceClient } from "@azure/storage-blob";
import { storageConfigs } from "src/storageConfig";
import { v4 as uuid } from "uuid";

// Function to upload file to Azure Blob Storage
export const uploadFileToAzureBlob = async (file, name , storageType) => {

  try {
   
    const config = storageConfigs[storageType];
    const { storageAccountUrl, sasToken, containerName } = config;
 
    const blobServiceClient = new BlobServiceClient(
      `${storageAccountUrl}?${sasToken}`
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlockBlobClient(name);

    await blobClient.uploadData(file);
    return blobClient.url;
   
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("File upload failed.");
  }
};



// import { BlobServiceClient } from "@azure/storage-blob";
// import { v4 as uuid } from "uuid";

// // Function to upload file to Azure Blob Storage
// export const uploadFileToAzureBlob = async (file, containerName) => {
//   try {
//     // Azure Blob Service Connection String
//     // Azure Blob Storage SAS URL
//     const AZURE_STORAGE_CONNECTION_STRING =
//       "https://b2brocketstorage.blob.core.windows.net/whitelable-logos?sp=racwdli&st=2025-01-22T11:46:06Z&se=2026-01-21T19:46:06Z&spr=https&sv=2022-11-02&sr=c&sig=jab2i%2FkivC6fdl6JVqCJ6XFOyr0lImlLDhiuBqCL%2FYg%3D";
//     // Replace with your actual connection string

//     // Create a BlobServiceClient instance
//     const blobServiceClient = new BlobServiceClient(
//       AZURE_STORAGE_CONNECTION_STRING
//     );

//     // Get a reference to the container
//     const containerClient = blobServiceClient.getContainerClient(containerName);

//     // Generate a unique blob name using uuid
//     const blobName = `${uuid()}.${file.type.split("/")[1]}`;
//     const blockBlobClient = containerClient.getBlockBlobClient(blobName);

//     // Upload the file to Blob Storage
//     const result = await blockBlobClient.uploadBrowserData(file, {
//       blobHTTPHeaders: {
//         blobContentType: file.type, // Set the correct content type for the file
//       },
//     });

//     console.log("Upload result:", result);

//     // Construct the URL to access the uploaded image
//     const uploadedImageUrl = `https://${blobServiceClient.accountName}.blob.core.windows.net/${containerName}/${containerName}/${blobName}`;

//     return uploadedImageUrl; // Return the URL of the uploaded file
//   } catch (error) {
//     console.error("Error uploading file:", error);
//     throw new Error("File upload failed.");
//   }
// };
