import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography, TextField, Button, Grid } from "@mui/material";
import { API_SERVICE_BACKEND } from "src/config";
import { getSessionStorage } from "src/utils/storage";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import toast from "react-hot-toast";

import UploadFiles from "src/components/whiteLable/UploadFiles";
import { uploadFileToAzureBlob } from "./uploadFileToAzureBlob";
import { debounce } from "lodash";
import { Seo } from "src/components/seo";

const BrandingManagement = () => {
  const [formData, setFormData] = useState({
    companyName: "B2B Rocket",
    logoURL: "",
    favIcon: "",
    description: "",
    title: "",
    tagLine: "",
    themeColors: {
      PrimaryTextColor: "#000000",
      SecondaryTextColor: "#6C737F",
      PrimaryBrandColor: "#004EEB",
      SecondaryBrandColor: "#5862ff",
    },
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFavFile, setSelectedFavFile] = useState(null);
  const [status, setStatus] = useState(false);
  const [dataStore, setDataStore] = useState(null);

  const { metaTitle, metaDescription, tagline, companyName } = useContext(
    WhiteLabelConfigurationContext
  );

  const email = getSessionStorage("userEmail");

  

  const { whiteLableId, getAllWhiteLabelClientConfigurations } = useContext(
    WhiteLabelConfigurationContext
  );

  const getAllWhiteLabelClients = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getwhiteLabelConfig/${whiteLableId}`
      );
      const data = await response.json();
      setSelectedFile(data?.data?.info?.logoUrl);
      setSelectedFavFile(data?.data?.info?.favIcon);
      setDataStore(data?.data??{})
      setFormData({
        description: data?.data.seo?.description || metaDescription,
        tagLine: data?.data.seo?.tagLine || tagline,
        title: data?.data.seo?.title || metaTitle,
        companyName: data?.data?.info?.companyName || "",
        logoURL: data?.data?.info?.logoUrl || "",
        favIcon: data?.data?.info?.favIcon || "",
        themeColors: {
          PrimaryTextColor: data?.data?.theme?.primaryTextColor || "#000000",
          SecondaryTextColor: data?.data?.theme?.secondaryTextColor || "#6C737F",
          PrimaryBrandColor: data?.data?.theme?.primaryBrandColor || "#004EEB",
          SecondaryBrandColor: data?.data?.theme?.secondaryBrandColor || "#5862ff",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllWhiteLabelClients();
  }, [whiteLableId]);

  const handleSubmit = async () => {
    try {
      let uploadedLogoUrl, uploadedFavIconUrl;
        const now = new Date().toISOString();
   
        if (typeof selectedFile !== "string" && selectedFile) {
        const newName = `${now}_${selectedFile.name}`;
        uploadedLogoUrl = await uploadFileToAzureBlob(selectedFile, newName, "whitelableLogos");
        // uploadedLogoUrl = await uploadFileToAzureBlob(selectedFile, "whitelableLogos");

        setFormData((prev) => ({ ...prev, logoURL: uploadedLogoUrl }));
      }

      if (typeof selectedFavFile !== "string" && selectedFavFile) {
        const newName = `${now}_${selectedFavFile.name}`;
        uploadedFavIconUrl = await uploadFileToAzureBlob(selectedFavFile,newName, "whitelableLogos");
        // uploadedFavIconUrl = await uploadFileToAzureBlob(selectedFavFile, "whitelableLogos");


        setFormData((prev) => ({ ...prev, favIcon: uploadedFavIconUrl }));
      }

      let requestData = {
        email: email,
        seo: {
          description: formData?.description || metaDescription,
          tagLine: formData?.tagLine || tagline,
          title: formData?.title || metaTitle,
        },
        info: {
          companyName: formData?.companyName,
        },
        theme: {
          primaryTextColor: formData?.themeColors?.PrimaryTextColor,
          secondaryTextColor: formData?.themeColors?.SecondaryTextColor,
          primaryBrandColor: formData?.themeColors?.PrimaryBrandColor,
          secondaryBrandColor: formData?.themeColors?.SecondaryBrandColor,
        },
      };

      if (selectedFile) {
        requestData.info.logoUrl = typeof selectedFile === "string" ? formData?.logoURL : uploadedLogoUrl;
      }

      if (selectedFavFile) {
        requestData.info.favIcon = typeof selectedFavFile === "string" ? formData?.favIcon : uploadedFavIconUrl;
      }

      const response = await fetch(`${API_SERVICE_BACKEND}/handleWhiteLabelConfig`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();

      if (response.status === 200) {
        toast.success(response.message ?? "Theme updated/created successfully!", {
          autoClose: 3000,
        });
        getAllWhiteLabelClients();
        getAllWhiteLabelClientConfigurations(whiteLableId);
      } else {
        toast.error(response.message ?? "There was an error updating the theme.", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error submitting theme data:", error);
    }
  };
  const debouncedSubmit = useCallback(
    debounce(() => setStatus(!status), 3000), 
    [status]
  );
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "companyName" || name === "logoURL" || name === "favIcon") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      debouncedSubmit();
      return () => debouncedSubmit.cancel();
    } else if (name.startsWith("themeColors")) {
      setFormData((prevData) => ({
        ...prevData,
        themeColors: {
          ...prevData.themeColors,
          [name.split(".")[1]]: value,
        },
      }));
      debouncedSubmit();
      return () => debouncedSubmit.cancel();
    }
    debouncedSubmit();
    return () => debouncedSubmit.cancel();
  };
 
  useEffect(() => {
    if(dataStore){
      handleSubmit()
    }
  }, [status]);

  const pageTitle = `Partner/White Label: Branding Management - ${metaTitle || companyName }`;

  return (
    <>
    <Seo title={pageTitle} />
    <Box sx={{ paddingX: "30px" }}>
      <Box sx={{ display: "flex", alignItems: "center", my: "20px" }}>
        <Typography sx={{ fontSize: "32px", fontWeight: 700, color: "#111927" }}>
          Branding Management
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            id="companyName"
            label="Company Name"
            variant="outlined"
            fullWidth
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            sx={{ mb: 3 }}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: "-10px  " }}>
          <Typography sx={{ fontSize: "18px", fontWeight: 700, color: "#111927" }}>
            Logos
          </Typography>
        </Grid>
        
        <Grid item xs={3}>
          <Typography>Logo:</Typography>
          <UploadFiles setStatus={setStatus} debouncedSubmit={debouncedSubmit} status={status} setSelectedFile={setSelectedFile} selectedFile={selectedFile} />
        </Grid>
        
        <Grid item xs={3}>
          <Typography>Favicon:</Typography>
          <UploadFiles setStatus={setStatus} debouncedSubmit={debouncedSubmit}  status={status} setSelectedFile={setSelectedFavFile} selectedFile={selectedFavFile} />
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ fontSize: "18px", fontWeight: 700, color: "#111927" }}>
            Brand Colors
          </Typography>
        </Grid>

        {Object.keys(formData.themeColors).map((color, index) => (
          <Grid item xs={6} key={index}>
            <TextField
              required
              type="color"
              id={`themeColor${index}`}
              label={color.replace(/([A-Z])/g, " $1")}
              variant="outlined"
              fullWidth
              name={`themeColors.${color}`}
              value={formData.themeColors[color]}
              onChange={handleChange}
            />
          </Grid>
        ))}
         <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: "20px", width: "100%" }}
            onClick={() => {
              setSelectedFile(null);
              setSelectedFavFile(null);
              setFormData((prevData) => ({
                ...prevData,
                companyName: "B2B Rocket",
                logoURL: "",
                favIcon: "",
                themeColors: {
                  PrimaryTextColor: "#000000",
                  SecondaryTextColor: "#6C737F",
                  PrimaryBrandColor: "#004EEB",
                  SecondaryBrandColor: "#5862ff",
                },
              }));
              debouncedSubmit();
              return () => debouncedSubmit.cancel();
            }}
          //   onClick={() =>{
          //     setSelectedFile(null),
          //     setSelectedFavFile(null),
          //     setFormData((prevData) => ({
          //       ...prevData,
          //       companyName: "B2B Rocket",
          //       logoURL: "",
          //       favIcon: "",
          //       themeColors: {
          //         PrimaryTextColor: "#000000",
          //         SecondaryTextColor: "#6C737F",
          //         PrimaryBrandColor: "#004EEB",
          //         SecondaryBrandColor: "#5862ff",
          //       },
          //     }))
          //   }
          // }
          >
            Reset Theme
          </Button>
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default BrandingManagement;
