import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { API_SERVICE_BACKEND } from "src/config";
import { Close } from "@mui/icons-material";
import { toast } from "react-hot-toast";

const ResetPasswordModal = ({ setOpen, open }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    setOpen({ open: false, data: null });
  };
  const handleSendEmail = async () => {
    const { email } = open.data;
    setIsLoading(true);

    try {
      const requestData = {
        email: email,
        whiteLabelUserId: open?.data?.whiteLabelUserId,
      };
      const response = await fetch(
        `${API_SERVICE_BACKEND}/sendResetPasswordEmailWhiteLabel`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        toast.success("Reset password email sent successfully!");
        handleClose();
        setIsLoading(false);
      } else {
        toast.error("Failed to send email.");
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message || "An unexpected error occurred.");
      setIsLoading(false);
    }
  };
  return (
    <Dialog open={open.open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle> Reset Password</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>
          Are you sure you want to send a reset password email?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={() => handleSendEmail()}
          //   color="error"
          color="primary"
          variant="contained"
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Send Email"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordModal;
