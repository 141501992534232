import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Stack,
  IconButton,
  Card,
  useTheme,
  Divider,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import toast from "react-hot-toast";

import AddPlans from "src/components/whiteLable/AddPlans";
import { API_SERVICE_BACKEND } from "src/config";
import { getSessionStorage } from "src/utils/storage";
import ConfirmDialog from "src/components/whiteLable/ConfirmDialog";
import activeIcon from "src/components/logos/activeIcon.png";
import { Seo } from "src/components/seo";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";

const Plans = () => {
  const theme = useTheme();
  const [open, setOpen] = useState({
    open: false,
    data: null,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    data: null,
  });

  const [plans, setPlans] = useState([]);
  const email = getSessionStorage("userEmail");

  // Fetch data from API to populate form
  const getAllWhiteLabelClients = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/plans?userEmail=${email}`
      );
      const data = await response.json();
      setPlans(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllWhiteLabelClients();
  }, [email]);

  const handleEdit = (data) => {
    setOpen({ open: true, data: data });
  };

  const handleDelete = async (data) => {
    const url = `${API_SERVICE_BACKEND}/plans/${data._id}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      if (response.ok) {
        getAllWhiteLabelClients();
        toast.success(data.message || "Delete plan successfully!");
        setOpenDeleteModal({ open: false, data: null });
      } else {
        toast.error(data.error || "Failed to connect");
      }
    } catch (error) {
      toast.error("Error submitting data");
    }
  };

  const numberFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
  });

  const keyLabelMap = {
    EMAIL_LOOKUPS: "Email Lookups",
    EMAIL_EXPORTS: "Email Exports",
    PHONE_LOOKUPS: "Phone Lookups",
    PHONE_EXPORTS: "Phone Exports",
    EMAIL_SENDING: "Email Sending",
    MULTICHANNEL_OUTREACH: "Multichannel Outreach",
    ACTIVE_CAMPAIGNS_CONTACTS: "Active Campaigns Contacts",
    DATA_ENRICH: "Data Enrichment",
    EMAIL_VALIDATION: "Email Validation",
    DATA_CLEAN_UP: "Data Clean Up",
    AI: "AI",
    AI_BDR: "AI BDR",
    MAILBOX_WARMUP: "Mailbox Warmup",
    AI_AUTO_REPLIES: "AI Auto Replies",
    CRM: "CRM",
    UNIFIED_INBOX: "Unified Inbox",
    UNIFIED_CALENDAR: "Unified Calendar",
    MEETING_SCHEDULER: "Meeting Scheduler",
  };

  const { metaTitle, companyName} = React.useContext(WhiteLabelConfigurationContext);
  const pageTitle = `Partner/White Label: Plans - ${metaTitle || companyName }`;

  return (
    <>
    <Seo title={pageTitle} />
    <Box sx={{ paddingX: "30px" }}>
      <Box sx={{ display: "flex", alignItems: "center", my: "20px" }}>
        <Typography
          sx={{ fontSize: "32px", fontWeight: 700, color: "#111927" }}
        >
          Plans
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", my: "20px" }}>
        <Button
          variant="contained"
          onClick={() => setOpen({ open: true, data: null })}
          sx={{
            marginLeft: "auto",
            color: "#ffffff",
            textTransform: "none",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {" "}
          Add New Plan
        </Button>
      </Box>
      {plans?.length ? (
        <Grid
          container
          spacing="8px"
          sx={{
            paddingRight: "20px",
            paddingBottom: "25px",
            color: theme.palette.text.primary,
          }}
          style={{ marginTop: "0px" }}
        >
          {plans.map((plan, index) => {
            const features = Object.entries(plan.monthlyCredits).map(
              ([key, value]) => ({
                key: keyLabelMap[key] || key,
                value,
              })
            );

            return (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                sx={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Card className="sodhi" style={{ height: "100%" }}>
                  <Stack
                    spacing={2}
                    style={{
                      padding: "20px 20px",
                      height: "100%",
                    }}
                  >
                    {/* Plan Name */}
                    <Box
                      sx={{
                        display: "flex",
                        mb: 2,
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h5">{plan?.planName}</Typography>
                      <Box>
                        <IconButton
                          onClick={() => handleEdit(plan)}
                          aria-label="settings"
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            setOpenDeleteModal({ open: true, data: plan })
                          }
                          aria-label="settings"
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </Box>

                    <Divider
                      sx={{ borderColor: "#ECE9F5", borderWidth: "1px" }}
                    />

                    {/* Feature List */}
                    <Stack spacing={2}>
                      {features.map((feature, idx) => (
                        <>
                          {feature.value !== false && (
                            <div
                              key={idx}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                              }}
                            >
                              <img src={activeIcon} alt="Active Icon" />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingLeft: "10px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: theme.palette.text.primary,
                                    fontSize: "14px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {feature.value !== true
                                    ? numberFormatter.format(feature.value)
                                    : ""}{" "}
                                  {feature.key}
                                </Typography>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        ""
      )}
      <AddPlans
        getAllWhiteLabelClients={getAllWhiteLabelClients}
        setOpen={setOpen}
        open={open}
      />
      <ConfirmDialog
        handleDelete={handleDelete}
        name={`  Are you sure you want to delete the plan "${openDeleteModal?.data?.planName}"`}
        setOpen={setOpenDeleteModal}
        open={openDeleteModal}
      />
    </Box>
    </>
  );
};

export default Plans;
