import * as React from "react";
import { addDays, format, subDays } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { saveAs } from "file-saver";
import DownloadCloud02 from "@untitled-ui/icons-react/build/esm/DownloadCloud02";
// import SearchLgIcon from "@untitled-ui/icons-react/build/esm/SearchLg";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import ChevronUp from "@untitled-ui/icons-react/build/esm/ChevronUp";
import VideoCallRoundedIcon from "@mui/icons-material/VideoCallRounded";
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  SvgIcon,
  Typography,
  CircularProgress,
  useTheme,
  Paper,
  alpha,
  OutlinedInput,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  FormControl,
  Select,
  MenuItem,
  Link,
  Tooltip,
  Popover,
  Autocomplete,
  TextField,
  Pagination,
  InputLabel,
  Menu,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
// import Pagination from "@mui/material/Pagination";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { customersApi } from "src/api/customers";
import { Seo } from "src/components/seo";
import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { useSelection } from "src/hooks/use-selection";
import { CustomerListSearch } from "src/sections/dashboard/customer/customer-list-search";
// import { CustomerListTable } from "src/sections/dashboard/customer/customer-list-table";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import {
  ADMIN_SERVER,
  // API_LOCAL_IP,
  API_SERVICE_BACKEND,
  API_SERVICE_BACKEND_2,
  API_SERVICE_BACKEND_3,
  APP_TIMEZONE,
  COMMUNICATION_SERVER,
  DEFAULT_DAYS_ON_CHART,
  LEADS_PER_PAGE,
  SALES_CHAT,
} from "../../../config";
import { jobsApi } from "src/api/jobs";
import { useAuth } from "src/hooks/use-auth";
import axios from "axios";
// import { cronofyApi } from "src/api/cronofy";
// import { formattedDate, formattedTime } from "../calendar";
import { textFromHTML } from "src/utils/parser";
import TimeLine from "src/components/logos/timeLine.png";
import ZohoIcon from "src/components/logos/zohoIcon.png";

// import Status from "src/components/logos/status.png";
// import Replies from "src/components/logos/Replies.png";
import { useLocation, useNavigate } from "react-router";
import { paths } from "src/paths";
import { TimelLinePage } from "./TimeLinePage";
import { html as htmlParser } from "src/utils/parser";
// import { useSelector } from "react-redux";
// import { useActionData } from "react-router";
import MessageChatSquareIcon from "src/icons/untitled-ui/duocolor/message-chat-square";
import { chunk, debounce } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import moment from "moment";
import DatePicker from "react-datepicker";
import { getSessionStorage } from "src/utils/storage";
import ExpandedRow from "./ExpandedRow";
import {
  ArrowDropDown,
  ArrowDropUp,
  ErrorOutlineOutlined,
  MoreVert,
  WarningAmberOutlined,
} from "@mui/icons-material";
import DateWrapper from "../jobs/dateWrapper";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TimelineDropdown from "src/components/timeline-dropdown";
import ViewEmailReplies from "./viewEmailReplies";
import LeadIcon from "src/components/logos/LeadIcon.svg";
import InterestedIcon from "src/components/logos/interestedIcon.svg";
import BookedIcon from "src/components/logos/bookedIcon.svg";
import CompletedIcon from "src/components/logos/completedIcon.svg";
import WonIcon from "src/components/logos/wonIcon.svg";
import OutOfOfficeIcon from "src/components/logos/logOutIcon.svg";
import WrongPersonIcon from "src/components/logos/wrongPersonIcon.svg";
import NotInterestedIcon from "src/components/logos/notInterestedIcon.svg";
import UnsubscribedIcon from "src/components/logos/unsubscribe.svg";
import OtherIcon from "src/components/logos/othericon.svg";
import LostIcon from "src/components/logos/lostIcon.svg";
import { styled } from "@mui/material/styles";
import Transfer from "./transfer/index";
import { useQuery } from "@tanstack/react-query";
import BlackList from "./blackList";
import { CustomerListDropdown } from "../../../sections/dashboard/customer/customer-list-dropdown";
import noData from "src/components/logos/noData.png";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";

// const regex = new RegExp(
//   /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm
// );

const excludedEmails = [
  "gautamsir076@gmail.com",
  "shiva@leadingly.io",
  "noah@leadingly.io",
  "noah@b2brocket.ai",
];

const useCustomersSearch = () => {
  console.log('checkigngnngg')
  const [state, setState] = useState({
    filters: {
      query: undefined,
      hasAcceptedMarketing: undefined,
      isProspect: undefined,
      isReturning: undefined,
      clicks: false,
      conversations: false,
      meetings: false,
      unsubscribe: false,
    },
    page: 0,
    rowsPerPage: 5,
    sortBy: "updatedAt",
    sortDir: "desc",
  });

  const handleFiltersChange = useCallback((filters) => {
    setState((prevState) => ({
      ...prevState,
      filters,
    }));
  }, []);

  const handleSortChange = useCallback((sort) => {
    setState((prevState) => ({
      ...prevState,
      sortBy: sort.sortBy,
      sortDir: sort.sortDir,
    }));
  }, []);

  const handlePageChange = useCallback((event, page) => {
    // // console.log(page, "page");
    setState((prevState) => ({
      ...prevState,
      page,
    }));
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setState((prevState) => ({
      ...prevState,
      rowsPerPage: parseInt(event.target.value, 10),
    }));
  }, []);

  return {
    handleFiltersChange,
    handleSortChange,
    handlePageChange,
    handleRowsPerPageChange,
    state,
  };
};

const useCustomersStore = (searchState) => {
  const isMounted = useMounted();
  const [state, setState] = useState({
    customers: [],
    customersCount: 0,
  });

  const handleCustomersGet = useCallback(async () => {
    try {
      const response = await customersApi.getCustomers(searchState);

      if (isMounted()) {
        setState({
          customers: response.data,
          customersCount: response.count,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [searchState, isMounted]);

  useEffect(
    () => {
      handleCustomersGet();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchState]
  );

  return {
    ...state,
  };
};

const useCustomersIds = (customers = []) => {
  return useMemo(() => {
    return customers.map((customer) => customer.id);
  }, [customers]);
};

// const MeetingStatusButtons = ({ id, status }) => {
//   const [activeButton, setActiveButton] = useState(status || false);
//   const handleClick = (buttonNumber) => {
//     setActiveButton(buttonNumber);
//   };
//   const handleSubmit = async () => {
//     try {
//       var dataSend = {
//         _id: id,
//         meeting_status: activeButton,
//       };
//       const responseDataLog = await fetch(
//         `${API_SERVICE_BACKEND_3}/updateusermeetings`,
//         {
//           method: "PUT",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(dataSend),
//         }
//       );
//       if (responseDataLog.status === 200 || responseDataLog.status === 201) {
//         toast.success("Meeting Status Update successfully");
//       }
//     } catch (err) {
//       console.log(err);
//       toast.error("Meeting Status Not Update successfully");
//     }
//   };
//   useEffect(() => {
//     if (activeButton !== status) {
//       handleSubmit();
//     }
//   }, [activeButton]);

//   return (
//     <>
//       <Button
//         variant="outlined"
//         style={{
//           marginTop: "5px",
//           fontSize: "13px",
//           color: activeButton ? "green" : "grey",
//           border: activeButton ? "1px solid green" : "1px solid grey",
//         }}
//         onClick={() => handleClick(true)}
//       >
//         Confirm Attendance
//       </Button>

//       <Button
//         variant="outlined"
//         style={{
//           marginTop: "5px",
//           fontSize: "13px",
//           color: activeButton ? "grey" : "green",
//           border: activeButton ? "1px solid grey" : "1px solid green",
//         }}
//         onClick={() => handleClick(false)}
//       >
//         Mark As No-Show
//       </Button>
//     </>
//   );
// };

const MeetingStatusButtons = ({ id, status }) => {
  const [activeButton, setActiveButton] = useState(status || false);
  const handleClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
    handleSubmit(buttonNumber);
  };
  const handleSubmit = async (buttonNumber) => {
    try {
      var dataSend = {
        _id: id,
        meeting_status: buttonNumber,
      };
      const responseDataLog = await fetch(
        `${API_SERVICE_BACKEND_3}/updateusermeetings`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataSend),
        }
      );
      if (responseDataLog.status === 200 || responseDataLog.status === 201) {
        toast.success("Meeting Status Update successfully");
      }
    } catch (err) {
      console.log(err);
      toast.error("Meeting Status Not Update successfully");
    }
  };
  // useEffect(() => {
  //   console.log("status: ", status);
  //   console.log("activeButton: ", activeButton);
  //   if (activeButton !== status) {
  //     handleSubmit();
  //   }
  // }, [activeButton]);
  return (
    <>
      <Button
        variant="outlined"
        color={"success"}
        style={{ marginTop: "5px", fontSize: "13px" }}
        onClick={() => handleClick(true)}
      >
        Confirm Attendance
      </Button>
      <Button
        variant="outlined"
        color={"error"}
        style={{ marginTop: "5px", fontSize: "13px" }}
        onClick={() => handleClick(false)}
      >
        Mark As No-Show
      </Button>
    </>
  );
};

let controller;

const leadFilters = {
  //all: { label: "All", active: false },
  emailsSent: { label: "Email Sent", active: false },
  unSentEmails: { label: "Leads", active: false },
  views: { label: "Viewed", active: false },
  clicks: { label: "Clicked", active: false },
  responded: { label: "Responded", active: false },
  meetings: { label: "Meeting Scheduled", active: false },
  conversations: { label: "Conversations", active: false },
  unsubscribe: { label: "Unsubscribe", active: false },
  replies: { label: "Replies", active: false },
  pageViewAt: { label: "Page Views", active: false },
  videoViewed: { label: " Video Views", active: false },
  isWebSiteVisit: { label: "Website Clicks", active: false },
  bounced: { label: "Bounced", active: false },
};
// console.log(leadFilters, "leadFiltersleadFiltersleadFilters");
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const getServerDateTime = async () => {
  try {
    const responseData = await axios(
      `${API_SERVICE_BACKEND_2}/get-server-date-time`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    return responseData.data;
  } catch (err) {
    console.log(err);
  }
};

const emailStageStatus = (enrolledFor) => {
  let staus = "";
  if (enrolledFor === "initial") {
    staus = "Initial Email Sequence";
  } else if (enrolledFor === "conversation") {
    staus = "Conversation Sequence";
  } else if (enrolledFor === "meetingBooked") {
    staus = "Prospect Books a Meeting";
  } else {
    staus = "Prospect Misses a Meeting";
  }
  return staus;
};
const emailStage = (enrolledFor) => {
  let stage = "";
  if (enrolledFor === "initial") {
    stage = 1;
  } else if (enrolledFor === "conversation") {
    stage = 2;
  } else if (enrolledFor === "meetingBooked") {
    stage = 3;
  } else {
    stage = 4;
  }
  return stage;
};

export const ReplyStatus = Object.freeze({
  ALL: "All",
  INTERESTED: "Interested",
  MEETING_BOOKED: "Meeting Booked",
  MEETING_COMPLETED: "Meeting Completed",
  WON: "Won",
  OUT_OF_OFFICE: "Out Of Office",
  WRONG_PERSON: "Wrong Person",
  NOT_INTERESTED: "Not Interested",
  LOST: "Lost",
  UNSUBSCRIBED: "Unsubscribed",
  OTHER: "Other",
});

const replyStatusColorMap = Object.freeze({
  [ReplyStatus.ALL]: "#0071F6",
  [ReplyStatus.INTERESTED]: "#00AA38",
  [ReplyStatus.MEETING_BOOKED]: "#5761FE",
  [ReplyStatus.MEETING_COMPLETED]: "#FF5C1F",
  [ReplyStatus.WON]: "#FF7D1F",
  [ReplyStatus.OUT_OF_OFFICE]: "#55597A",
  [ReplyStatus.WRONG_PERSON]: "#00BBF6",
  [ReplyStatus.NOT_INTERESTED]: "#050C46",
  [ReplyStatus.LOST]: "#E20E0E",
  [ReplyStatus.OTHER]: "#E20E0E",
  [ReplyStatus.UNSUBSCRIBED]: "#E20E0E",
});

const statusList = [
  {
    label: ReplyStatus.ALL,
    icon: LeadIcon,
    color: replyStatusColorMap[ReplyStatus.ALL],
    value: undefined,
  },
  {
    label: ReplyStatus.INTERESTED,
    icon: InterestedIcon,
    color: replyStatusColorMap[ReplyStatus.INTERESTED],
    value: ReplyStatus.INTERESTED,
  },
  {
    label: ReplyStatus.MEETING_BOOKED,
    icon: BookedIcon,
    color: replyStatusColorMap[ReplyStatus.MEETING_BOOKED],
    value: ReplyStatus.MEETING_BOOKED,
  },
  {
    label: ReplyStatus.MEETING_COMPLETED,
    icon: CompletedIcon,
    color: replyStatusColorMap[ReplyStatus.MEETING_COMPLETED],
    value: ReplyStatus.MEETING_COMPLETED,
  },
  {
    label: ReplyStatus.WON,
    icon: WonIcon,
    color: replyStatusColorMap[ReplyStatus.WON],
    value: ReplyStatus.WON,
  },
  {
    label: ReplyStatus.OUT_OF_OFFICE,
    icon: OutOfOfficeIcon,
    color: replyStatusColorMap[ReplyStatus.OUT_OF_OFFICE],
    value: ReplyStatus.OUT_OF_OFFICE,
  },
  {
    label: ReplyStatus.WRONG_PERSON,
    icon: WrongPersonIcon,
    color: replyStatusColorMap[ReplyStatus.WRONG_PERSON],
    value: ReplyStatus.WRONG_PERSON,
  },
  {
    label: ReplyStatus.NOT_INTERESTED,
    icon: NotInterestedIcon,
    color: replyStatusColorMap[ReplyStatus.NOT_INTERESTED],
    value: ReplyStatus.NOT_INTERESTED,
  },
  {
    label: ReplyStatus.LOST,
    icon: LostIcon,
    color: replyStatusColorMap[ReplyStatus.LOST],
    value: ReplyStatus.LOST,
  },
  {
    label: "Unenrolled",
    icon: UnsubscribedIcon,
    color: replyStatusColorMap[ReplyStatus.UNSUBSCRIBED],
    value: ReplyStatus.UNSUBSCRIBED,
  },
  {
    label: ReplyStatus.OTHER,
    icon: OtherIcon,
    color: replyStatusColorMap[ReplyStatus.OTHER],
    value: ReplyStatus.OTHER,
  },
];

const CustomPaper = styled(Paper)(({ theme }) => ({
  width: 600,
}));

const Page = () => {
  const clientEmail = getSessionStorage("userEmail");
  const navigate = useNavigate();
  const location = useLocation();
  const customersSearch = useCustomersSearch();
  const customersStore = useCustomersStore(customersSearch.state);
  const customersIds = useCustomersIds(customersStore.customers);
  const customersSelection = useSelection(customersIds);
  const [array, setArray] = useState([]);
  const [clicksArray, setClicksArray] = useState([]);
  const [conversationArray, setConversationArray] = useState([]);
  const [meetingsArray, setMeetingsArray] = useState([]);
  const theme = useTheme();
  const [expand, setExpand] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [open, setOpen] = useState(false);
  const [timelineOpen, setTimelineOpen] = useState(false);
  const [currOpenEmail, setCurrOpenEmail] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [searchText, setSearchText] = useState(selectedItems);
  const [selectAll, setSelectAll] = useState(false);
  const [isZohoIntegration, setZohoIntegration] = useState(false);
  const [isHubspotIntegration, setHubspotIntegration] = useState(false);
  const [visible, setVisible] = useState(true);
  const [exportingCSV, setExportingCSV] = useState(false);
  const [loadingAILeads, setLoadingAILeads] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [currCampaign, setCurrCampaign] = useState(null);
  const [currCampPage, setCurrCampPage] = useState(1);
  const [currCampIndex, setCurrCampIndex] = useState(0);
  const [leads, setLeads] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [timelineData, setTimelineData] = useState({});
  const [filteredLeads, setFilteredLeads] = useState(leads);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(leadFilters);
  const [count, setCount] = useState(-1);
  const [sortBy, setSortBy] = useState({
    creationTime: -1,
    emailSentTime: -1,
  });
  const [disablePage, setDisablePage] = useState(true);
  const [customerArr, setCustomerArr] = useState([]);
  const [hstransferLoading, setHsTransferLoading] = useState(false);
  const [zhtransferLoading, setZhTransferLoading] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const [Baropen, setBarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [hubSpotReqProg, setHubSpotReqProg] = useState(0);
  const [zohoReqProg, setZohoReqProg] = useState(0);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [filterCsvExport, setFilterCsvExport] = useState("All");
  const [tab, setTab] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [loader, setLoader] = useState(true);
  const [aiAgents, setaiAgents] = useState([]);
  const blockListTabs = ["emailBlocklist", "domainBlocklist"]
  const [selectedLeads, setSelectedLeads] = useState([]);

  console.log('tabbbss', tab)
  const handleLoader = (data) => {
    setLoader(data);
  };

  useEffect(() => {
    if (Array.isArray(campaigns?.data)) {
      const filteredCampaigns = campaigns.data.filter(
        (campaign) => !campaign.archived
      );
      const filteredArchiveCampaigns = campaigns.data.filter(
        (campaign) => campaign.archived
      );
      const combinedCampaigns = [
        ...filteredCampaigns,
        ...filteredArchiveCampaigns,
      ];
      setaiAgents(combinedCampaigns);
    } else {
      setaiAgents([]);
    }
  }, [campaigns]);

  const handleClick = (ref) => {
    setAnchorEl(ref?.current);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };
  const popOpen = Boolean(anchorEl);
  const id = popOpen ? "simple-popover" : undefined;
  const [stageStats, setStageStats] = useState({
    selectedStage: "",
    stats: {},
  });
  const [dateSelect, setDateSelect] = useState({
    from: "",
    to: "",
  });
  const onDateRangeChange = (field, value) => {
    setDateSelect((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedOptions([...campaigns.data]);
    } else {
      setSelectedValue(campaigns.data);
      setSelectedOptions([]);
    }
    setSelectAll(!selectAll);
  };
  const handleChange = (event, newValue, reason) => {
    if (newValue.some((item) => item.selectAll)) {
      setSelectedValue(campaigns.data);
      handleSelectAll();
    } else {
      setSelectedOptions(newValue.filter((item) => !item.selectAll));
      setSelectedValue(newValue.filter((item) => !item.selectAll));
    }
  };

  const handleIntialChange = (event) => {
    setSelectedStage(event.target.value);
    setStageStats((prevState) => ({
      ...prevState,
      selectedStage: event.target.value,
    }));
  };

  const handleStatusChange = (e, value) => {
    console.log(e, value, "setSelectedStatus");
    setSelectedStatus(value ? value.label : "");
  };

  let stages = {
    "initialSeqStats.1": "Initial 1",
    "initialSeqStats.2": "Initial 2",
    "initialSeqStats.3": "Initial 3",
    "conversationSeqStats.1": "Conversation 1",
    "conversationSeqStats.2": "Conversation 2",
    "conversationSeqStats.3": "Conversation 3",
    "meetingsSeqStats.1": "Meetings 1",
    "meetingsSeqStats.2": "Meetings 2",
    "meetingsSeqStats.3": "Meetings 3",
    "missedMeetingsSeqStats.1": "Missed Meetings 1",
    "missedMeetingsSeqStats.2": "Missed Meetings 2",
    "missedMeetingsSeqStats.3": "Missed Meetings 3",
  };
  const stageFilterTabs = [
    "emailsSent",
    "views",
    "clicks",
    // "pageViewAt",
    "replies",
    // "videoViewed",
    // "isWebSiteVisit",
    // "conversations",
    // "meetings",
    // "unsubscribe"
  ];

  const LeadRow = ({
    data,
    index,
    theme,
    selectedItems,
    setSelectedItems,
    page,
    setPage,
    isZohoIntegration,
    setZohoIntegration,
    isHubspotIntegration,
    setHubspotIntegration,
    filters,
    expand,
    setExpand,
    handleRowSelect,
    tab,
    setTab,
    viewSentEmail,
    formatDate,
    formatedarrayDate,
    timelineData,
    setTimelineOpen,
    setTimelineData,
  }) => {
    const [viewEmail, setViewEmail] = useState(false);

    const clickemailStatus = async () => {
      const id_value = emailSeqStatus["initial"]["prospect"][1];
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/getEmailSentInfo?id=${id_value}`
      );
      setCurrOpenEmail(res.data.email);
      handleOpen("emailView");
    };

    const {
      _id,
      emailStatus,
      firstName,
      lastName,
      email,
      emailBody,
      description,
      linkedin_url,
      organization,
      openedAt,
      clickedAt,
      respondedAt,
      pageViewAt,
      meetingBookedAt,
      conversationAt,
      industry,
      address,
      campaignIds,
      sentAt,
      nextFollowUpStep,
      enrolledFor,
      emailSeqStatus,
      isZohoPosted,
      isHubspotPosted,
      source,
      unverifiedEmail,
      useBouncerStatus,
      videoViewDuration,
      videoViewed,
      videoViewedAt,
      latestSentAt,
      status,
    } = data;
    if (excludedEmails.includes(email)) return;
    const { city, state, country } = address ? address : {};
    // const formattedSentEmailDate = new Date(
    //   sentAt
    // ).toLocaleDateString("en-US", {
    //   weekday: "short",
    //   year: "numeric",
    //   month: "short",
    //   day: "numeric",
    // });
    const formattedSentEmailDate =
      sentAt !== undefined
        ? new Date(sentAt).toISOString().slice(0, 19).replace("T", " ")
        : "-";
    const emailToDisplay = email
      ? email
      : source === "csv"
        ? unverifiedEmail
        : email;
    const isEmailNotVerified =
      source === "csv" && ["PENDING", "FAILED"].includes(useBouncerStatus);

    const emailCheck = email?.includes("bot@b2brocket.ai");

    return (
      <>
        <TableRow
          key={_id}
          sx={{
            borderBottom: `1px solid ${theme.palette.grey[300]}`,

            "&:last-child td, &:last-child th": {
              border: 0,
            },
            "& .MuiTableCell-root": {
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
            },
            backgroundColor: emailCheck ? "light" : "inherit",
            pointerEvents: emailCheck ? "none" : "auto",
            opacity: emailCheck ? 0.5 : 1,
          }}
        >
          <TableCell>
            <Checkbox
              checked={selectedItems.indexOf(_id) !== -1}
              onChange={() =>
                handleRowSelect(_id, {
                  firstName,
                  lastName,
                  email,
                  organization,
                  city,
                  state,
                  country,
                  linkedin_url,
                  description,
                  campaignIds,
                  sentAt,
                  respondedAt,
                  pageViewAt,
                  clickedAt,
                  conversationAt,
                  meetingBookedAt,
                  enrolledFor,
                  emailStatus,
                  emailBody,
                  openedAt,
                  industry,
                  address,
                  nextFollowUpStep,
                  emailSeqStatus,
                  isZohoPosted,
                  isHubspotPosted,
                  videoViewDuration,
                  videoViewed,
                  videoViewedAt,
                  latestSentAt,
                  status,
                })
              }
            />
          </TableCell>
          <TableCell>
            #{LEADS_PER_PAGE * (page - 1) + index + 1}
            {/* {currCampIndex + 1}. */}
            {/* {LEADS_PER_PAGE * (currCampPage - 1) +
    index +
    1} */}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              fontWeight: "500",
            }}
          >
            {firstName}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              fontWeight: "500",
            }}
          >
            {lastName}
          </TableCell>
          {/* <TableCell align="left" width="25%">
        xxx-xxx-xxxx
      </TableCell> */}
          <TableCell align="center">{description}</TableCell>
          <TableCell align="center">{organization}</TableCell>

          <TableCell align="center">
            {city || state || country
              ? `${city || ""}, ${state || ""}, ${country || ""}`
              : "N/A"}
          </TableCell>
          <TableCell align="center">
            {/* <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
                fontSize: " 0.875rem",
                align:"center",
              }}
            > */}
            {emailToDisplay}
            {isEmailNotVerified ? (
              useBouncerStatus === "PENDING" ? (
                <Tooltip title="Verification Pending" placement="top" arrow>
                  <WarningAmberOutlined
                    sx={{
                      color: "orange",
                      fontSize: "18px",
                    }}
                  />
                </Tooltip>
              ) : useBouncerStatus === "FAILED" ? (
                <Tooltip title="Verification Failed" placement="top" arrow>
                  <ErrorOutlineOutlined
                    sx={{
                      color: "red",
                      fontSize: "18px",
                    }}
                  />
                </Tooltip>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            {/* </Typography> */}
          </TableCell>
          {/* <TableCell align="left">
          {source && source === "csv"
            ? "CSV"
            : "B2B Rocket"}
        </TableCell> */}
          {isZohoIntegration && (
            <TableCell align="center">
              {isZohoPosted === true ? "Yes" : "No"}
            </TableCell>
          )}
          {isHubspotIntegration && (
            <TableCell align="center">
              {isHubspotPosted === true ? "Yes" : "No"}
            </TableCell>
          )}
          <TableCell align="center">{industry ? industry : "-"}</TableCell>
          <TableCell align="center">
            {Boolean(linkedin_url) ? (
              <a href={linkedin_url} target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="36"
                  height="36"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#0288D1"
                    d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                  ></path>
                  <path
                    fill="#FFF"
                    d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                  ></path>
                </svg>
              </a>
            ) : (
              <Typography variant="h6">-</Typography>
            )}
          </TableCell>
          {/* <TableCell
          align="left"
          sx={{ minWidth: "150px" }}
        >
          <Stack rowGap={1}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  width: "50px",
                }}
              >
                Opened
              </Typography>
              <span>-</span>
              <Typography>
                {openedAt
                  ? openedAt?.length || 0
                  : 0}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  width: "50px",
                }}
              >
                Clicked
              </Typography>
              <span>-</span>
              <Typography>
                {clickedAt
                  ? clickedAt?.length || 0
                  : 0}
              </Typography>
            </Box>
          </Stack>
        </TableCell> */}
          {/* <TableCell align="left">
                  {Boolean(linkedin_url) ? (
                    <a
                      href={linkedin_url}
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="36"
                        height="36"
                        viewBox="0 0 48 48"
                      >
                        <path
                          fill="#0288D1"
                          d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                        ></path>
                        <path
                          fill="#FFF"
                          d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                        ></path>
                      </svg>
                    </a>
                  ) : (
                    <Typography variant="h6">
                      -
                    </Typography>
                  )}
                </TableCell> */}
          {/* <TableCell align="left">
      {campaignIds[0] || "#"}
      </TableCell> */}
          {/* {filters.emailsSent.active && ( */}
          <>
            {/* {filters.emailsSent.active && (
            <TableCell>
              <IconButton
                size="small"
                onClick={() => {
                  setTimelineData({
                    nextFollowUpStep,
                    enrolledFor,
                    emailSeqStatus,
                    emailStatus,
                  });
                  setTimelineOpen(true);
                }}
              >
                <img src={TimeLine} />
              </IconButton>
            </TableCell>
          )} */}

            {filters.emailsSent.active && (
              <TableCell>
                {emailStatus !== 0 ? (
                  <>
                    <IconButton
                      size="small"
                      color={emailStatus === 1 ? "success" : "error"}
                      onClick={clickemailStatus}
                    >
                      {emailStatus === 1 ? (
                        <Tooltip title="Email sent Successfully">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="36"
                            height="36"
                            viewBox="0 0 48 48"
                          >
                            <path
                              fill="#c8e6c9"
                              d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                            ></path>
                            <path
                              fill="#4caf50"
                              d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
                            ></path>
                          </svg>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Email not sent">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="36"
                            height="36"
                            viewBox="0 0 48 48"
                          >
                            <linearGradient
                              id="GCWVriy4rQhfclYQVzRmda_hRIvjOSQ8I0i_gr1"
                              x1="9.812"
                              x2="38.361"
                              y1="9.812"
                              y2="38.361"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop offset="0" stop-color="#f44f5a"></stop>
                              <stop offset=".443" stop-color="#ee3d4a"></stop>
                              <stop offset="1" stop-color="#e52030"></stop>
                            </linearGradient>
                            <path
                              fill="url(#GCWVriy4rQhfclYQVzRmda_hRIvjOSQ8I0i_gr1)"
                              d="M24,4C12.955,4,4,12.955,4,24s8.955,20,20,20s20-8.955,20-20C44,12.955,35.045,4,24,4z M24,38	c-7.732,0-14-6.268-14-14s6.268-14,14-14s14,6.268,14,14S31.732,38,24,38z"
                            ></path>
                            <linearGradient
                              id="GCWVriy4rQhfclYQVzRmdb_hRIvjOSQ8I0i_gr2"
                              x1="6.821"
                              x2="41.08"
                              y1="6.321"
                              y2="40.58"
                              gradientTransform="translate(-.146 .354)"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop offset="0" stop-color="#f44f5a"></stop>
                              <stop offset=".443" stop-color="#ee3d4a"></stop>
                              <stop offset="1" stop-color="#e52030"></stop>
                            </linearGradient>
                            <polygon
                              fill="url(#GCWVriy4rQhfclYQVzRmdb_hRIvjOSQ8I0i_gr2)"
                              points="13.371,38.871 9.129,34.629 34.629,9.129 38.871,13.371"
                            ></polygon>
                          </svg>
                        </Tooltip>
                      )}
                    </IconButton>
                    {sentAt && (
                      <Typography variant="subtitle2">
                        {formatDate(sentAt)}
                      </Typography>
                    )}
                  </>
                ) : (
                  <Tooltip title="Email not sent">
                    <img
                      width="36"
                      height="36"
                      src="https://img.icons8.com/plumpy/24/minus--v1.png"
                      alt="minus--v1"
                    />
                  </Tooltip>
                )}
              </TableCell>
            )}
            {filters.replies.active && (
              <TableCell align="center">{status}</TableCell>
            )}
            {filters.replies.active && (
              <TableCell align="center">
                <Link
                  onClick={() => {
                    setViewEmail(true);
                  }}
                >
                  {" "}
                  Email Status
                </Link>
              </TableCell>
            )}
            {/* <TableCell align="center">
  <IconButton
    size="small"
    color="info"
    onClick={() =>
      viewReplies({
        firstName,
        lastName,
        email,
        emailBody,
        description,
        linkedin_url,
        organization,
        industry,
        address,
      })
    }
  >
    <img src={Replies}/>
  </IconButton>
  </TableCell> */}
          </>

          <TableCell align="center">
            <Button
              sx={{
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                minWidth: "30px",
                minHeight: "30px",
                p: "6px",
                borderRadius: "8px",
                color: theme.palette.text.primary,
                "&:hover": {
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                },
              }}
              onClick={() => {
                if (expand === _id) {
                  setExpand(false);
                } else {
                  setExpand(_id);
                }
              }}
            >
              {expand && expand === _id ? <ChevronUp /> : <ChevronDown />}
            </Button>
          </TableCell>

          {/* <TableCell align="center">
      {description}
    </TableCell>
    <TableCell align="center">
      {organization}
    </TableCell>
    <TableCell align="center">{`${city}, ${state}, ${country}`}</TableCell>
    <TableCell align="center">
      {Boolean(linkedin_url) ? (
        <a
          href={linkedin_url}
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="36"
            height="36"
            viewBox="0 0 48 48"
          >
            <path
              fill="#0288D1"
              d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
            ></path>
            <path
              fill="#FFF"
              d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
            ></path>
          </svg>
        </a>
      ) : (
        <Typography variant="h6">
          -
        </Typography>
      )}
    </TableCell>
    <TableCell align="center">
      {industry}
    </TableCell>
    <TableCell align="center">NA</TableCell> */}
        </TableRow>
        {expand && expand === _id && (
          <ExpandedRow
            _id={_id}
            emailStatus={emailStatus}
            firstName={firstName}
            lastName={lastName}
            email={email}
            emailBody={emailBody}
            openedAt={openedAt}
            clickedAt={clickedAt}
            meetingBookedAt={meetingBookedAt}
            conversationAt={conversationAt}
            nextFollowUpStep={nextFollowUpStep}
            emailSeqStatus={emailSeqStatus}
            enrolledFor={enrolledFor}
            sentAt={sentAt}
            filters={filters}
            setTab={setTab}
            respondedAt={respondedAt}
            pageViewAt={pageViewAt}
            viewSentEmail={viewSentEmail}
            formattedSentEmailDate={formattedSentEmailDate}
            setExpand={setExpand}
            formatedarrayDate={formatedarrayDate}
            setTimelineData={setTimelineData}
            setTimelineOpen={setTimelineOpen}
            emailStageStatus={emailStageStatus}
            videoViewDuration={videoViewDuration}
            videoViewed={videoViewed}
            videoViewedAt={videoViewedAt}
          />
        )}
        {viewEmail && (
          <ViewEmailReplies
            description={description}
            viewEmail={viewEmail}
            setViewEmail={setViewEmail}
            email={email}
            emailBody={emailBody}
            leadId={_id}
          />
        )}
      </>
    );
  };

  const openSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setBarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setBarOpen(false);
  };

  const query = new URLSearchParams(location.search);
  const campaignId = query.get("campaignId");
  const { user } = useAuth();
  console.log(user, "handleOpen");
  const handleOpen = (dialog) => {
    setDialog(dialog);
    setOpen(true);
  };

  const viewSentEmail = (data) => {
    setCurrOpenEmail(data);
    handleOpen("emailView");
  };

  const viewReplies = (data) => {
    navigate(paths.dashboard.conversations, {
      state: { data: { ...data, campaignId } },
    });
  };

  usePageView();

  const handleRowSelect = (rowId, data) => {
    // console.log(rowId, "rowId");
    const email = data.email;
    const selectedIndex = selectedItems.indexOf(rowId);
    let newSelected = [];
    let newdataSelected = [];
    let newLeadsSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, rowId);
      newdataSelected = newdataSelected.concat(selectedData, {
        _id: rowId,
        ...data,
      });
      if (email) {
        newLeadsSelected = newLeadsSelected.concat(selectedLeads, email);
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
      newLeadsSelected = newLeadsSelected.concat(selectedLeads.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
      newLeadsSelected = newLeadsSelected.concat(selectedLeads.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
      newLeadsSelected = newLeadsSelected.concat(
        selectedLeads.slice(0, selectedIndex),
        selectedLeads.slice(selectedLeads + 1)
      );
    }
    setSelectedItems(newSelected);
    setSelectedLeads(newLeadsSelected);
    setSelectedData(newdataSelected);
  };

  useEffect(() => {
    setSelectedItems([]);
    setSelectedData([]);
    setSelectedLeads([]);
  }, [page, filters]);

  const handleRowSelectAll = () => {
    // If all rows are already selected, clear the selection; otherwise, select all rows
    const newSelectedRows =
      selectedItems.length === filteredLeads.length
        ? []
        : filteredLeads.map((row) => row._id);
    const newSelectedLeads =
      selectedLeads.length === filteredLeads.length
        ? []
        : filteredLeads
          .filter((row) => row.email !== null)
          .map((row) => row.email);
    const newdataSelected =
      selectedData.length === filteredLeads.length
        ? []
        : filteredLeads.map((row) => {
          return {
            _id: row._id,
            firstName: row.firstName,
            lastName: row.lastName,
            email: row.email,
            description: row.description,
            organization: row.organization,
          };
        });

    setSelectedItems(newSelectedRows);
    setSelectedLeads(newSelectedLeads);
    setSelectedData(newdataSelected);

    // console.log(newSelectedRows, "newSelectedRows");
    // console.log(newdataSelected, "newdataSelected");
    // console.log(selectedItems, "rowsselect");
  };

  const getUserCSVData = async (orgId) => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getusercsvdata/${orgId}`
      );
      const data = await response.json();
      setArray(data?.data?.csv_data || []);
      generateClicksArray(
        data?.data?.csv_data || [],
        data?.data?.filteredDataMetrics || []
      );
      generateConversationArray(
        data?.data?.csv_data || [],
        data?.data?.filteredDataMetrics || []
      );
      generateMeetingsArray(
        data?.data?.csv_data || [],
        data?.data?.filteredDataMetrics || []
      );
      // console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const generateClicksArray = (array, filteredData) => {
    var newArr = [];
    for (let i = 0; i < filteredData?.length; i++) {
      const { visitorsIndex } = filteredData[i];
      for (let j = 0; j < visitorsIndex?.length; j++) {
        if (array[visitorsIndex[j]] === undefined) {
          // console.log("Undefined at :", visitorsIndex[j]);
          continue;
        }
        newArr.push(array[visitorsIndex[j]]);
      }
    }
    setClicksArray(newArr);
    // console.log("clicksArray: ", newArr);
  };

  const generateConversationArray = (array, filteredData) => {
    var newArr = [];
    for (let i = 0; i < filteredData?.length; i++) {
      const { communicationIndex } = filteredData[i];
      for (let j = 0; j < communicationIndex?.length; j++) {
        if (array[communicationIndex[j]] === undefined) {
          // console.log("Undefined at :", communicationIndex[j]);
          continue;
        }
        newArr.push(array[communicationIndex[j]]);
      }
    }
    setConversationArray(newArr);
    // console.log("conversations array: ", newArr);
  };

  const getUserMeetings = async (email, orgId) => {
    console.log('cddcdcdcd')
    try {
      handleLoader(true);
      const allIds = campaigns?.data?.map(({ _id }) => _id)?.join(",");

      // Create request body as per new API structure
      const requestBody = {
        email: email,
        orgId: orgId,
        page: page,
        limit: LEADS_PER_PAGE,
        dateSelect: dateSelect,
        search: searchQuery,
        campaignId: selectedValue?.length
          ? selectedValue?.map(({ _id }) => _id)?.join(",")
          : "",
        campaignIds: allIds,
        ...(stageFilterTabs.includes(tab) ? { stage: selectedStage } : {})
      };
      console.log(requestBody, 'requestBodyrequestBody')
      // Use axios.post instead of axios.get
      const response = await axios.post(
        `${API_SERVICE_BACKEND}/getusermeetings`,
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      var meetings = response?.data?.response?.data;
      var metaData = response?.data?.response?.metaData;

      var tempMeeting = [];
      await Promise.all(
        meetings.map((item) => {
          var dataAdd = {
            title: item?.meeting_title,
            _id: item?._id,
            meeting_status: item?.meeting_status,
            date: item?.meeting_date,
            allDay: false,
            description: item?.meeting_purpose || item?.meeting_with || "",
            link: item?.meeting_link,
            time: item?.meeting_time,
            meeting_date: item?.meeting_date,
            guest: item?.meeting_guest,
            timezone: item?.timezones || item?.meeting_timezone || "",
            // createdAt: new Date(item?.createdAt).toUTCString().substring(0, 16),
            createdAt: item?.createdAt,
            prospect_name: item?.prospect_name || "",
            prospectEmail: item?.event_id?.split("/")[1] || "",
            campaignId: item?.campaign_id,
          };
          // console.log("dataAdd :: ", dataAdd.createdAt);
          tempMeeting.push(dataAdd);
        })
      );
      handleLoader(false);
      // console.log(tempMeeting,metaData,'response+>')
      // console.log(response?.data,'response+>')
      // console.log(response?.data?.response?.data,'response+>')
      setMeetingsArray(tempMeeting);
      return { tempMeeting, metaData };

    }
    catch (err) {
      console.log(err);
      handleLoader(false);
    }
  };

  // useEffect(() => {
  //   getUserMeetings()
  // }, [dateSelect])
  const generateMeetingsArray = async (array, filteredData) => {
    var newArr = [];
    // for (let i = 0; i < filteredData.length; i++) {
    //   const { meetingsIndex } = filteredData[i];
    //   for (let j = 0; j < meetingsIndex.length; j++) {
    //     if (array[meetingsIndex[j]] === undefined) {
    //       console.log("Undefined at :", meetingsIndex[j]);
    //       continue;
    //     }
    //     newArr.push(array[meetingsIndex[j]]);
    //   }
    // }

    // console.log(newArr);

    const email = getSessionStorage("userEmail");
    const orgId = getSessionStorage("organization_id");
    const meetings = await getUserMeetings(email, orgId);
    console.log(meetings, 'meetingsmeetings')
    newArr = [...meetings.tempMeeting];

    setMeetingsArray(newArr);
    // console.log("meetings array: ", newArr);
  };

  const arrayToCsv = (data) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);

    // Creating the header row
    csvRows.push(headers.join(","));

    // Creating the data rows
    for (const row of data) {
      const values = headers.map((header) => row[header]);
      csvRows.push(values.join(","));
    }
    return csvRows.join("\n");
  };

  const headersMapMeeting = {
    title: "Title",
    description: "Description",
    meeting_date: "Date",
    time: "Time",
    timezone: "Timezone",
    createdAt: "Booked On",
    meeting_status: "Meeting Status",
    link: "Meeting Link",
  };

  const arrayToCsvForAiLeads = (data) => {
    const csvRows = [];
    let headersMap = {
      _id: "Lead ID",
      campaignId: "AI Agent",
      status: "Status",
      firstName: "First Name",
      lastName: "Last Name",
      description: "Title",
      email: "Email",
      organization: "Company",
      website_domains: "Website Domain",
      linkedin_url: "LinkedIn",
      address: "Address",
      latestSentAt: "Last Updated",
      openedAt: "Last Opens",
      clickedAt: "Last Click",
      clickedAtCount: "Total Click",
      pageViewAt: "Last Page View",
      pageViewCount: "Total Page Views",
      isWebSiteVisit: "Website Clicks",
      conversationAt: "Last AI Conversation",
      conversationCount: "Total AI Conversations",
      respondedAt: "Last Email Reply",
      respondedCount: "Total Email Replies",
      meetingBookedAt: "Last Meeting Booked",
      meetingBookedCount: "Total Meetings Booked",
      unsubscribe: "Subscription Status",
      emailSentAll: "Email Sent",
      emailStatus: "Email Status",
    };
    let headers = [
      "_id",
      "campaignId",
      "status",
      "firstName",
      "lastName",
      "description",
      "email",
      "organization",
      "website_domains",
      "linkedin_url",
      "address",
      "latestSentAt",
      "openedAt",
      "clickedAt",
      "clickedAtCount",
      "pageViewAt",
      "pageViewCount",
      "isWebSiteVisit",
      "conversationAt",
      "conversationCount",
      "respondedAt",
      "respondedCount",
      "meetingBookedAt",
      "meetingBookedCount",
      "unsubscribe",
      "emailSentAll",
      "emailStatus",
      "bounced"
    ];
    // Creating the header row
    const isActive = Object.values(filters).some((filter) => filter.active);
    if (filters["emailsSent"].active || isActive === false) {
      // headersMap.opened = "Seen";
      // headers.push("opened");
    } else {
      // delete headersMap.opened;
      // headers = headers.filter((item) => item !== "opened");
    }
    csvRows.push(headers.map((header) => headersMap[header]).join(","));

    // const filterKeys = Object.keys(filters);
    // const activeFilterKey = filterKeys.find(fKey => filters[fKey].active === true)
    console.log(
      "export csv data",
      data?.map((item) => ({
        respondedAt:
          (item?.respondedAt &&
            item?.respondedAt !== "-" &&
            moment(item?.respondedAt).format("D MMM YYYY h:mm:ss")) ||
          "-",
      }))
    );
    if (data || data?.length) {
      data.forEach((lead) => {
        // const { city, state, country } = lead.address ? lead.address : {};
        // lead.address = `${city || "UNKNOWN"} | ${state || "UNKNOWN"} | ${country || "UNKNOWN"}`;
        // lead.campaignId = lead.campaignIds[0];
        // const formattedSentEmailDate = new Date(lead.latestSentAt).toLocaleDateString("en-US", {
        //   // weekday: 'short',
        //   year: "numeric",
        //   month: "short",
        //   day: "numeric",
        //   hour: "2-digit",
        //   minute: "2-digit",
        // });
        // const formattedClickEmailDate = new Date(lead.clickedAt).toLocaleDateString("en-US", {
        //   year: "numeric",
        //   month: "short",
        //   day: "numeric",
        //   hour: "2-digit",
        //   minute: "2-digit",
        // });
        // const formattedOpenEmailDate = new Date(lead.openedAt).toLocaleDateString("en-US", {
        //   year: "numeric",
        //   month: "short",
        //   day: "numeric",
        //   hour: "2-digit",
        //   minute: "2-digit",
        // });

        // let leadStatus = activeFilterKey ? filters[activeFilterKey].label : null;

        // if (!leadStatus) {
        //   leadStatus = Number(lead.emailStatus ?? 0) == 0 ? 'Leads' : 'Sent'
        //   leadStatus += (lead.opened === true || Number(lead.openedAt?.length ?? 0) > 0) && lead.unsubscribe === false ? ",Opened" : "";
        //   leadStatus += lead.clickedAt && lead.clickedAt?.length > 0 && lead.unsubscribe === false ? ",Clicks" : "";
        //   leadStatus += lead.respondedAt && lead.respondedAt.length > 0 ? ',Replies' : "";
        //   leadStatus += lead.videoViewed === true ? ',Video Views' : "";
        //   leadStatus += lead.isWebSiteVisit === true ? ',Website Clicks' : "";
        //   leadStatus += lead.conversationAt && lead.conversationAt.length > 0 ? ',Conversations' : "";
        //   leadStatus += lead.unsubscribe == true ? ',Unsubscribe' : "";
        // }

        // lead.latestSentAt = lead.latestSentAt ? formattedSentEmailDate : "-";
        // lead.openedAt = lead.openedAt ? formattedOpenEmailDate : "-";
        // lead.clickedAt = lead.clickedAt ? formattedClickEmailDate : "-";
        // lead.clickedAtCount = lead.clickedAt?.length ? lead?.clickedAt?.length : 0;
        // // lead.openedAt = lead?.openedAt?.length ? lead?.openedAt?.length : "-";
        // lead.respondedAt = lead?.respondedAt?.length ? lead?.respondedAt[lead?.respondedAt?.length-1] : "-";
        // lead.respondedCount = lead?.respondedAt?.length ? lead?.respondedAt?.length : 0;
        // lead.conversationCount = lead?.conversationAt?.length ?? 0;
        // lead.conversationAt = lead?.conversationAt?.length ? lead?.conversationAt?.length : "-";
        // lead.meetingBookedCount = lead?.meetingBookedAt?.length ?? 0;
        // lead.meetingBookedAt = lead?.meetingBookedAt?.length ? lead?.meetingBookedAt[lead?.meetingBookedAt?.length - 1] : "-";
        // lead.pageViewCount = lead?.pageViewAt?.length ?? 0;
        // lead.pageViewAt = lead?.pageViewAt?.length ? lead?.pageViewAt[lead?.pageViewAt?.length - 1].timestamp : "-";
        // lead.isWebSiteVisit = lead.isWebSiteVisit ? lead.isWebSiteVisit : "-";
        // lead.status = lead?.status ? lead?.status : "-";
        // lead.sequence = lead.enrolledFor ? emailStageStatus(lead.enrolledFor) : "-";
        // lead.stage = lead.enrolledFor ? emailStage(lead.enrolledFor) : "-";
        // lead.description = JSON.stringify(lead?.description?.replace(/[()]/g, "").replace(/;/g, " |"));
        // lead.organization = JSON.stringify(lead?.organization.replace(/"/g, "").replace(/'/g, "").replace(/[()]/g, "").replace(/;/g, " |"));
        // lead.website_domains = (lead.website_domains && lead.website_domains.length > 0) ? lead.website_domains.join(',') : '';
        // lead.unsubscribe = lead.unsubscribe ?? false;
        // lead.status = leadStatus;
        const latestSentAt = lead.latestSentAt ?? "-"
        lead.latestSentAt = latestSentAt && latestSentAt !== '-'
          ? moment(latestSentAt).format('D MMM YYYY h:mm:ss')
          : '-';
        const clickedAt = lead.clickedAt ?? "-"
        lead.clickedAt = clickedAt && clickedAt !== '-'
          ? moment(clickedAt).format('D MMM YYYY h:mm:ss')
          : '-';

        lead.openedAt = lead.openedAt && lead.openedAt !== '-'
          ? moment(lead.openedAt).format('D MMM YYYY h:mm:ss')
          : '-';

        lead.respondedAt = lead.respondedAt && lead.respondedAt !== '-'
          ? moment(lead.respondedAt).format('D MMM YYYY h:mm:ss')
          : '-';
        // console.log(lead,"nbggnghfdhfghrthtrhrt")
        lead.unsubscribe = lead.unsubscribe ? "Unsubscribe" : "-";

        const leadRow = headers.map((key) => {
          // console.log(key,lead[key],'sbdffdfgnngfs')
          // console.log("lead[key])", { key, lead: lead[key] });
          const processedData = (String(lead[key]) || "")
            .split(",")
            .join(" | ");
          if (key === "emailBody") {
            return textFromHTML(processedData || "").replace(
              "\n",
              "(*newline)"
            );
          }
          return processedData || "";
        });
        csvRows.push(leadRow.join(","));
      });
    }
    return csvRows.join("\n");
  };

  const arrayToCsvForMeetings = (data) => {
    const csvRows = [];
    const headers = [
      "title",
      "description",
      "meeting_date",
      "time",
      "timezone",
      "createdAt",
      "meeting_status",
      "link",
    ];
    // Creating the header row
    csvRows.push(headers.map((header) => headersMapMeeting[header]).join(","));

    data.forEach((lead) => {
      const formattedSentEmailDate = new Date(
        lead.meeting_date
      ).toLocaleDateString("en-US", {
        // weekday: 'short',
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      lead.meeting_date = formattedSentEmailDate;

      const leadRow = headers.map((key) => {
        // console.log(lead[key]);
        const processedData = (String(lead[key]) || "").split(",").join(" | ");
        if (key === "emailBody") {
          return textFromHTML(processedData || "").replace("\n", "(*newline)");
        }
        return processedData || "";
      });
      csvRows.push(leadRow.join(","));
    });

    return csvRows.join("\n");
  };

  const downloadCsvFile = (csvData, filename) => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, filename);
  };

  function getActiveFilter() {
    for (const filter in filters) {
      if (filters[filter].active) {
        return filter;
      }
    }
    return null; // No active filter found
  }

  const exportCSVData = async () => {
    setExportingCSV(true);
    const currTabSel = Object.entries(filters).find(
      ([key, { active }]) => active
    );
    const currTab = currTabSel?.[0] || "all";

    // Get active filter
    const activeFilter = getActiveFilter();

    // Determine the export file name based on active filter
    let exportFileName;
    if (filterCsvExport === "views") {
      exportFileName = `Opened_Leads_Exported.csv`;
    } else {
      exportFileName = filterCsvExport
        ? `${filterCsvExport}_Leads_Exported.csv`
        : `Leads_Exported.csv`;
    }

    const filterKeys = Object.keys(filters);
    const trimmedFilters = { [filterCsvExport]: true };
    // for (let key of filterKeys) {
    //   if (!filters[key].active) {
    //     continue;
    //   }
    //   trimmedFilters[key] = true;
    // }
    // console.log("trimmedFilters: ", trimmedFilters);

    let selected_compain_ids = selectedValue?.length
      ? selectedValue?.map(({ _id }) => _id)?.join(",")
      : "all";

    controller?.abort();
    controller = new AbortController();

    if (
      Object.keys(trimmedFilters)?.length &&
      filterCsvExport.toLowerCase() !== "all"
    ) {
      if (["responded", "meetings"].includes(currTab)) {
        await fetchFilteredLeads(
          trimmedFilters,
          selected_compain_ids,
          controller,
          sortBy,
          true,
          searchQuery
        ).then((data) => {
          if (!data?.metaData) {
            // if (!data?.length) {
            //   setCurrCampIndex((prev) => prev + 1);
            //   setCurrCampPage(1);
            //   return;
            // }
            const lastElem = data?.pop();
            const count = lastElem?.count;
            // console.log(`Export row count for "${currTab}": ${count}`);
            if (!count) {
              data?.push(lastElem);
            }
            // console.log(data.slice(0, 5));
            const csvArrayasCSV = arrayToCsvForAiLeads(data);
            setExportingCSV(false);
            downloadCsvFile(csvArrayasCSV, exportFileName);
          } else {
            const csvArrayasCSV = arrayToCsvForMeetings(data.tempMeeting);
            setExportingCSV(false);
            downloadCsvFile(csvArrayasCSV, exportFileName);
          }
        });
        setExportingCSV(false);
        // console.log(`Export for ${currTab}, coming soon!`);
        return;
      }

      await fetchFilteredLeads(
        trimmedFilters,
        selected_compain_ids,
        controller,
        sortBy,
        true,
        searchQuery
      ).then((data) => {
        if (!data?.metaData) {
          // if (!data?.length) {
          //   setCurrCampIndex((prev) => prev + 1);
          //   setCurrCampPage(1);
          //   return;
          // }
          const lastElem = data?.pop();
          const count = lastElem?.count;
          // console.log(`Export row count for "${currTab}": ${count}`);
          // if (!count) {
          //   data?.push(lastElem);
          // }
          // console.log(data.slice(0, 5));
          const csvArrayasCSV = arrayToCsvForAiLeads(data);
          setExportingCSV(false);
          downloadCsvFile(csvArrayasCSV, exportFileName);
        } else {
          const csvArrayasCSV = arrayToCsvForAiLeads(data.leads);
          setExportingCSV(false);
          downloadCsvFile(csvArrayasCSV, exportFileName);
        }
      });
      return;
    } else {
      await jobsApi
        // .getCampaignLeads(campaignId, currCampPage, LEADS_PER_PAGE, controller)
        .getCampaignLeadsV2(
          selectedValue?.length
            ? selectedValue?.map(({ _id }) => _id)?.join(",")
            : null,
          page,
          LEADS_PER_PAGE,
          controller,
          selectedValue?.length ? null : user.email,
          sortBy,
          true,
          searchQuery,
          dateSelect,
          true
        )
        .then((data) => {
          const csvArrayasCSV = arrayToCsvForAiLeads(data?.leads);
          setExportingCSV(false);
          downloadCsvFile(csvArrayasCSV, exportFileName);
        })
        .catch((error) => {
          setExportingCSV(false);
          console.log("error", error);
        });
      // const _campaign_id = selectedValue?.length ? selectedValue?.map(({ _id }) => _id)?.join(",") : null
      // const _email = selectedValue?.length ? null : user.email

      // const allIds = campaigns?.data?.map(({ _id }) => _id)?.join(",");

      // let payload = {
      //   email: _email,
      //   page: page,
      //   limit: LEADS_PER_PAGE,
      //   sortJSON: encodeURI(JSON.stringify(sortBy)),
      //   exportBool: true,
      //   search: searchQuery,
      //   filters: Object.keys(trimmedFilters)?.length ? encodeURI(JSON.stringify(trimmedFilters)) : null,
      //   campaignId: selected_compain_ids === "all" ? allIds : selected_compain_ids,
      // }

      // if(dateSelect.from && dateSelect.to){
      //   payload.dateSelect = encodeURI(JSON.stringify(dateSelect))
      // }

      // const urlPath = `${API_SERVICE_BACKEND}/export-campaign-leads/${_campaign_id}?${new URLSearchParams({
      //   ...payload
      // }).toString()}`
      // window.location = urlPath;
    }
    setExportingCSV(false);
  };

  const setLeadsByExludingEmail = async (leads) => {
    // const excludedEmails = ["gautamsir076@gmail.com", "shiva@leadingly.io", "noah@leadingly.io", "noah@b2brocket.ai"];
    const excludedEmails = [];
    const filteredLeads = leads.filter(
      (lead) => !excludedEmails.includes(lead.email)
    );
    setLeads(filteredLeads);
    setIsloading(false);
    setLoadingAILeads(false);
  };

  const changeLeadsFilters = (key) => {
    setFilterCsvExport(key);
    // if (key !== "replies") setSelectedStatus("All");

    setPage(1);
    setLeadsByExludingEmail([]);
    // setCurrCampaign(null);
    // setCurrCampIndex(0);
    // setCurrCampPage(1);
    setFilters((prev) => {
      const temp = { ...prev[key] };
      if ([...Object.keys(filters)].includes(key)) {
        temp.active = !temp.active;
        return { ...leadFilters, [key]: temp };
      }
      return { ...leadFilters };
    });
  };

  useEffect(() => {
    setCount(-1);
    const currTabSel = Object.entries(filters).find(
      ([key, { active }]) => active
    );
    // console.log(currTabSel,"currTabSelcurrTabSel")
    const currTab = currTabSel?.[0] || "all";
    const visBool = ![
      "responded",
      // "clicks",
      "meetings",
      // "conversations",
    ].includes(currTab);
    setVisible(visBool);
  }, [filters]);

  console.log(visible, 'cdscsdcsdc')

  const fetchFilteredLeads = async (
    filters,
    campaignId,
    controller,
    sortOptions,
    exportBool = false,
    search
  ) => {
    // setLoadingAILeads(true);
    // also need to add conversations filters

    const allIds = campaigns?.data?.map(({ _id }) => _id)?.join(",");
    // console.log(allIds);
    const key = Object.keys(filters)[0];
    if (["responded", "meetings"].includes(key)) {
      let temp;
      if (key === "responded") {
        // update backend to accomodate multiple campaignIds as params
        const res = await axios.post(
          `${COMMUNICATION_SERVER}/api/v1/main/getFilteredEmails`,
          {
            payload: {
              campaignId: campaignId === "all" ? allIds : campaignId,
              page,
              filters,
            },
          },
          { signal: controller?.signal }
        );
        const respondedLeads = res.data;
        temp = respondedLeads.map(({ _id: email, responses }) => email);
      }
      // else if (["clicks", "conversations"].includes(key)) {
      //   // pagination of this filter is pending
      //   const queryParams = {
      //     page,
      //     // limit: Math.floor(
      //     //   LEADS_PER_PAGE / (campaigns ? campaigns.length : 1)
      //     // ),
      //     limit: LEADS_PER_PAGE,
      //     search: search,
      //     // campaignIdListStr: allIds,
      //   };
      //   if (key === "clicks") {
      //     console.log("4", 4);

      //     queryParams["empty"] = true;
      //   }
      //   // console.log(queryParams);
      //   let tempCampClicks = campaigns.map(async ({ _id: campId }) => {
      //     const res = await axios.get(
      //       `${API_SERVICE_BACKEND_2}/searchCampConvo/${campId}?${new URLSearchParams(
      //         queryParams
      //       ).toString()}`,
      //       { signal: controller?.signal }
      //     );
      //     // const data = await res.json();
      //     tempCampClicks = res?.data?.data?.conversations?.map(
      //       ({ leadEmail: email }) => email
      //     );
      //     return tempCampClicks;
      //   });

      //   tempCampClicks = await Promise.all(tempCampClicks);
      //   // console.log(tempCampClicks);

      //   let tempLeadsPromise = tempCampClicks.map(async (temp, index) => {
      //     const campId = campaigns[index]?._id;
      //     const tempLeads = await axios.post(
      //       `${API_SERVICE_BACKEND}/leadInfo`,
      //       {
      //         campaignId: campId,
      //         // campaignIdListStr: allIds,
      //         leadEmail: temp.join(","),
      //       },
      //       { signal: controller?.signal }
      //     );
      //     return tempLeads;
      //   });

      //   tempLeadsPromise = await Promise.all(tempLeadsPromise);
      //   // console.log(tempLeadsPromise);

      //   const payload = [];
      //   tempLeadsPromise.forEach((tempLeads, index) => {
      //     // console.log(tempLeads)
      //     // const campaignId = campaigns[index];
      //     const campaignId = tempLeads?.data?._id;
      //     if (!campaignId) return;
      //     const campaignEmailsList = tempLeads?.data?.lead?.map(
      //       (lead) => lead?.email
      //     );
      //     payload.push({ campaignId, emailsList: campaignEmailsList });
      //   });

      //   const leadsRes = await axios.post(
      //     `${API_SERVICE_BACKEND}/getUsersAllCampConvo?${new URLSearchParams(
      //       queryParams
      //     ).toString()}`,
      //     {
      //       payload,
      //       sortJSON: sortOptions,
      //       exportBool,
      //     },
      //     { signal: controller?.signal }
      //   );
      //   // console.log(leadsRes);

      //   const campEmails = leadsRes.data?.conversations?.map(
      //     ({ leadEmail, campaignId }) => ({ campaignId, leadEmail })
      //   );
      //   // console.log(campEmails);

      //   tempCampClicks = {};
      //   campEmails.forEach(({ campaignId, leadEmail }) => {
      //     if (!tempCampClicks[campaignId]) tempCampClicks[campaignId] = [];
      //     tempCampClicks[campaignId].push(leadEmail);
      //   });
      //   // console.log(tempCampClicks);

      //   tempLeadsPromise = Object.entries(tempCampClicks).map(
      //     async ([campId, temp], index) => {
      //       // const campId = campaigns[index]?._id;
      //       const tempLeads = await axios.post(
      //         `${API_SERVICE_BACKEND}/leadInfo`,
      //         {
      //           campaignId: campId,
      //           // campaignIdListStr: allIds,
      //           leadEmail: temp.join(","),
      //         },
      //         { signal: controller?.signal }
      //       );
      //       return tempLeads;
      //     }
      //   );
      //   tempLeadsPromise = await Promise.all(tempLeadsPromise);

      //   const leads = [];
      //   tempLeadsPromise.forEach((tempLeads, index) => {
      //     // const campaignId = campaigns[index];
      //     const campaignId = tempLeads?.data?._id;

      //     leads.push(
      //       ...tempLeads?.data?.lead?.map((lead) => ({ ...lead, campaignId }))
      //     );
      //   });

      //   const sortedLeads = new Array(leadsRes.data?.count || 0);
      //   campEmails.forEach(({ campaignId, leadEmail }, ix) => {
      //     const index = leads.findIndex(
      //       (lead) => lead.campaignId === campaignId && lead.email === leadEmail
      //     );
      //     sortedLeads[ix] = leads[index];
      //   });

      //   // leads.push({ count: leadsRes.data?.count });
      //   sortedLeads.push({ count: leadsRes.data?.count });

      //   // setLeadsByExludingEmail(tempLeads.data.lead);
      //   // setLoadingAILeads(false);
      //   // return leads;
      //   return sortedLeads;
      // }
      else if (key === "meetings") {
        temp = [];
        const accessToken = user.dbRef?.extra?.accessToken?.access_token;
        // console.log("accessToken: ", accessToken);
        // const events = await cronofyApi.readEvents({ accessToken });
        // const events = await cronofyApi.readEventsFromSysDB({
        //   campaignId,
        //   campaignIdListStr: allIds,
        //   projFields: ["event_id"],
        // });

        const email = getSessionStorage("userEmail");
        const orgId = getSessionStorage("organization_id");
        const meetings = await getUserMeetings(email, orgId);
        let newArr = [...meetings.tempMeeting];
        setMeetingsArray(newArr);
        return meetings;

        // temp = events.events
        //   ?.map((event) => {
        //     const { event_id } = event;
        //     const [campId, email] = event_id.split("/");
        //     if (campaignId === "all") {
        //       if (!allIds.includes(campId)) return null;
        //       return email;
        //     }
        //     if (campId !== campaignId) return null;
        //     return email;
        //   })
        //   .filter((email) => email !== null);
      }
      // console.log("temp :: ", temp);
      // // console.log(temp.length);
      // const tempLeads = await axios.post(
      //   // `${API_LOCAL_IP}/leadInfo`,
      //   `${API_SERVICE_BACKEND}/leadInfo`,
      //   {
      //     campaignId,
      //     campaignIdListStr: allIds,
      //     leadEmail: temp.join(","),
      //   },
      //   { signal: controller?.signal }
      // );
      // console.log("tempLeads.data", tempLeads.data);
      // setLeadsByExludingEmail(tempLeads.data.lead);
      // setLoadingAILeads(false);
      // return tempLeads.data.lead;
    } else if (
      [
        "clicks",
        "conversations",
        "emailsSent",
        "views",
        "unsubscribe",
        "unSentEmails",
        "all",
        "replies",
        "pageViewAt",
        "isWebSiteVisit",
        "videoViewed",
        "bounced"
      ].includes(key)
    ) {
      // console.log("api call fgvhfid")
      handleLoader(true);
      let filter = filters;
      if (key == "replies") {
        filter = { responded: true };
      } else if (key == "pageViewAt") {
        filter = { "pageViewAt.0": { $exists: true } };
      } else if (key == "isWebSiteVisit") {
        filter = { isWebSiteVisit: true };
      } else if (key == "views") {
        filter.unsubscribe = false;
      } else if (key == "clicks") {
        filter.unsubscribe = false;
      }
      let status = selectedStatus;
      if (selectedStatus === "All") {
        status = "";
      } else {
        status = selectedStatus;
      }
      let payload = {
        campaignId: campaignId,
        campaignIdListStr: allIds,
        filters: filter,
        sortJSON: sortOptions,
        search: search,
        status: status,
        exportBool,
        ...(stageFilterTabs.includes(tab) ? { stage: selectedStage } : {}),
      };
      if (dateSelect.from && dateSelect.to) {
        payload.dateRange = dateSelect;
      }
      if (exportBool === false) {
        payload.limit = LEADS_PER_PAGE;
        payload.page = page;
      }
      if (allIds) {
        const res = await axios.post(
          `${API_SERVICE_BACKEND}/getFilteredLeads`,
          // `http://192.168.16.41:8080/api/v1/main/getFilteredLeads`,
          {
            payload: payload,
          },
          {
            signal: controller?.signal,
          }
        );
        handleLoader(false);
        // console.log(res);
        // setLoadingAILeads(false);
        if (res.data.status !== 1) {
          // setLeadsByExludingEmail([]);
          return [];
        } else {
          handleLoader(false);
          // setLeadsByExludingEmail(res.data);
          return res.data.data;
        }
      }
    }
  };

  // useEffect(() => {
  //   if (!campaigns?.length) return;
  //   if (currCampIndex >= campaigns.length) {
  //     setCurrCampaign(null);
  //   }
  //   setCurrCampaign(campaigns[currCampIndex]);
  // }, [campaigns, currCampIndex]);

  // useEffect(() => {
  //   if (currCampPage <= 0) {
  //     setCurrCampIndex((prev) => {
  //       const newCampIndex = Math.min(0, prev - 1);
  //       const tempCamp = campaigns[newCampIndex];
  //       // console.log(tempCamp);
  //       let tempCurrCampPage;
  //       if (tempCamp) {
  //         const totalLeads = tempCamp?.operationStat?.leadsGen;
  //         tempCurrCampPage = Math.ceil(totalLeads / LEADS_PER_PAGE);
  //       }
  //       setCurrCampPage(tempCurrCampPage);
  //       return newCampIndex;
  //     });
  //   }
  // }, [currCampPage]);

  const totalPageCount = Math.ceil(count / LEADS_PER_PAGE);
  // console.log("LEADS_PER_PAGE: ", LEADS_PER_PAGE);
  // console.log("count: ", count);

  // 1 -> asc, -1 -> desc; assuming binary values

  const changeSort = (field, value) => {
    setSortBy((prev) => ({ ...prev, [field]: value }));
  };

  // useEffect(() => {
  //   console.log(currCampaign);
  // }, [currCampaign]);
  // useEffect(() => {
  //   console.log(currCampIndex, currCampPage);
  // }, [currCampIndex, currCampPage]);

  const changeAugPage = (inc) => {
    setDisablePage(true);
    setPage((prev) => prev + inc);
    // setCurrCampPage((prev) => prev + inc);
  };
  const handleSearchChangeDebounced = debounce((query) => {
    setSearchQuery(query);

    if (query === "") {
      setFilteredLeads(leads);
    }
  }, 600);

  const handleSearchChange = async (e) => {
    const query = e.target.value.toLowerCase();
    setSearchText(query);
    handleSearchChangeDebounced(query);
  };

  const handleCustomerChecked = (e, data) => {
    if (e.target.checked) {
      customerArr.push(data);
      // console.log("yesssss");
    } else {
      const index = customerArr.findIndex(
        (element) => element._id === data._id
      );
      // console.log(index);
      if (index > -1) {
        customerArr.splice(index, 1);
      }
    }
    setCustomerArr(customerArr);
    // if(customerArr?.length >0){

    // }
  };

  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`,
  });

  useEffect(() => {
    if (!user) return;
    const tokenZoho = user.integrations.zoho?.access_token;
    const tokenHubspot = user.integrations.hubspot?.access_token;
    if (tokenZoho) {
      setZohoIntegration(true);
    } else {
      setZohoIntegration(false);
    }
    if (tokenHubspot) {
      setHubspotIntegration(true);
    } else {
      setHubspotIntegration(false);
    }
  }, [user]);

  // Assuming you have a state variable selectedItems to manage selected items

  const handleClose = () => {
    setOpen(false);
    setDialog(null);
    setCurrOpenEmail(null);
  };

  // console.log("SELECTED ITEMS ------> ", selectedItems);
  const handleCheckboxChange = (item) => {
    const selectedIndex = selectedItems.findIndex(
      (element) => element._id === item._id
    );

    let newSelected = [...selectedItems];

    if (selectedIndex === -1) {
      newSelected = [...selectedItems, item];
    } else {
      newSelected = [
        ...selectedItems.slice(0, selectedIndex),
        ...selectedItems.slice(selectedIndex + 1),
      ];
    }

    setSelectedItems(newSelected);
  };
  const handleSelectAllChange = () => {
    const isAllSelected = !selectAll;

    if (isAllSelected) {
      setSelectedItems([...leads]); // Select all items
    } else {
      setSelectedItems([]); // Deselect all items
    }

    setSelectAll(isAllSelected);
  };

  useEffect(() => {
    if (!user?.email) return;
    jobsApi.getUserCampaigns(user.email).then((data) => {
      setCampaigns(data || []);
      if (!data || data?.data?.length === 0) {
        setLoadingAILeads(false);
      }
      // if (data?.length) {
      //   setCurrCampaign(data[0]);
      // }
    });
  }, [user]);

  useEffect(() => {
    // console.log(filters);
    setCount(-1);
    const currTabSel = Object.entries(filters).find(
      ([key, { active }]) => active
    );
    const currTab = currTabSel?.[0] || "all";
    const visBool = ![
      "responded",
      // "clicks",
      "meetings",
      // "conversations",
    ].includes(currTab);
    setVisible(visBool);
  }, [filters]);

  // useEffect(
  //   () => {
  //     // handleLoader(true);
  //     const handleFilteredLeads = async () => {
  //       if (campaigns?.data?.length === 0) return;
  //       setLoadingAILeads(true);
  //       // if (!currCampaign) {
  //       //   setLeadsByExludingEmail([]);
  //       //   setDisablePage(false);
  //       //   return;
  //       // }
  //       controller?.abort();
  //       controller = new AbortController();
  //       // const campaignId = currCampaign?._id;
  //       setIsloading(true);
  //       const filterKeys = Object.keys(filters);
  //       const trimmedFilters = {};
  //       for (let key of filterKeys) {
  //         if (!filters[key].active) {
  //           continue;
  //         }
  //         trimmedFilters[key] = true;
  //       }
  //       if (Object.keys(trimmedFilters)?.length) {
  //         // fetchFilteredLeads(trimmedFilters, campaignId, controller)
  //         await fetchFilteredLeads(
  //           trimmedFilters,
  //           selectedValue?.length
  //             ? selectedValue?.map(({ _id }) => _id)?.join(",")
  //             : "all",
  //           controller,
  //           sortBy,
  //           false,
  //           searchQuery
  //         )
  //           .then((data) => {
  //             console.log("data::::::: leads ", data);
  //             if (!data?.metaData?.count) {
  //               // if (!data?.length) {
  //               //   setCurrCampIndex((prev) => prev + 1);
  //               //   setCurrCampPage(1);
  //               //   return;
  //               // }
  //               if (data?.length) {
  //                 const lastElem = data?.pop();
  //                 var count = lastElem?.count;
  //                 if (count === undefined) {
  //                   data?.push(lastElem);
  //                 }
  //                 setCount(count);
  //               } else {
  //                 setCount(data?.metaData?.count);
  //               }
  //               // if(trimmedFilters.messages){

  //               // }

  //               setLeadsByExludingEmail(data?.length > 0 ? data : []);
  //               setDisablePage(false);
  //               setLoadingAILeads(false);
  //             } else {
  //               setCount(data.metaData.count);
  //               setLeadsByExludingEmail(
  //                 data?.leads?.length > 0 ? data?.leads : []
  //               );
  //               setDisablePage(false);
  //               setLoadingAILeads(false);
  //             }
  //           })
  //           .catch((error) => {
  //             console.log("error", error);
  //           });
  //         return;
  //       }
  //       jobsApi
  //         // .getCampaignLeads(campaignId, currCampPage, LEADS_PER_PAGE, controller)
  //         .getCampaignLeadsV2(
  //           selectedValue?.length
  //             ? selectedValue?.map(({ _id }) => _id)?.join(",")
  //             : null,
  //           page,
  //           LEADS_PER_PAGE,
  //           controller,
  //           selectedValue?.length ? null : user.email,
  //           sortBy,
  //           false,
  //           searchQuery,
  //           dateSelect
  //         )
  //         .then((data) => {
  //           // if (!data?.length) {
  //           //   setCurrCampIndex((prev) => prev + 1);
  //           //   setCurrCampPage(1);
  //           //   return;
  //           // }
  //           // console.log(campaignId, data);
  //           setCount(data.metaData.count);
  //           setLeadsByExludingEmail(data.leads);
  //           setDisablePage(false);
  //           setLoadingAILeads(false);
  //         })
  //         .catch((error) => {
  //           console.log("error", error);
  //         });
  //       setLoadingAILeads(false);
  //       handleLoader(false);
  //     }
  //     handleFilteredLeads();
  //     // (async () => {
  //     //   if (campaigns?.data?.length === 0) return;
  //     //   setLoadingAILeads(true);
  //     //   // if (!currCampaign) {
  //     //   //   setLeadsByExludingEmail([]);
  //     //   //   setDisablePage(false);
  //     //   //   return;
  //     //   // }
  //     //   controller?.abort();
  //     //   controller = new AbortController();
  //     //   // const campaignId = currCampaign?._id;
  //     //   setIsloading(true);
  //     //   const filterKeys = Object.keys(filters);
  //     //   const trimmedFilters = {};
  //     //   for (let key of filterKeys) {
  //     //     if (!filters[key].active) {
  //     //       continue;
  //     //     }
  //     //     trimmedFilters[key] = true;
  //     //   }
  //     //   if (Object.keys(trimmedFilters)?.length) {
  //     //     // fetchFilteredLeads(trimmedFilters, campaignId, controller)
  //     //     await fetchFilteredLeads(
  //     //       trimmedFilters,
  //     //       selectedValue?.length
  //     //         ? selectedValue?.map(({ _id }) => _id)?.join(",")
  //     //         : "all",
  //     //       controller,
  //     //       sortBy,
  //     //       false,
  //     //       searchQuery
  //     //     )
  //     //       .then((data) => {
  //     //         console.log("data::::::: leads ", data);
  //     //         if (!data?.metaData?.count) {
  //     //           // if (!data?.length) {
  //     //           //   setCurrCampIndex((prev) => prev + 1);
  //     //           //   setCurrCampPage(1);
  //     //           //   return;
  //     //           // }
  //     //           if (data?.length) {
  //     //             const lastElem = data?.pop();
  //     //             var count = lastElem?.count;
  //     //             if (count === undefined) {
  //     //               data?.push(lastElem);
  //     //             }
  //     //             setCount(count);
  //     //           } else {
  //     //             setCount(data?.metaData?.count);
  //     //           }
  //     //           // if(trimmedFilters.messages){

  //     //           // }

  //     //           setLeadsByExludingEmail(data?.length > 0 ? data : []);
  //     //           setDisablePage(false);
  //     //           setLoadingAILeads(false);
  //     //         } else {
  //     //           setCount(data.metaData.count);
  //     //           setLeadsByExludingEmail(
  //     //             data?.leads?.length > 0 ? data?.leads : []
  //     //           );
  //     //           setDisablePage(false);
  //     //           setLoadingAILeads(false);
  //     //         }
  //     //       })
  //     //       .catch((error) => {
  //     //         console.log("error", error);
  //     //       });
  //     //     return;
  //     //   }
  //     //   jobsApi
  //     //     // .getCampaignLeads(campaignId, currCampPage, LEADS_PER_PAGE, controller)
  //     //     .getCampaignLeadsV2(
  //     //       selectedValue?.length
  //     //         ? selectedValue?.map(({ _id }) => _id)?.join(",")
  //     //         : null,
  //     //       page,
  //     //       LEADS_PER_PAGE,
  //     //       controller,
  //     //       selectedValue?.length ? null : user.email,
  //     //       sortBy,
  //     //       false,
  //     //       searchQuery,
  //     //       dateSelect
  //     //     )
  //     //     .then((data) => {
  //     //       // if (!data?.length) {
  //     //       //   setCurrCampIndex((prev) => prev + 1);
  //     //       //   setCurrCampPage(1);
  //     //       //   return;
  //     //       // }
  //     //       // console.log(campaignId, data);
  //     //       setCount(data.metaData.count);
  //     //       setLeadsByExludingEmail(data.leads);
  //     //       setDisablePage(false);
  //     //       setLoadingAILeads(false);
  //     //     })
  //     //     .catch((error) => {
  //     //       console.log("error", error);
  //     //     });
  //     //   setLoadingAILeads(false);
  //     //   handleLoader(false);
  //     // })();
  //   },
  //   // [currCampaign, currCampPage, filters]
  //   [
  //     page,
  //     filters,
  //     campaigns,
  //     sortBy,
  //     searchQuery,
  //     dateSelect,
  //     selectedValue,
  //     selectedStatus,
  //   ]
  // );

  const handleFilteredLeads = async () => {

    console.log('>?>LLD<cola')
    handleLoader(true);
    if (campaigns?.data?.length === 0) return;
    setLoadingAILeads(true);
    // if (!currCampaign) {
    //   setLeadsByExludingEmail([]);
    //   setDisablePage(false);
    //   return;
    // }
    controller?.abort();
    controller = new AbortController();
    // const campaignId = currCampaign?._id;
    setIsloading(true);
    const filterKeys = Object.keys(filters);
    const trimmedFilters = {};
    let fetchedData;
    for (let key of filterKeys) {
      if (!filters[key].active) {
        continue;
      }
      trimmedFilters[key] = true;
    }

    if (Object.keys(trimmedFilters)?.length || tab) {
      // fetchFilteredLeads(trimmedFilters, campaignId, controller)
      fetchedData = await fetchFilteredLeads(
        Object.keys(trimmedFilters)?.length > 0
          ? trimmedFilters
          : { [tab]: true },
        selectedValue?.length
          ? selectedValue?.map(({ _id }) => _id)?.join(",")
          : "all",
        controller,
        sortBy,
        false,
        searchQuery
      )
        .then((data) => {
          console.log("data::::::: leads ", data);
          if (!data?.metaData?.count) {
            // if (!data?.length) {
            //   setCurrCampIndex((prev) => prev + 1);
            //   setCurrCampPage(1);
            //   return;
            // }
            if (data?.length) {
              const lastElem = data?.pop();
              var count = lastElem?.count;
              if (count === undefined) {
                data?.push(lastElem);
              }
              setCount(count);
            } else {
              setCount(data?.metaData?.count);
            }
            // if(trimmedFilters.messages){

            // }

            setLeadsByExludingEmail(data?.length > 0 ? data : []);
            setDisablePage(false);
            setLoadingAILeads(false);
          } else {
            setCount(data.metaData.count);
            setLeadsByExludingEmail(data?.leads?.length > 0 ? data?.leads : []);
            setDisablePage(false);
            setLoadingAILeads(false);
          }
          return data;
        })
        .catch((error) => {
          console.log("error", error);
        });
      console.log("fetchedData", fetchedData);
      return fetchedData;
    }
    fetchedData = await jobsApi
      // .getCampaignLeads(campaignId, currCampPage, LEADS_PER_PAGE, controller)
      .getCampaignLeadsV2(
        selectedValue?.length
          ? selectedValue?.map(({ _id }) => _id)?.join(",")
          : null,
        page,
        LEADS_PER_PAGE,
        controller,
        selectedValue?.length ? null : user.email,
        sortBy,
        false,
        searchQuery,
        dateSelect
      )
      .then((data) => {
        // if (!data?.length) {
        //   setCurrCampIndex((prev) => prev + 1);
        //   setCurrCampPage(1);
        //   return;
        // }
        // console.log(campaignId, data);
        setCount(data.metaData.count);
        setLeadsByExludingEmail(data.leads);
        setDisablePage(false);
        setLoadingAILeads(false);
        return data;
      })
      .catch((error) => {
        console.log("error", error);
      });
    setLoadingAILeads(false);
    handleLoader(false);
    console.log("fetchedData 1", fetchedData);
    return fetchedData;
  };

  const selectedTab = filters?.[tab];

  const {
    isLoading: isFilteredLeadsPending,
    isError: isFilteredLeadsError,
    data: filterLeads = [],
    // refetch: refetchTotalData,
  } = useQuery({
    queryKey: [
      "filterLeads",
      page,
      filters,
      selectedTab?.label,
      selectedTab?.value,
      campaigns,
      sortBy,
      dateSelect,
      selectedValue,
      selectedStatus,
    ],
    queryFn: () => handleFilteredLeads(),
    enabled: campaigns?.data?.length > 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!isFilteredLeadsPending) {
      setCount(filterLeads?.metaData?.count || 0);
      setLeadsByExludingEmail(filterLeads?.leads || []);
      if (filterLeads?.tempMeeting) {
        setMeetingsArray(filterLeads?.tempMeeting);
      }
    }
  }, [
    isFilteredLeadsPending,
    filters,
    campaigns,
    sortBy,
    dateSelect,
    selectedValue,
    selectedStatus,
  ]);

  useEffect(() => {
    // if (selectedStatus !== "All") {
    handleFilteredLeads();
    // }
  }, [selectedStatus, selectedStage]);

  useEffect(() => {
    if (searchText.length) {
      const lowerCaseSearchText = searchText?.toLowerCase();

      const filteredData = leads.filter((data) => {
        const fieldsToSearch = [
          data?.email,
          data?.lastName,
          data?.firstName,
          data?.organization,
          data?.description,
          data?.address?.city,
          data?.address?.state,
          data?.address?.country,
        ];

        return fieldsToSearch.some((field) =>
          field?.toLowerCase().includes(lowerCaseSearchText)
        );
      });
      setFilteredLeads(filteredData);
    }
  }, [searchText]);

  useEffect(() => {
    var orgId = getSessionStorage("organization_id");
    getUserCSVData(orgId);
  }, []);

  useEffect(() => {
    setFilteredLeads(leads);
  }, [leads]);

  // console.log(filters.emailsSent.active, "filtersfiltersfiltersfilters");
  // console.log(filteredLeads, "filteredLeads");
  // console.log("SELECTED ITEMS ------> ", selectedItems);
  // console.log(customersSearch.state, "customersSearch");
  // console.log("loadingAILeads", loadingAILeads);

  const formatDate = (date) => {
    // return new Date(date).toISOString().slice(0, 19).replace("T", " ");
    const timestamp = new Date(date);

    const year = timestamp.getFullYear();
    const month = String(timestamp.getMonth() + 1).padStart(2, "0");
    const day = String(timestamp.getDate()).padStart(2, "0");

    const hours = String(timestamp.getHours() % 12 || 12).padStart(2, "0");
    const minutes = String(timestamp.getMinutes()).padStart(2, "0");
    const ampm = timestamp.getHours() >= 12 ? "pm" : "am";

    return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
  };

  const formatedDate = (date) => {
    if (date !== undefined) {
      return new Date(date).toISOString().slice(0, 19).replace("T", " ");
    } else {
      return "-";
    }
  };
  const formatedarrayDate = (date) => {
    const datelist = [];
    if (date !== undefined) {
      date.map((value) => {
        const formattedDate = new Date(value)
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");
        datelist.push(formattedDate);
      });
      return datelist;
    } else {
      return "-";
    }
  };

  const dateTime = (dateString, timeString) => {
    const dateTimeString = dateString + " " + timeString;
    const dateTime = moment(dateTimeString, "YYYY-MM-DD h:mmA");
    var formattedDateTimeString = dateTime.toISOString();
    return formattedDateTimeString;
  };

  const handleMeetingClicked = async (meeting) => {
    const matchedData = meetingsArray.find((data) => {
      return data._id === meeting;
    });
  };

  const getProspectData = async (email, campaignId) => {
    try {
      const response = await axios.get(
        `${API_SERVICE_BACKEND}/leadInfo?campaignId=${campaignId}&leadEmail=${email}`
      );
      const { nextFollowUpStep, enrolledFor, emailSeqStatus, emailStatus } =
        response?.data?.lead[0];
      setTimelineData({
        nextFollowUpStep,
        enrolledFor,
        emailSeqStatus,
        emailStatus,
      });
      setTimelineOpen(true);
    } catch (error) {
      setTimelineData({});
      setTimelineOpen(false);
      console.log("Err", error);
    }
  };
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const optionsWithSelectAll = [
    { responsibility: "All AI Agent", selectAll: true },
    ...(aiAgents || []),
  ];

  const inputRef = React.useRef(null);

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  };


  const [openBlockListModel, setOpenBlockListModel] = useState(null);

  const handleEmailBlockList = async () => {
    try {
      const payload = {
        clientEmail: clientEmail,
        addEmails: new Set(selectedLeads),
      };
      const res = await axios.put(
        `https://api.b2brocket.ai/api/v1/main/exclusionlist/updateExclusionList`,
        payload
      );
      console.log("response", res);
      toast.success("Sucessfully added to Blocklist");
      setOpenBlockListModel(false)
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
      setOpenBlockListModel(false)
    }
  };

  const handleDomainBlockList = async () => {
    try {
      const payload = {
        clientEmail: clientEmail,
        addDomains: new Set(selectedLeads?.map((item) => item?.split('@')[1])),
      };
      const res = await axios.put(
        `https://api.b2brocket.ai/api/v1/main/exclusionlist/updateExclusionList`,
        payload
      );
      console.log("response", res);
      toast.success("Sucessfully added to Blocklist");
      setOpenBlockListModel(false)
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
      setOpenBlockListModel(false)
    }
  };

  const handleBlockListModel = (event) => {
    setOpenBlockListModel(event.currentTarget);
  };

  const handleBlockListModelClose = () => {
    setOpenBlockListModel(null);
  };

  useEffect(() => {
    setSelectedStage("");
  }, [tab]);
  const { companyName, metaTitle } = React.useContext(WhiteLabelConfigurationContext);
  const pageTitle = `Leads: Leads - ${metaTitle || companyName }`

  return (
    <>
      <Seo title={pageTitle} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialog === "emailView" ? (
          <>
            <DialogTitle id="alert-dialog-title">Email sent</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {/* {htmlParser(currOpenEmail?.email)} */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: currOpenEmail.html,
                  }}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              {/* <Button onClick={showWarning} disabled={loading} autoFocus>
                Continue
              </Button> */}
            </DialogActions>
          </>
        ) : null}
      </Dialog>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
          //backgroundColor: alpha(theme.palette.primary.main, 0.05),
        }}
      >
        <Container
          maxWidth="2xl"
          style={{ paddingX: "30px" }}
        // style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          <Stack spacing={4}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={4}
            >
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <div>
                  <Typography
                    sx={{
                      fontSize: "32px",
                      fontWeight: "700",
                      fontFamily: "Inter",
                    }}
                  >
                    Leads
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        marginTop: "10px",
                        fontFamily: "Inter",
                      }}
                    >
                      Total Leads: {count < 0 ? 0 : count}
                    </Typography>
                    {/* {selectedLeads.length > 0 && (
                      <Button
                        variant="outlined"
                        sx={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          fontFamily: "Inter",
                        }}
                        onClick={handleBlockList}
                      >
                        Add to Block List ({selectedLeads.length})
                      </Button>
                    )} */}
                    {selectedLeads.length > 0 && (
                      <div>
                        <Button
                          variant="outlined"
                          sx={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            fontFamily: "Inter",
                          }}
                          onClick={handleBlockListModel}
                        >
                          Add to Block List ({selectedLeads.length})
                          {openBlockListModel ? (
                            <ArrowDropUp />
                          ) : (
                            <ArrowDropDown />
                          )}
                        </Button>

                        <Menu
                          anchorEl={openBlockListModel}
                          open={Boolean(openBlockListModel)}
                          onClose={handleBlockListModelClose}
                          sx={{ "& .MuiMenuItem-root": { fontSize: "14px" } }}
                        >
                          <MenuItem onClick={handleEmailBlockList}>Add to Email Block List</MenuItem>
                          <MenuItem onClick={handleDomainBlockList}>Add to Domain Block List</MenuItem>
                        </Menu>
                      </div>
                    )}
                  </div>
                </div>

                <Stack alignItems="center" direction="row" spacing={1}>
                  {/* <Button
                    color="inherit"
                    size="small"
                    startIcon={(
                      <SvgIcon>
                        <Upload01Icon />
                      </SvgIcon>
                    )}
                  >
                    Import
                  </Button> */}
                  {stageFilterTabs.includes(tab) && (
                    <FormControl sx={{ minWidth: "200px" }}>
                      <InputLabel
                        shrink
                        sx={{
                          "&.MuiInputLabel-shrink.MuiInputLabel-outlined": {
                            transform: "translate(14px, -10px) scale(1)",
                          },
                        }}
                      >
                        Stages
                      </InputLabel>
                      <Select
                        displayEmpty
                        label={"Stages"}
                        value={selectedStage}
                        disabled={isloading}
                        onChange={(e) => {
                          setSelectedStage(e.target.value);
                        }}
                        sx={{
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          height: "42px",
                          borderadius: "8px",
                          marginLeft: "8px",
                          "& fieldset": {
                            boxShadow: "none !important"
                          },
                          "& .MuiOutlinedInput-input": {
                            lineHeight: "20px"
                          }
                        }}
                      >
                        <MenuItem value="">All Stages</MenuItem>

                        {Object.entries(stages).map(([key, value]) => {
                          return <MenuItem value={key}>{value}</MenuItem>;
                        })}

                        {/*<MenuItem value="" disabled>*/}
                        {/*  Stage*/}
                        {/*</MenuItem>*/}
                        {/*<MenuItem value={1}>Initial Email Sequence</MenuItem>*/}
                        {/*<MenuItem value={2}>Conversation Sequence</MenuItem>*/}
                        {/*<MenuItem value={3}>Prospect Books a Meeting</MenuItem>*/}
                        {/*<MenuItem value={4}>Prospect Misses a Meeting</MenuItem>*/}
                      </Select>
                    </FormControl>
                  )}
                  {true && (
                    <LoadingButton
                      loading={exportingCSV}
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                        },
                        minWidth: "120px",
                        fontSize: "14px",
                        fontFamily: "Inter",
                      }}
                      loadingPosition="start"
                      startIcon={
                        <SvgIcon>
                          <DownloadCloud02 />
                        </SvgIcon>
                      }
                      size="small"
                      // variant="outlined"
                      onClick={exportCSVData}
                      disabled={transferLoading}
                    >
                      Export
                    </LoadingButton>
                  )}
                  <Snackbar
                    open={Baropen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                  >
                    <Alert
                      onClose={handleSnackbarClose}
                      severity={snackbarSeverity}
                    >
                      {snackbarMessage}
                    </Alert>
                  </Snackbar>

                  <Transfer
                    customersSearch={customersSearch}
                    emailStageStatus={emailStageStatus}
                    selectedData={selectedData}
                    openSnackbar={openSnackbar}
                    transferLoading={transferLoading}
                    setTransferLoading={setTransferLoading}
                  />
                </Stack>
              </Stack>

              {/* <Stack
                alignItems="center"
                direction="row"
                spacing={3}
              >
                <Button
                  startIcon={(
                    <SvgIcon>
                      <PlusIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                >
                  Add
                </Button>
              </Stack> */}
            </Stack>
            {/* <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              sx={{
                px: 3,
                py: 2,
              }}
            >
              <IconButton
                disabled={disablePage || page === 1}
                onClick={() => changeAugPage(-1)}
              >
                <SvgIcon fontSize="small">
                  <ChevronLeftIcon />
                </SvgIcon>
              </IconButton>
              <Typography variant="caption">{page}</Typography>
              <IconButton
                disabled={disablePage || leads.length < LEADS_PER_PAGE}
                onClick={() => changeAugPage(1)}
              >
                <SvgIcon fontSize="small">
                  <ChevronRightIcon />
                </SvgIcon>
              </IconButton>
            </Stack> */}
            <div
              class="newUI"
              style={{
                border: "1px solid #E0E0E5",
                background: "white",
                borderRadius: "10px",
              }}
            >
              {/* <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{
                  p: "0px 20px",
                  borderBottom: `1px solid ${theme.palette.grey[200]}`,
                  //background:'white',
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  // borderRadius:'10px'
                }}
              >
                <CustomerListDropdown
                  onFiltersChange={customersSearch.handleFiltersChange}
                  onAIFiltersChange={changeLeadsFilters}
                  onSortChange={customersSearch.handleSortChange}
                  sortBy={customersSearch.state.sortBy}
                  sortDir={customersSearch.state.sortDir}
                  selectedTab={tab}
                  setTab={setTab}
                  setSearchQuery={setSearchQuery}
                  setSearchText={setSearchText}
                />
              </Stack> */}
              {!blockListTabs.includes(tab) && <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems="center"
                width="100%"
                my={2}
                sx={{
                  p: "0px 20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "8px", }}>
                    <OutlinedInput
                      onChange={handleSearchChange}
                      size="small"
                      // disabled={loader}
                      placeholder="Search Contacts"
                      startAdornment={
                        <InputAdornment position="start">
                          <SvgIcon style={{
                            color: theme.palette.text.primary, 
                          }}
                          >
                            <SearchMdIcon />
                          </SvgIcon>
                        </InputAdornment>
                      }
                      value={searchText}
                    />
                  </div>
                  <TimelineDropdown
                    displayAll={true}
                    setDateRange={setDateSelect}
                    // height="52px"
                    handleCustomRange={handleClick}
                    loader={loader}
                  />
                  <>
                    {/* <Button variant="outlined"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                    fontSize: "13px",
                    fontWeight: 700,
                    lineHeight: "16.38px",
                    color: "#28287B",
                    backgroundColor: "#fff",
                    px: 1.5,
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                    border: "1px solid #E4E4E5",
                    height: "52px",
                    //marginLeft:'10px',
                  }}>
                  <CalendarTodayRoundedIcon />
                  <Button onClick={handleClick}>Custom Range</Button>
                </Button> */}
                    <Popover
                      id={id}
                      open={popOpen}
                      onClose={handlePopClose}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      sx={{
                        width: "-webkit-fill-available",
                      }}
                    >
                      <DateWrapper
                        setAnchorEl={setAnchorEl}
                        dateRange={dateSelect}
                        setDateRange={setDateSelect}
                        onDateRangeChange={onDateRangeChange}
                      />
                    </Popover>
                  </>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    size="small"
                    id="checkboxes-tags-demo"
                    options={[
                      {
                        responsibility: "All AI Campaign",
                        selectAll: true,
                        archived: "",
                      },
                      ...(aiAgents || []),
                    ] || []}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.responsibility}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{ fontFamily: "Inter", fontSize: "14px" }}
                      >
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selectedOptions.some((item) =>
                            option.selectAll
                              ? selectedOptions.length === campaigns?.data?.length
                              : item._id === option._id
                          )}
                        />
                        {option.responsibility}
                        <div
                          style={{
                            border:
                              option.archived === ""
                                ? ""
                                : option.archived
                                  ? "1px solid #F04438"
                                  : "1px solid #10B981",
                            padding: "4px 8px",
                            borderRadius: "16px",
                            background:
                              option.archived === ""
                                ? ""
                                : option.archived
                                  ? "#F04438"
                                  : "#10B981",
                            color: "white",
                            fontSize: "14px",
                            marginLeft: "4px",
                            fontFamily: "Inter",
                          }}
                        >
                          {option.archived === ""
                            ? ""
                            : option.archived
                              ? " Archived"
                              : " Active"}
                        </div>
                      </li>
                    )}
                    sx={{
                      width: 300,
                      "& .MuiOutlinedInput-root": {
                        paddingRight: "65px",
                        height: "auto",
                        minHeight: "40px",
                        maxHeight: "120px",
                        overflow: "auto",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E0E0E0",
                        },
                        "& .MuiAutocomplete-endAdornment": {
                          right: "8px",
                          position: "absolute",
                          top: "8px",
                          background: "white",
                          paddingLeft: "8px",
                        },
                        "&::-webkit-scrollbar": {
                          width: "6px",
                        },
                        "&::-webkit-scrollbar-track": {
                          background: "#f1f1f1",
                          borderRadius: "3px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#888",
                          borderRadius: "3px",
                          "&:hover": {
                            background: "#757575",
                          },
                        },
                      },
                      "& .MuiAutocomplete-tag": {
                        margin: "2px",
                        maxWidth: "calc(100% - 70px)",
                      },
                      "& .MuiInputBase-root": {
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "flex-start",
                      },
                      "& .MuiAutocomplete-inputRoot": {
                        flexWrap: "wrap",
                      },
                      "& .MuiAutocomplete-input": {
                        width: "0",
                        minWidth: "30px",
                        flexGrow: 1,
                      },
                    }}
                    value={selectedOptions}
                    onChange={handleChange}
                    PaperComponent={CustomPaper}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="AI Campaign"
                        variant="outlined"
                        inputRef={inputRef}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: "15px" },
                        }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                  {filters.replies.active && (
                    <Autocomplete
                      disabled={loader}
                      size="small"
                      id="status-autocomplete"
                      options={statusList}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{ fontFamily: "Inter", fontSize: "14px" }}
                        >
                          <img
                            src={option.icon}
                            style={{ marginRight: "10px" }}
                            alt=""
                          />
                          {option.label}
                        </li>
                      )}
                      sx={{ width: 250, marginLeft: "10px" }}
                      value={
                        statusList.find(
                          (status) => status.label === selectedStatus
                        ) || null
                      }
                      onChange={handleStatusChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status"
                          variant="outlined"
                          inputRef={inputRef}
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: "15px" },
                          }}
                          sx={{
                            "& fieldset": {
                              boxShadow: "none !important"
                            },
                            "& .MuiOutlinedInput-input": {
                              lineHeight: "20px"
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                onClick={handleIconClick}
                                style={{ cursor: "pointer", fontSize: "14px" }}
                              >
                                <ExpandMoreRoundedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                  {(!blockListTabs.includes(tab) && loader) ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50px",
                      }}
                    >
                      <CircularProgress
                        sx={{ width: "20px", height: "20px", padding: "5px" }}
                      />
                    </Box>
                  ) : (
                    <Box></Box>
                  )}
                  {/* <FormControl
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    borderadius: "8px",
                    margin: '6px',
                    background: "var(--base-white, #FFF)",
                  }}
                >
                  <Select
                    displayEmpty
                    value={selectedValue}
                    onChange={handleChange}
                    sx={{
                      color: "var(--primary-dark-purple, #050C46)",
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "20px",
                      position: "static",
                      height: "52px",
                      borderadius: "8px",
                    }}
                  >
                    <MenuItem value="">
                      All AI Agent
                    </MenuItem>
                    {campaigns?.data?.map((campagin) => (
                      <MenuItem
                        key={campagin._id}
                        value={campagin}
                      >
                        {campagin.responsibility}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                  {/* <FormControl >
                  <Select
                    displayEmpty
                    value={selectedStage}
                    onChange={handleIntialChange}
                    sx={{
                      color: "#050C46",
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      height: "35px",
                      borderadius: "8px",
                    }}
                  >
                    <MenuItem value="">
                      All Stages
                    </MenuItem>

                    {
                      Object.entries(stages).map(([key, value]) => {
                        return <MenuItem value={key}>{value}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl> */}
                </div>

                {(visible || leads.length === 0) && (
                  <div>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CustomerListDropdown
                        onFiltersChange={customersSearch.handleFiltersChange}
                        onAIFiltersChange={changeLeadsFilters}
                        onSortChange={customersSearch.handleSortChange}
                        sortBy={customersSearch.state.sortBy}
                        sortDir={customersSearch.state.sortDir}
                        selectedTab={tab}
                        setTab={setTab}
                        setSearchQuery={setSearchQuery}
                        setSearchText={setSearchText}
                      />

                      <Button
                        color="inherit"
                        size="small"
                        variant="outlined"
                        disabled={loader}
                        sx={{
                          border: `1px solid ${theme.palette.grey[300]}`,
                          backgroundColor: "white",
                          "&:hover": {
                            backgroundColor: "white",
                            border: `1px solid ${theme.palette.primary.main}`,
                          },
                        }}
                        endIcon={
                          <SvgIcon>
                            {sortBy.creationTime === 1 ? (
                              <ExpandLessRoundedIcon />
                            ) : (
                              <ExpandMoreRoundedIcon />
                            )}
                          </SvgIcon>
                        }
                        onClick={() => {
                          changeSort(
                            "creationTime",
                            sortBy.creationTime === 1 ? -1 : 1
                          );
                          changeSort(
                            "emailSentTime",
                            sortBy.emailSentTime === 1 ? -1 : 1
                          );
                        }}
                      >
                        {sortBy.creationTime === 1
                          ? "Oldest First"
                          : "Latest First"}
                      </Button>
                    </Box>
                  </div>
                )}

              </Stack>}
              <Card
                sx={{
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: "0px",
                }}
              >{console.log(customersSearch, blockListTabs, tab, 'customersSearchcustomersSearch=>')}
                {blockListTabs.includes(tab) && <BlackList setTab={setTab} userEmail={user?.email} value={tab == "emailBlocklist" ? 0 : 1} tab={tab} />}
                {!blockListTabs.includes(tab) && !loadingAILeads ? (
                  // campaigns?.data?.length > 0 ? (
                  <>
                    {false ? (
                      // {customersSearch.state.filters.unsubscribe ? (
                      <Typography sx={{ ml: 2, mb: 2 }}></Typography>
                    ) : tab === 'meetings' ? (
                      <TableContainer
                        sx={{ mt: 0, borderRadius: "0px" }}
                        component={Paper}
                      >
                        <Table
                          sx={{ minWidth: "100%", borderRadius: "0px" }}
                          aria-label="simple table"
                        >
                          <TableHead
                            sx={{
                              "& .MuiTableCell-root": {
                                color: theme.palette.text.primary
                              },
                            }}
                          >
                            <TableRow
                              sx={{
                                "& .MuiTableCell-root": {
                                  backgroundColor: "#E9E9EC",
                                  borderBottom: `1px solid #E9E9EC}`,
                                },
                              }}
                            >
                              <TableCell align="center">#</TableCell>
                              <TableCell align="center">TITLE</TableCell>
                              <TableCell align="center">PROSPECT NAME</TableCell>
                              <TableCell align="center">DESCRIPTION</TableCell>
                              <TableCell align="center">DATE</TableCell>
                              <TableCell align="center">TIME</TableCell>
                              <TableCell align="center">TIMEZONE</TableCell>
                              <TableCell align="center">PROSPECT EMAIL</TableCell>
                              <TableCell align="center">TIMELINE</TableCell>
                              <TableCell align="center">MEETING STATUS</TableCell>
                              <TableCell align="center">JOIN MEET</TableCell>
                              <TableCell align="center">BOOKED ON</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tab === 'meetings' &&
                              // page === 1 &&
                              meetingsArray
                                .sort(function (a, b) {
                                  var keyA = moment
                                    .tz(a.meeting_date, a.timezone)
                                    .unix(),
                                    keyB = moment
                                      .tz(b.meeting_date, b.timezone)
                                      .unix();
                                  // Compare the 2 dates
                                  if (keyA < keyB) return 1;
                                  if (keyA > keyB) return -1;
                                  return 0;
                                })
                                .map((item, index) => {
                                  // const formattedSentEmailDate = new Date(item.meeting_date).toISOString().slice(0, 19).replace("T", " ");
                                  return (
                                    <TableRow
                                      // key={item.id}
                                      key={index}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                        "& .MuiTableCell-root": {
                                          backgroundColor: "#FFFFFF",
                                          borderBottom: `1px solid ${theme.palette.grey[300]}`,
                                          fontWeight: "500",
                                        },
                                      }}
                                    >
                                      {/* <TableCell>{leads.length + index + 1}</TableCell> */}
                                      <TableCell align="center">
                                        #
                                        {LEADS_PER_PAGE * (page - 1) +
                                          index +
                                          1}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.title}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.prospect_name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.description}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.meeting_date + " " + item.time}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.time}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.timezone}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.prospectEmail}
                                      </TableCell>
                                      <TableCell>
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            getProspectData(
                                              item.prospectEmail,
                                              item.campaignId
                                            );
                                          }}
                                        >
                                          <img src={TimeLine} />
                                        </IconButton>
                                      </TableCell>
                                      <TableCell align="center">
                                        <MeetingStatusButtons
                                          id={item?._id}
                                          status={item?.meeting_status}
                                        />
                                      </TableCell>
                                      {item.link ? (
                                        <TableCell align="center">
                                          <Button
                                            variant="outlined"
                                            onClick={() => {
                                              window.open(item.link, "_blank");
                                            }}
                                          >
                                            Join
                                            <VideoCallRoundedIcon fontSize="large" />
                                          </Button>
                                        </TableCell>
                                      ) : (
                                        <TableCell align="center">
                                          <Button variant="outlined">
                                            Pending
                                          </Button>
                                        </TableCell>
                                      )}
                                      <TableCell align="center">
                                        {formatDate(item.createdAt)}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <>
                        {filteredLeads.length > 0 && (
                          <TableContainer
                            sx={{ mt: 0, borderRadius: "0px" }}
                            component={Paper}
                          >
                            <Table
                              sx={{ Width: "100%", borderRadius: "0px" }}
                              aria-label="simple table"
                            >
                              <TableHead
                                sx={{
                                  "& .MuiTableCell-root": {
                                    color: theme.palette.text.primary
                                  },
                                }}
                              >
                                <TableRow
                                  sx={{
                                    "& .MuiTableCell-root": {
                                      backgroundColor: "#E9E9EC",
                                      borderBottom: `1px solid #E9E9EC}`,
                                    },
                                  }}
                                >
                                  <TableCell
                                  // style={{borderRight:'2px solid #CECED3'}}
                                  >
                                    <Checkbox
                                      checked={
                                        selectedItems.length ===
                                        filteredLeads.length
                                      }
                                      onChange={handleRowSelectAll}
                                    />
                                  </TableCell>
                                  <TableCell align="center">#</TableCell>
                                  <TableCell
                                    align="center"
                                    width={
                                      filters.emailsSent.active ? "0%" : "10%"
                                    }
                                  >
                                    FIRST NAME
                                  </TableCell>
                                  <TableCell align="center">LAST NAME</TableCell>
                                  <TableCell align="center">TITLE</TableCell>
                                  <TableCell align="center">COMPANY</TableCell>
                                  <TableCell align="center">Location</TableCell>
                                  <TableCell align="center">EMAIL</TableCell>
                                  {/* <TableCell align="left">SOURCE</TableCell> */}
                                  {isZohoIntegration && (
                                    <TableCell
                                      align="center"
                                      sx={{ minWidth: "100px" }}
                                    >
                                      Posted to Zoho
                                    </TableCell>
                                  )}
                                  {isHubspotIntegration && (
                                    <TableCell
                                      align="center"
                                      sx={{ minWidth: "100px" }}
                                    >
                                      Posted to Hubspot
                                    </TableCell>
                                  )}
                                  <TableCell
                                    align="center"
                                    sx={{ minWidth: "150px" }}
                                  >
                                    Industry
                                  </TableCell>
                                  <TableCell align="center">Profile</TableCell>
                                  <TableCell align="center">Expand</TableCell>
                                  {/* <TableCell>Engagements</TableCell> */}

                                  {/* <TableCell align="left" width="25%">
                                PHONE
                              </TableCell> */}
                                  {/* <TableCell align="left" >AGENT</TableCell> */}
                                  {/* <TableCell align="left" width={filters.emailsSent.active ? "0%" :"20%"} >CONVERSATION</TableCell>
                              <TableCell align="left" width={filters.emailsSent.active ? "0%" :"10%"}>
                                ENGAGED DATE
                              </TableCell> */}
                                  {filters.emailsSent.active && (
                                    <>
                                      <TableCell align="center">
                                        Email Status
                                      </TableCell>
                                    </>
                                  )}
                                  {filters.replies.active && (
                                    <>
                                      <TableCell align="center">Status</TableCell>
                                    </>
                                  )}
                                  {filters.replies.active && (
                                    <>
                                      <TableCell align="center">
                                        Replies
                                      </TableCell>
                                    </>
                                  )}
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {true ? (
                                  filteredLeads?.map(
                                    (
                                      {
                                        _id,
                                        emailStatus,
                                        firstName,
                                        lastName,
                                        email,
                                        emailBody,
                                        description,
                                        linkedin_url,
                                        organization,
                                        openedAt,
                                        clickedAt,
                                        respondedAt,
                                        pageViewAt,
                                        meetingBookedAt,
                                        conversationAt,
                                        industry,
                                        address,
                                        campaignIds,
                                        sentAt,
                                        nextFollowUpStep,
                                        enrolledFor,
                                        emailSeqStatus,
                                        isZohoPosted,
                                        isHubspotPosted,
                                        source,
                                        unverifiedEmail,
                                        useBouncerStatus,
                                        videoViewDuration,
                                        videoViewed,
                                        videoViewedAt,
                                        latestSentAt,
                                        status,
                                      },
                                      index
                                    ) => {
                                      return (
                                        <LeadRow
                                          key={_id}
                                          theme={theme}
                                          setTimelineData={setTimelineData}
                                          formatDate={formatDate}
                                          setTimelineOpen={setTimelineOpen}
                                          timelineData={timelineData}
                                          formatedarrayDate={formatedarrayDate}
                                          handleRowSelect={handleRowSelect}
                                          viewSentEmail={viewSentEmail}
                                          tab={tab}
                                          setTab={setTab}
                                          expand={expand}
                                          setExpand={setExpand}
                                          filters={filters}
                                          isHubspotIntegration={
                                            isHubspotIntegration
                                          }
                                          setHubspotIntegration={
                                            setHubspotIntegration
                                          }
                                          isZohoIntegration={isZohoIntegration}
                                          setZohoIntegration={
                                            setZohoIntegration
                                          }
                                          index={index}
                                          page={page}
                                          setPage={setPage}
                                          selectedItems={selectedItems}
                                          setSelectedItems={setSelectedItems}
                                          data={{
                                            _id,
                                            emailStatus,
                                            firstName,
                                            lastName,
                                            email,
                                            emailBody,
                                            description,
                                            linkedin_url,
                                            organization,
                                            openedAt,
                                            clickedAt,
                                            respondedAt,
                                            pageViewAt,
                                            meetingBookedAt,
                                            conversationAt,
                                            industry,
                                            address,
                                            campaignIds,
                                            sentAt,
                                            nextFollowUpStep,
                                            enrolledFor,
                                            emailSeqStatus,
                                            isZohoPosted,
                                            isHubspotPosted,
                                            source,
                                            unverifiedEmail,
                                            useBouncerStatus,
                                            videoViewDuration,
                                            videoViewed,
                                            videoViewedAt,
                                            latestSentAt,
                                            status,
                                          }}
                                        />
                                      );
                                    }
                                  )
                                ) : (
                                  <div style={{ margin: "auto" }}>
                                    <CircularProgress />
                                  </div>
                                )}
                                {/* {!customersSearch.state.filters.clicks &&
                          !customersSearch.state.filters.conversations &&
                          // !customersSearch.state.filters.meetings &&
                          page === 1 &&
                          array.map((item, index) => (
                            <TableRow
                              key={item.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{leads.length + index + 1}</TableCell>
                              {Object.values(item).map((val) => (
                                <TableCell align="center">
                                  {regex.test(val) ? (
                                    <Tooltip title="View linkedin profile">
                                      <a href={val} target="_blank">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          x="0px"
                                          y="0px"
                                          width="36"
                                          height="36"
                                          viewBox="0 0 48 48"
                                        >
                                          <path
                                            fill="#0288D1"
                                            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                          ></path>
                                          <path
                                            fill="#FFF"
                                            d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                          ></path>
                                        </svg>
                                      </a>
                                    </Tooltip>
                                  ) : (
                                    <>{val}</>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        {customersSearch.state.filters.clicks &&
                          page === 1 &&
                          clicksArray.map((item, index) => (
                            <TableRow
                              key={item.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{leads.length + index + 1}</TableCell>
                              {Object.values(item).map((val) => (
                                <TableCell align="center">
                                  {regex.test(val) ? (
                                    <Tooltip title="View linkedin profile">
                                      <a href={val} target="_blank">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          x="0px"
                                          y="0px"
                                          width="36"
                                          height="36"
                                          viewBox="0 0 48 48"
                                        >
                                          <path
                                            fill="#0288D1"
                                            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                          ></path>
                                          <path
                                            fill="#FFF"
                                            d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                          ></path>
                                        </svg>
                                      </a>
                                    </Tooltip>
                                  ) : (
                                    <>{val}</>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        {customersSearch.state.filters.conversations &&
                          page === 1 &&
                          conversationArray.map((item, index) => (
                            <TableRow
                              key={item.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{leads.length + index + 1}</TableCell>
                              {Object.values(item).map((val) => (
                                <TableCell align="center">
                                  {regex.test(val) ? (
                                    <Tooltip title="View linkedin profile">
                                      <a href={val} target="_blank">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          x="0px"
                                          y="0px"
                                          width="36"
                                          height="36"
                                          viewBox="0 0 48 48"
                                        >
                                          <path
                                            fill="#0288D1"
                                            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                          ></path>
                                          <path
                                            fill="#FFF"
                                            d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                                          ></path>
                                        </svg>
                                      </a>
                                    </Tooltip>
                                  ) : (
                                    <>{val}</>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))} */}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}

                        {(!blockListTabs.includes(tab) && loader) || isloading ? (


                          <div style={{ paddingBlock: "20px" }}>
                            <center>
                              <CircularProgress />
                            </center>
                          </div>

                        ) : (

                          leads.length === 0 && (<div style={{ textAlign: 'center', marginTop: '40px' }}>
                            <h3> No Lead Found</h3>
                            <h4 style={{ marginTop: '0px' }}>
                              <img src={noData} width="30%" />
                            </h4>
                          </div>)
                        )}
                      </>
                    )}
                    {/* <CustomerListTable
                count={customersStore.customersCount}
                items={customersStore.customers}
                onDeselectAll={customersSelection.handleDeselectAll}
                onDeselectOne={customersSelection.handleDeselectOne}
                onPageChange={customersSearch.handlePageChange}
                onRowsPerPageChange={customersSearch.handleRowsPerPageChange}
                onSelectAll={customersSelection.handleSelectAll}
                onSelectOne={customersSelection.handleSelectOne}
                page={customersSearch.state.page}
                rowsPerPage={customersSearch.state.rowsPerPage}
                selected={customersSelection.selected}
              /> */}
                    {/* {customersSearch.state.filters.meetings === true ? (
                      ""
                    ) : ( */}
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      p={3}
                    >
                      {/* <Button
                        onClick={() => {
                          if (page > 1) {
                            setPage(page - 1);
                          }
                        }}
                        disabled={page === 1}
                        startIcon={<ChevronLeftIcon />}
                        variant="outlined"
                        sx={{
                          borderColor: theme.palette.grey[300],
                          color: "#000000",
                        }}
                      >
                        Previous
                      </Button> */}
                      {/* <Typography
                        sx={{
                          color: theme.palette.neutral[900],
                          fontWeight: "500",
                        }}
                      >
                        Page {page} of {totalPageCount}
                      </Typography> */}
                      <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Pagination
                          count={totalPageCount}
                          page={page}
                          onChange={(event, page) => setPage(page)}
                          showFirstButton
                          showLastButton
                          siblingCount={1}
                          boundaryCount={1}
                          variant="outlined"
                          shape="rounded"
                          size="large"
                          sx={{
                            mt: 2,
                            display: "flex",
                            justifyContent: "center",
                            mb: "15px",
                          }}
                        />
                      </Stack>
                      {/* <Stack direction={"row"} spacing={2}> */}

                      {/* <Button
                        onClick={() => {
                          if (page < totalPageCount) {
                            setPage(page + 1);
                          }
                        }}
                        disabled={page === totalPageCount}
                        endIcon={<ChevronRightIcon />}
                        variant="outlined"
                        sx={{
                          borderColor: theme.palette.grey[300],
                          color: "#000000",
                        }}
                      >
                        Next
                      </Button> */}
                      {/* </Stack> */}
                    </Stack>
                    {/* )} */}
                  </>
                ) : (
                  // ) : (
                  //   <h1 style={{ textAlign: "center" }}>No Campaigns Found</h1>
                  // )
                  !blockListTabs.includes(tab) && <div style={{ paddingBlock: "20px" }}>
                    <center>
                      <CircularProgress />
                    </center>
                  </div>
                )}
              </Card>
            </div>
            {/* <Stack alignItems="center">
              <Pagination
                shape="rounded"
                count={Math.ceil(count / LEADS_PER_PAGE)}
                page={page}
                onChange={(e, value) => {
                  setPage(value);
                }}
              />
            </Stack> */}
          </Stack>
        </Container>
        {timelineOpen && (
          <TimelLinePage
            open={timelineOpen}
            setTimelineOpen={setTimelineOpen}
            timelineData={timelineData}
          />
        )}
      </Box>
    </>
  );
};

export default Page;
