import React, { useEffect, useState, useMemo, useCallback } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DraftsIcon from "@mui/icons-material/Drafts";
import ReactJson from "react-json-view";
import Cookies from "js-cookie";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Divider,
  Stack,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  MenuItem,
  alpha,
  useMediaQuery,
  CircularProgress,
  LinearProgress,
  Tooltip,
  Switch,
  styled,
  Select,
  DialogActions,
  ListItem,
  ListItemText,
  List,
  Snackbar,
  Pagination,
  DialogContentText,
  Chip,
  Drawer,
  FormControl,
  InputLabel,
  useTheme,
  FormControlLabel,
  Checkbox,
  Popover,
  FormGroup,
  Tabs,
  Tab,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  SearchOutlined,
  AddOutlined,
  Close,
  DeleteOutline,
  ExpandMore,
  ExpandLess,
  InfoOutlined,
  LanguageOutlined,
  CheckCircle,
  Cancel,
  CloseOutlined,
  ReportProblemOutlined,
  BarChartOutlined,
  QueryStatsOutlined,
  Edit,
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { GmailIcon } from "src/components/logos/gmailIcon";
import { MicrosoftIcon } from "src/components/logos/microsoftIcon";
import Instructions from "./instructions";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { toast } from "react-hot-toast";
import {
  googleWebAppConfig,
  microsoftWebAppConfig,
  API_SERVICE_BACKEND,
  API_ADMIN_SERVICE_BACKEND,
} from "../../../../config";
import "../style.css";
import { getSessionStorage, setSessionStorage } from "src/utils/storage";
import ConnectNewAccount from "src/components/connectNewAccount";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { MdArrowBack } from "react-icons/md";
// import { Edit01 } from "@untitled-ui/icons-react";
// import { IoCloseCircleOutline } from "react-icons/io5";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartTooltip,
  Legend
);

export const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  devicePixelRatio: 2,
  clip: false,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 12,
        boxHeight: 12,
        borderRadius: 8,
        color: "#111927",
        font: {
          size: 12,
        },
      },
    },
    title: {
      display: true,
    },
    /* tooltip: {
      mode: "nearest",
      axis: "x",
      intersect: false,
      enabled: (item) => {
        return item?.tooltip?.title?.[0] === "undefined"
          ? false
          : true;
      },
      // filter: (tooltipItem) => {
      //   return !bufferIndices.includes(
      //     tooltipItem.dataIndex
      //   );
      // },
      callbacks: {
        title: (tooltipItem) => {
          const endDateArray =
            tooltipItem?.[0]?.label?.split("/");
          const endDate = `${endDateArray?.[1]}/${endDateArray?.[0]}/${endDateArray?.[2]}`;
          let days = 7; // Days you want to subtract
          let startDate = new Date(endDate);
          let lastWeekDate = new Date(
            startDate?.getTime() -
              days * 24 * 60 * 60 * 1000
          );
          const formattedLastWeekDate =
            moment(lastWeekDate).format("MMM DD"); 

          const formattedEndDate =
            moment(endDate).format("MMM DD");

       
        },
        label: (tooltipItem) => {
          const ref = {
            1: "low",
            2: "medium",
            3: "high",
          };

          return `score : ${
            ref[parseInt(tooltipItem.formattedValue)]
          }`;
        },
      },
    }, */
  },
  scales: {
    x: {
      //stacked: true,
    },
    y: {
      title: {
        display: true,
        text: "Emails Sent",
        color: "#111927",
        font: {
          weight: "700",
          size: 12,
        },
      },
      border: {
        display: false,
      },

    }
  }
};

const scrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: "14px",
    height: "14px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "60px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#E4E4E5",
    borderRadius: "10px",
    border: "4px solid rgba(0, 0, 0, 0)",
    backgroundClip: "padding-box",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#d5d5d5",
  },
};
const mailBoxStatusList = [
  {
    value: "CONNECTED",
    label: "Connected",
    color: "#12B981"
  }, {
    value: "WARMUP",
    label: "Warm Up",
    color: "#FF5C1F"

  },
  {
    label: "Disconnected",
    color: "#E20E0E",
    value: "DISCONNECTED"
  },
]
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#00AA38",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      backgroundColor: "#ffffff",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#33cf4d",
      backgroundColor: "#ffffff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
    backgroundColor: "#ffffff",
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const CustomButton = ({ label, error, handleClick, acc, setOpenSnackbar }) => {
  const [open, setOpen] = useState(false);
  const [domain, setDomain] = useState("godaddy");
  const [service, setService] = useState("google");
  const [subDomain, setSubDomain] = useState("");
  const [subDomainError, setSubDomainError] = useState("");
  const [checking, setChecking] = useState(false);

  const theme = useTheme();


  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isValidDomain = (subdomain) => {
    const regex = /[^a-zA-Z0-9\-]/;
    return subdomain !== "" ? !regex.test(subdomain) : false;
  };
  const handleOpen = () => [setOpen(true)];
  const handleClose = () => {
    setOpen(false);
  };
  const [expandedSpf, setExpandedSpf] = useState(true);
  const handleExpandSPF = () => {
    setExpandedSpf(!expandedSpf);
  };

  const checkStatus = () => {
    if (isValidDomain(subDomain)) {
      setSubDomainError("");
      setChecking(true);
      setTimeout(() => setChecking(false), 5000);
    } else {
      setSubDomainError("Invalid sub domain!");
    }
  };
  const CustomDomainInstructions = () => {
    return (
      <List
        sx={{
          listStyle: "decimal",
          pl: 4,
          "& .MuiListItem-root": { p: 0, fontSize: "14px" },
          "& .MuiListItemText-root .MuiTypography-root": {
            fontWeight: "400",
            fontSize: "14px",
            color: "#ffffff",
          },
        }}
      >
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Go to your domain's DNS Settings</ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Create a new CNAME record</ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Enter the following details</ListItemText>
        </ListItem>
      </List>
    );
  };
  return (
    <>
      <Button
        onClick={
          ["SPF", "DMARC", "DKIM", "Custom Tracking", "MX"].includes(label)
            ? handleOpen
            : handleClick
        }
        variant="outlined"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          borderRadius: 1,
          py: 1,
          px: 2,
          border: `1px solid ${error ? "#E20E0E" : "#00AA38"}`,
          "&:hover": {
            border: `1px solid ${error ? "#E20E0E" : "#00AA38"}`,
            backgroundColor: alpha(error ? "#E20E0E" : "#00AA38", 0.1),
          },
        }}
      >
        <Typography
          sx={{ fontWeight: "700", color: theme.palette.text.primary, fontSize: "12px" }}
        >
          {label}
        </Typography>
        {error ? (
          <Cancel sx={{ color: "#E20E0E", fontSize: "22px" }} />
        ) : (
          <CheckCircle sx={{ color: "#00AA38", fontSize: "22px" }} />
        )}
      </Button>
      <Dialog open={open} onClose={handleClose} fullScreen={isSmDown}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {label === "Custom Tracking"
              ? "Set up a custom tracking subdomain"
              : "Configure Domain Authentication"}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseOutlined sx={{ color: theme.palette.text.primary }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: { xs: "hidden", sm: "auto" },
            // flex: {
            //   xs: `${label === "Custom Tracking" ? 0 : 1} 1 auto`,
            //   sm: "1 1 auto",
            // },
          }}
        >
          {label === "Custom Tracking" ? (
            <Stack rowGap={2}>
              <Typography
                className=""
                sx={{ fontSize: "14px", fontWeight: "400", color: theme.palette.text.primary }}
              >
                Default tracking domains can put your sending reputation at
                risk, so it's best to set up a custom tracking subdomain to
                maintain deliverability.
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "400", color: theme.palette.text.primary }}
              >
                Configuring a custom subdomain will require access to your
                company's DNS.
              </Typography>
              <Stack rowGap={0.7}>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: "600", color: theme.palette.text.primary }}
                >
                  Add custom subdomain
                </Typography>
                <Box
                  sx={{

                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: 1,
                  }}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    sx={{ width: { xs: "100%", sm: "100px" }, flexGrow: 1 }}
                    value={subDomain}
                    onChange={(e) => setSubDomain(e.target.value)}
                    error={!!subDomainError}
                    placeholder={"track"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: theme.palette.text.primary,
                            }}
                          >
                            .{acc?.senderEmail.split("@")?.[1]}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    helperText={subDomainError}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      loading={checking}
                      // loadingPosition="start"
                      loadingIndicator="Checking…"
                      sx={{ borderRadius: 1, py: "6px" }}
                      onClick={checkStatus}
                    >
                      <span>Check Status</span>
                    </LoadingButton>
                    {checking && (
                      <Button
                        variant="outlined"
                        sx={{ borderRadius: 1, py: "6px" }}
                        onClick={() => {
                          setChecking(false);
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Box>
                </Box>
              </Stack>
              <Stack rowGap={1}>
                <Typography
                  sx={{
                    color: theme.palette.text.primary,
                    fontSize: "14px",
                    fontWeight: "400",
                    color: theme.palette.text.primary,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Add a new CNAME record for your tracking domain or subdomain.{" "}
                  <Tooltip title={<CustomDomainInstructions />}>
                    <InfoOutlined sx={{ color: theme.palette.text.secondary, fontSize: "18px" }} />
                  </Tooltip>
                </Typography>

                <Stack
                  rowGap={1}
                  sx={{
                    backgroundColor: alpha("#000000", 0.05),
                    borderRadius: 1,
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: theme.palette.text.primary,
                        width: "100px",
                      }}
                    >
                      Record Type:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: theme.palette.text.primary,
                      }}
                    >
                      CNAME
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: theme.palette.text.primary,
                        width: "100px",
                      }}
                    >
                      Host:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: theme.palette.text.primary,
                      }}
                    >
                      {subDomain || "track"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: theme.palette.text.primary,
                        width: "100px",
                      }}
                    >
                      Value:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: theme.palette.text.primary,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      prox.itrackly.com{" "}
                      <span
                        style={{
                          borderRadius: "8px",
                          padding: "4px 8px",
                          marginLeft: "8px",
                          backgroundColor: theme.palette.text.primary,
                          color: "#ffffff",
                          fontSize: "13px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpenSnackbar(true);
                          navigator.clipboard.writeText("prox.itrackly.com");
                        }}
                      >
                        Copy
                      </span>
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: theme.palette.text.primary,
                  mt: 1,
                }}
              >
                {label === "DKIM" && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <Accordion variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Advanced Options
                        </AccordionSummary>
                        <AccordionDetails>
                          {acc?.rapidApiData ? (
                            typeof acc?.rapidApiData !== "object" ? (
                              <Typography>{acc?.rapidApiData}</Typography>
                            ) : (
                              <ReactJson
                                src={acc?.rapidApiData?.records[label]}
                              />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                )}
                {label === "MX" && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <Accordion variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Advanced Options
                        </AccordionSummary>
                        <AccordionDetails>
                          {acc?.rapidApiData ? (
                            typeof acc?.rapidApiData !== "object" ? (
                              <Typography>{acc?.rapidApiData}</Typography>
                            ) : (
                              <ReactJson
                                src={acc?.rapidApiData?.records[label]}
                              />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                )}
                {label === "DMARC" && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <Accordion variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Advanced Options
                        </AccordionSummary>
                        <AccordionDetails>
                          {acc?.rapidApiData ? (
                            typeof acc?.rapidApiData !== "object" ? (
                              <Typography>{acc?.rapidApiData}</Typography>
                            ) : (
                              <ReactJson
                                src={{
                                  DMARC: acc?.rapidApiData?.records[label],
                                }}
                              />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {/* <div>
                      <div className="pop-authenticate-domain"
                        onClick={handleExpandSPF}>
                        <p>Advanced Option</p>
                        <IconButton
                          sx={{
                            borderRadius: 1,
                            backgroundColor: "#ECE9F5",
                            color: "#5761FE",
                            p: 0.5,
                            "&:hover": { backgroundColor: "#ECE9F5" },
                          }}
                        >
                          {expandedSpf ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        {expandedSpf && (<pre style={{ whiteSpace: 'pre-wrap', padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px', }}>
                          <p className="black-authencate">{'"' + label + '" :'}</p> <p className="red-authencate">{JSON.stringify(acc?.rapidApiData?.records[label], null, 2)}</p>
                        </pre>)}
                      </div>


                    </div> */}
                  </>
                )}
                {label === "SPF" && (
                  <>
                    <div style={{ margin: "0px" }}>
                      <Accordion variant="outlined">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Advanced Options
                        </AccordionSummary>
                        <AccordionDetails>
                          {acc?.rapidApiData ? (
                            typeof acc?.rapidApiData !== "object" ? (
                              <Typography>{acc?.rapidApiData}</Typography>
                            ) : (
                              <ReactJson
                                src={{ SPF: acc?.rapidApiData?.records[label] }}
                              />
                            )
                          ) : (
                            <CircularProgress />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {/* <div>

                      <div className="pop-authenticate-domain"
                        onClick={handleExpandSPF}>
                        <p>Advanced Option</p>
                        <IconButton
                          sx={{
                            borderRadius: 1,
                            backgroundColor: "#ECE9F5",
                            color: "#5761FE",
                            p: 0.5,
                            "&:hover": { backgroundColor: "#ECE9F5" },
                          }}
                        >
                          {expandedSpf ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        {expandedSpf && (<pre style={{ whiteSpace: 'pre-wrap', padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px', }}>
                          <p className="black-authencate">{'"' + label + '" :'}</p> <p className="red-authencate">{JSON.stringify(acc?.rapidApiData?.records[label], null, 2)}</p>
                        </pre>)}
                      </div>


                    </div> */}
                  </>
                )}
                How to configure domain attributes?{" "}
                <span
                  style={{
                    display: isSmDown ? "inline" : "none",
                    borderRadius: "8px",
                    padding: "4px",
                    border: `1px solid ${error ? "#E20E0E" : "#00AA38"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                    marginLeft: "8px",
                  }}
                >
                  {" "}
                  {label}
                </span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 1,
                  my: 1,
                }}
              >
                {" "}
                <Typography
                  sx={{
                    display: { xs: "none", sm: "block" },
                    borderRadius: 1,
                    py: 1,
                    px: 2,
                    border: `1px solid ${error ? "#E20E0E" : "#00AA38"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {label}
                </Typography>
                <Select
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  size="small"
                >
                  <MenuItem value="godaddy">GoDaddy</MenuItem>
                  <MenuItem value="namecheap">NameCheap</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                <Select
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                  size="small"
                >
                  <MenuItem value="google">Google WorkSpace</MenuItem>
                  <MenuItem value="microsoft">Microsoft 365</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </Box>
              <Instructions
                domain={domain}
                service={service}
                type={label}
                rapiddata={acc.rapidApiData}
              />
            </>
          )}
        </DialogContent>
        {label === "Custom Tracking" && (
          <DialogActions>
            <Button
              fullWidth={isSmDown}
              variant="contained"
              sx={{ alignSelft: "end", borderRadius: 1 }}
              onClick={checkStatus}
            >
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

const GooglePostMaster = () => {
  return (
    <Stack
      rowGap={2}
      sx={{
        border: "1px solid #ECE9F5",
        backgroundColor: "#ffffff",
        p: 2,
        borderRadius: 1,
      }}
    >
      <Typography
        sx={{ color: "#111927", fontWeight: "700", fontSize: "12px" }}
      >
        Google Postmaster:{" "}
        <span
          style={{
            color: "#55597A",
            fontWeight: "700",
            fontSize: "12px",
          }}
        >
          Domain Health
        </span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          flexWrap: { xs: "wrap", sm: "nowrap" },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F8F7FC",
            border: "1px solid #ECE9F5",
            p: 2,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", sm: "32%" },
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "#55597A",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Spam Rate :
          </Typography>
          <Box
            sx={{
              border: "1px solid #5761FE",
              borderRadius: 1,
              p: 1,
              px: 3,
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                1.2%
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 7 days
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: alpha("#111927", 0.1) }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                0.98%
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 30 days
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F8F7FC",
            border: "1px solid #ECE9F5",
            p: 2,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", sm: "32%" },
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "#55597A",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Domain Reputation :
          </Typography>
          <Box
            sx={{
              border: "1px solid #5761FE",
              borderRadius: 1,
              p: 1,
              px: 3,
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                High
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 7 days
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: alpha("#111927", 0.1) }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                High
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 30 days
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F8F7FC",
            border: "1px solid #ECE9F5",
            p: 2,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", sm: "32%" },
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "#55597A",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            IP Reputation :
          </Typography>
          <Box
            sx={{
              border: "1px solid #5761FE",
              borderRadius: 1,
              p: 1,
              px: 3,
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                High
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 7 days
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: alpha("#111927", 0.1) }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                }}
              >
                High
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#55597A",
                  fontSize: "10px",
                }}
              >
                Last 30 days
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
const MailItem = ({
  acc,
  index,
  selected,
  handleChange,
  expandAll,
  userType,
  setOpenSnackbar,
  fetchAllMailBoxes,
  userEmail,
}) => {
  const defaultHourlyLimit = acc?.hourlyLimit || 0;
  const upcomingSchedule = acc?.rampUp?.schedule || [];
  const checkSchedule = [6, 8, 10, 12, 15, 18, 21, 24, 27, 30];
  
  const defaultMode = acc?.rampUp?.type;
  const defaultSchedule = (defaultMode === "DEFAULT" || upcomingSchedule.length < 1) 
      ? checkSchedule 
      : upcomingSchedule;
  const defaultDailyLimit = acc?.maxAllowed || 0;
  const defaultInWeek = acc?.inWeek || 0;

  const defaultIsWeekly = acc?.rampUp?.frequency === "DAILY" ? 0 : 1
  const defaultDelay = acc?.delayEmails
    ? Math.floor(acc?.delayEmails / (1000 * 60))
    : 0 || 0;

  console.log(acc, 'accacc=>>')
  console.log("acc=====>", acc, acc?.rampUp?.schedule, defaultSchedule);
  const [expanded, setExpanded] = useState(false);
  const [hourlyLimit, setHourlyLimit] = useState(defaultHourlyLimit);
  const [dailyLimit, setDailyLimit] = useState(defaultDailyLimit);
  const [dailyLimitInWeek, setDailyLimitInWeek] = useState(defaultInWeek - 1);
  const [dailyLimitTemp, setDailyLimitTemp] = useState(defaultDailyLimit);
  const [delay, setDelay] = useState(defaultDelay);
  const [openTracking, setOpenTracking] = useState(acc?.openTracking);
  const [clickTracking, setClickTracking] = useState(acc?.clickTracking);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [forwardDomain, setForwardDomain] = useState('');

  const [schedule, setSchedule] = useState(defaultSchedule);
  const [tempSchedule, setTempSchedule] = useState(defaultSchedule); // Temporary schedule during editing

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState('');
  const [newValues, setNewValues] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [finalNewValues, setFinalNewValues] = useState([]);
  const [isWeekly, setIsWeekly] = useState(false);
  const [isWeeklyDB, setIsWeeklyDB] = useState(false);
  const [mode, setMode] = useState('default');
  const [modeDB, setModeDB] = useState('default');
  const [arrayGenerator, setArrayGenerator] = useState({
    start: '',
    increment: '',
    duration: ''
  });
  const [arrayGeneratorDB, setArrayGeneratorDB] = useState({
    start: '',
    increment: '',
    duration: ''
  });
  const [tabValue, setTabValue] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [showAddField, setShowAddField] = useState(false);
  const [addValue, setAddValue] = useState('');
  const [hoverPosition, setHoverPosition] = useState(null);
  const [addingPosition, setAddingPosition] = useState(null);
  const [newPositionValue, setNewPositionValue] = useState('');

  const handleAddAtPosition = (position) => {
    // Check if a valid number was entered
    if (newPositionValue && !isNaN(parseInt(newPositionValue))) {
      const newValue = parseInt(newPositionValue);

      // Check if this value already exists in tempSchedule
      if (!tempSchedule.includes(newValue)) {
        const newSchedule = [...tempSchedule];
        newSchedule.splice(position, 0, newValue);
        setTempSchedule(newSchedule);
      }
    }

    // Clear input and close regardless of what happened
    setNewPositionValue('');
    setAddingPosition(null);
  };
  // Add space detection between fields
  const handleSpaceHover = (index) => {
    setHoverIndex(index);
    setShowAddField(true);
  };

  const handleSpaceLeave = () => {
    // Small delay to allow clicking the add button
    setTimeout(() => {
      if (!document.activeElement?.classList.contains('add-field')) {
        setHoverIndex(null);
        setShowAddField(false);
      }
    }, 100);
  };

  const handleAddValueAtPosition = (value, position) => {
    if (value && !isNaN(parseInt(value))) {
      const newValue = parseInt(value);
      const newSchedule = [...tempSchedule];
      newSchedule.splice(position, 0, newValue);
      setTempSchedule(newSchedule.sort((a, b) => a - b));
      setAddValue('');
      setShowAddField(false);
      setHoverIndex(null);
    }
  };
  const handleScrollLeft = () => {
    if (startIndex > 0) {
      setStartIndex(prev => prev - 1);
    }
  };

  const handleScrollRight = () => {
    if (startIndex + 5 < tempSchedule.length) {
      setStartIndex(prev => prev + 1);
    }
  };
  const handleTabChange = (event, newValue) => {
    console.log(newValue, 'newValuenewValue')
    setTabValue(newValue);
    setIsWeekly(newValue === 1 ? true : false)
  };



  const generateArray = useCallback(() => {
    const start = parseInt(arrayGenerator.start);
    const increment = parseInt(arrayGenerator.increment);
    const duration = parseInt(arrayGenerator.duration);
    setDailyLimitTemp(null)
    // setDailyLimit(null)
    if (!isNaN(start) && !isNaN(increment) && !isNaN(duration)) {
      const newArray = Array.from({ length: duration }, (_, index) =>
        start + (increment * index)
      );
      setTempSchedule(newArray);
      setStartIndex(0);
      setArrayGenerator({ start: '', increment: '', duration: '' });
    }
  }, [arrayGenerator]);

  // useEffect(() => {
  //   const { start, increment, duration } = arrayGenerator;
  //   if (start && increment && duration) {
  //     generateArray();
  //   }
  // }, [arrayGenerator, generateArray]);

  const handleAddNewValue = () => {


    if (newValues.some(value => value.trim() === '')) {
      return;
    }
    setClicked(true)




    setNewValues([...newValues, '']);
  };
  const [editingIndex, setEditingIndex] = useState(null);

  const handleEditValueChange = (index, newValue) => {
    // Only allow numbers and empty string
    if (newValue === '' || /^\d*$/.test(newValue)) {
      const actualIndex = startIndex + index;

      // Check if the new value already exists (excluding current position)
      const isDuplicate = tempSchedule.some((value, idx) =>
        idx !== actualIndex && value === newValue && newValue !== ''
      );

      // Only update if it's not a duplicate
      if (!isDuplicate) {
        const newTempSchedule = [...tempSchedule];
        newTempSchedule[actualIndex] = newValue;
        setTempSchedule(newTempSchedule);
      }
    }
  };

  // Exit edit mode on blur
  const handleBlurEditBox = () => {
    setEditingIndex(null);
  };



  const handleBlurAddBox = () => {
    const validNewValues = newValues
      .map(v => parseInt(v))
      .filter(v => !isNaN(v) && !tempSchedule.includes(v)); // Exclude duplicates

    if (validNewValues.length > 0) {
      const updatedSchedule = [...tempSchedule, ...validNewValues].sort((a, b) => a - b);
      setTempSchedule(updatedSchedule);
    }

    setNewValues([]); // Clear input values
    handleClose();
    setClicked(false)
  };



  const handleNewValueChange = (index, value) => {
    const updated = [...newValues];
    updated[index] = value;
    setNewValues(updated);

  };

  const handleRemoveNewValue = (indexToRemove) => {
    setNewValues((prev) => prev.filter((_, index) => index !== indexToRemove));
    setClicked(false)
  };


  const handleClick = (event) => {
    console.log(defaultIsWeekly, defaultMode, defaultSchedule, 'default account values')
    setAnchorEl(event.currentTarget);
    setMode(defaultMode === 'CUSTOM' ? 'custom' : 'default')
    setIsWeekly(defaultIsWeekly || false)
    setTabValue(defaultIsWeekly || 0);
    setDailyLimitTemp(defaultDailyLimit)
    // setIsEditing(false)
    setTempSchedule(defaultSchedule)
  };

  const handleClosed = () => {
    setAnchorEl(null);
    setIsEditing(false);
  };
  const handleSave = () => {
    if (mode !== 'custom') {
      mailBoxUpdate('empty')
      handleClosed()
      setIsEditing(false); setAnchorEl(null)
    }
    else {
      if (dailyLimitTemp === null) {
        setIsEditing(false)
      }
      else {
        setDailyLimit(dailyLimitTemp)
        handleClosed()
        mailBoxUpdate()
      }
    }

    // const validNewValues = newValues
    //   .map(v => parseInt(v))
    //   .filter(v => !isNaN(v));

    // const updatedSchedule = [...schedule, ...validNewValues]
    //   .sort((a, b) => a - b);

    // setSchedule(updatedSchedule);
    // handleClose();
    // setNewValues([]);
  };
  const handleRemoveValue = (indexToRemove, dailyLimitTemp) => {
    const actualIndex = startIndex + indexToRemove; // Get the real index
    const updatedSchedule = tempSchedule.filter((_, index) => index !== actualIndex);

    setTempSchedule(updatedSchedule);

    // If the removed value was the selected `dailyLimitTemp`, update it to the previous one
    if (dailyLimitTemp === tempSchedule[actualIndex]) {
      const newIndex = actualIndex - 1;
      const newDailyLimit = newIndex >= 0 ? updatedSchedule[newIndex] : null;
      setDailyLimitTemp(newDailyLimit);
    }

    // If the current page becomes empty, move to the previous page (if possible)
    if (updatedSchedule.length > 0 && updatedSchedule.slice(startIndex, startIndex + 5).length === 0) {
      setStartIndex((prev) => Math.max(0, prev - 5));
    }
  };




  const handleAddValue = () => {
    if (newValue && !isNaN(newValue)) {
      const numValue = parseInt(newValue);
      setNewValue('');
    }
  };

  const openBox = Boolean(anchorEl);
  const id = openBox ? 'daily-limit-popover' : undefined;



  useEffect(() => {
    setTempSchedule(prevSchedule => {
      if (!prevSchedule.includes(defaultDailyLimit)) {
        return [...prevSchedule, defaultDailyLimit].sort((a, b) => a - b);
      }
      return prevSchedule;
    });
  }, [defaultDailyLimit]);
  const navigate = useNavigate();

  const handleExpand = () => {
    setExpanded(!expanded);
  };
  const handleOpenTracking = async (e) => {
    setOpenTracking(e.target.checked);
    try {
      let payload = {
        openTracking: e.target.checked,
      };
      setLoading(true);
      const res = await axios.put(
        `${API_SERVICE_BACKEND}/mailbox/change/status/${acc._id}`,
        payload
      );

      setLoading(false);
      fetchAllMailBoxes(userEmail);
      return res;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };
  const handleClickTracking = async (e) => {
    setClickTracking(e.target.checked);
    try {
      let payload = {
        clickTracking: e.target.checked,
      };
      setLoading(true);
      const res = await axios.put(
        `${API_SERVICE_BACKEND}/mailbox/change/status/${acc._id}`,
        payload
      );

      setLoading(false);
      fetchAllMailBoxes(userEmail);
      return res;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };
  const isValidInput = (input) => {
    return /^[0-9]*$/.test(input);
  };
  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  const isSaveVisible =
    hourlyLimit !== defaultHourlyLimit ||
    dailyLimit !== defaultDailyLimit ||
    delay !== defaultDelay;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false)
  const [resLimit, setResLimit] = useState('')

  useEffect(() => {
    setConfirmed(false);
  }, [open]);
  // useEffect(() => {
  //   if (mode === 'default') {
  //     mailBoxUpdate()
  //     // setDailyLimitTemp(defaultDailyLimit)
  //     // setDailyLimit(defaultDailyLimit)
  //     // setTempSchedule(null)
  //   }
  // }, [mode]);

  const mailBoxUpdate = async (mailboxScheduleValue) => {
    console.log(dailyLimitTemp, tempSchedule, mode, dailyLimitInWeek, isWeekly, 'tempScheduletempScheduletempScheduletempSchedule')
    let index = tempSchedule.indexOf(dailyLimitTemp);
    let updatedInWeek = index !== -1 ? index + 1 : dailyLimitInWeek + 1;
    try {
      let payload = {
        hourlyLimit: hourlyLimit || 0,
        // delayEmails: delay ? Math.floor(delay * 1000 * 60) : 0,
        maxAllowed: mailboxScheduleValue ? dailyLimit : (dailyLimitTemp || 0),
        inWeek: updatedInWeek,
        rampUp: {
          schedule: mailboxScheduleValue ? null : tempSchedule,
          frequency: isWeekly ? 'WEEKLY' : 'DAILY',
          type: mailboxScheduleValue ? null : mode === 'custom' ? 'CUSTOM' : null
        }
      };
      if (!mailboxScheduleValue) {
        setOpen(false);
        setAnchorEl(null)
      }
      setIsEditing(false)
      setIsWeekly(false)
      setTabValue(0)
      setLoading(true);
      const res = await axios.put(
        `${API_SERVICE_BACKEND}/mailbox/change/status/${acc._id}`,
        payload
      );


      setResLimit(dailyLimit)
      fetchAllMailBoxes(userEmail, true, setLoading);
      // setLoading(false);
      return res;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const theme = useTheme();
  const handleClose = () => {
    setIsDeleteOpen(false);
  };
  const mailBoxStatus = mailBoxStatusList.find((item) => item.value === acc.connectionStatus);
  const handleDelete = async (mailboxId) => {
    const id = mailboxId;
    try {
      setIsDeleting(true);
      const res = await axios.delete(`${API_SERVICE_BACKEND}/mailbox/${id}`);
      if (res.status === 200) {
        setIsDeleting(false);
        toast.success("Account deleted successfully!");
        setIsDeleteOpen(false);
        await fetchAllMailBoxes(userEmail);
      } else {
        setIsDeleting(false);
        console.error("Failed to unassign the mailbox:", res);
        toast.error("Failed to unassign the mailbox!");
        //alert('Failed to unassign the mailbox');
      }
    } catch (err) {
      setIsDeleting(false);
      console.error("Error when deleting the mailbox:", err);
      toast.error("An error occurred while deleting the mailbox!");
      //alert('An error occurred while deleting the mailbox');
    } finally {
      setLoading(false);
      setIsDeleting(false);
    }
  };
  const fetchMailboxStats = async (signal) => {
    try {
      const res = await fetch(
        `${API_SERVICE_BACKEND}/getmailboxLogs/${acc?._id}`
      );
      const userdata = await res.json();
      const sortedData = userdata?.data?.logs?.sentHistory || [];
      return sortedData
    } catch (error) {

    }

  };

  const {
    data: chartData,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["mailboxStats", acc?._id],
    queryFn: ({ signal }) => fetchMailboxStats(signal),
    enabled: isDrawerOpen,
    staleTime: Infinity,
    keepPreviousData: true,
  });
  const queryClient = useQueryClient();

  const checkSetupAndFixErrorStatus = async (type) => {
    switch (type) {
      case "SPF":
        return acc.rapidApiData?.records?.SPF?.includes("v=spf1");
      case "DKIM":
        return true;
      case "DMARC":
        return acc.rapidApiData.records.DMARC.includes("v=DMARC1");
      case "Custom Tracking":
        return true;
      case "MX":
        return acc.rapidApiData.records.MX?.length > 0;
      default:
        return false;

    }
  };

  // get forward domain 
  const getForwardDomain = async () => {
    try {
      const domain = acc.senderEmail?.split("@")?.[1]
      const response = await axios.get(`${API_SERVICE_BACKEND}/DFYInfraSetup/getForwardDomain?domain=${domain}`)
      setForwardDomain(response.data.data ?? '')
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    // Find the index of dailyLimitTemp in tempSchedule
    const selectedIndex = tempSchedule.findIndex(value => value === dailyLimitTemp);
    const totalItems = tempSchedule.length;

    if (selectedIndex !== -1) {
      let newStartIndex = 0;

      // If there are 5 or fewer items, always start from 0
      if (totalItems <= 5) {
        newStartIndex = 0;
      }
      // If selected item is in last incomplete group
      else if (selectedIndex >= totalItems - (totalItems % 5)) {
        // Show the last group of up to 5 items
        newStartIndex = Math.max(0, totalItems - 5);
      }
      // Otherwise use standard group calculation
      else {
        newStartIndex = Math.floor(selectedIndex / 5) * 5;
      }

      setStartIndex(newStartIndex);
    } else {
      // If selected value is not found, start from the beginning
      setStartIndex(0);
    }
  }, []); // Added dailyLimitTemp as dependency

  useEffect(() => {
    getForwardDomain()
    return () => {
      queryClient.invalidateQueries(["mailboxStats"])
    }
  }, [])

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 0,
    borderRadius: 34,
    '& .MuiSwitch-switchBase': {
      margin: 4,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb': {
          backgroundColor: '#fff',
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#fff',
          border: '1px solid #E0E0E0',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#fff',
      width: 26,
      height: 26,
      boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#fff',
      borderRadius: 20,
      border: '1px solid #E0E0E0',
    },
  }));



  return (
    <>
      <Card
        key={index}
        sx={{
          border: "1px solid #EAECF0",
          borderRadius: "12px",
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          overflow: "visible",
        }}
      >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 }, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              mb: 2,
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              {acc?.provider === "google" ? (
                <GmailIcon size={"20px"} />
              ) : acc?.provider === "microsoft" ? (
                <MicrosoftIcon size={"20px"} />
              ) : <DraftsIcon size={"20px"} color="primary" />}
              <Typography
                sx={{ fontWeight: "700", color: theme.palette.text.primary, fontSize: "14px" }}
              >
                {acc.senderEmail}
              </Typography>
              <Chip
                variant="outlined"
                size="small"
                label={mailBoxStatus?.label}
                sx={{
                  color: mailBoxStatus?.color,
                  fontSize: "12px",
                  fontWeight: "700",
                  borderRadius: 1,
                  borderColor: mailBoxStatus?.color,
                  p: 0.5,
                }}
              />
              {acc?.healthStatus ? (
                <Chip
                  label={`${acc?.healthStatus}%`}
                  variant="outlined"
                  sx={{ marginLeft: 2 }}
                />
              ) : <Tooltip title={"Health Status Not Available"} placement="top" arrow>
                <InfoOutlined />
              </Tooltip>}

            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "space-between", sm: "flex-end" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              {forwardDomain && <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#5761FE",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <LanguageOutlined />
                Domain:{" "}
                <span style={{ color: "#000000" }}>
                  {forwardDomain}
                </span>
              </Typography>}
              {" "}
              {/* {loading && <CircularProgress size={25} />} */}
              {/* {loading ? (
                <CircularProgress size={25} />
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    display: {
                      xs: "none",
                      sm: isSaveVisible ? "block" : "none",
                    },
                    borderRadius: 1,
                    py: "4.6px",
                  }}
                  onClick={() => setOpen(true)}
                >
                  Save
                </Button>
              )} */}
              <IconButton
                onClick={() => setIsDrawerOpen(true)}
                sx={{
                  borderRadius: 1,
                  backgroundColor: "#ECE9F5",
                  color: theme.palette.primary.main,
                  p: 0.5,
                  "&:hover": { backgroundColor: "#ECE9F5" },
                }}
              >
                <BarChartOutlined />
              </IconButton>
              <Button
                variant="outlined"
                color="error"
                disabled={acc.connectionStatus === "WARMUP"}
                onClick={() => setIsDeleteOpen(true)}
                sx={{ borderRadius: 1, py: "4.6px" }}
                size="small"
              >
                Delete
              </Button>
              {/* {acc?.healthStatus && (
                <Box
                  sx={{
                    width: "150px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                    backgroundColor: "#F8F7FC",
                    border: "1px solid #5761FE",
                    borderRadius: 1,
                    px: 1,
                    py: 0.5,
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={acc?.healthStatus ? acc?.healthStatus : 0}
                    sx={{ width: "100%" }}
                  />
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#111927",
                      fontSize: "14px",
                    }}
                  >
                    {acc?.healthStatus ? acc?.healthStatus : 0}%
                  </Typography>
                </Box>
              )} */}
              {/* <IconButton
                onClick={handleExpand}
                sx={{
                  borderRadius: 1,
                  backgroundColor: "#ECE9F5",
                  color: "#5761FE",
                  p: 0.5,
                  "&:hover": { backgroundColor: "#ECE9F5" },
                }}
              >
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton> */}
            </Box>
          </Box>
          <Divider sx={{ borderColor: alpha("#111927", 0.1) }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              p: 2,
              flexWrap: { xs: "wrap", sm: "nowrap" },
            }}
          >
            {
              anchorEl === null ?
                // <Box
                //   sx={{
                //     display: "flex",
                //     flexDirection: { xs: "row", sm: "column" },
                //     justifyContent: { xs: "space-between", sm: "center" },
                //     alignItems: "center",
                //     gap: 1,
                //     width: { xs: "100%", sm: "auto" },
                //   }}
                // >
                //   <Typography
                //     sx={{
                //       fontSize: "12px",
                //       color: theme.palette.text.secondary,
                //       fontWeight: "500",
                //       textAlign: "center",
                //     }}
                //   >
                //     Daily Sending Limits
                //   </Typography>
                //   <Typography
                //     sx={{
                //       display: "flex",
                //       justifyContent: "center",
                //       alignItems: "center",
                //       gap: 0.5,
                //     }}
                //   >

                //     <Button
                //       sx={{
                //         width: "60px",
                //         height: "44px",
                //         borderRadius: "6px",
                //         cursor: "pointer",
                //         backgroundColor: loading ? "#f0f0f0" : "#fff",
                //         border: loading ? "1px solid rgba(0, 0, 0, 0.23)" : `1px solid ${theme.palette.primary.main}`,
                //         padding: 0,
                //         minWidth: "unset",
                //         fontWeight: "700",
                //         fontSize: "14px",
                //         color: theme.palette.primary.main,
                //         textAlign: "center",
                //         userSelect: "none",
                //         "&:hover": {
                //           backgroundColor: "#fff",
                //           border: loading ? "1px solid rgba(0, 0, 0, 0.23)" : `2px solid ${theme.palette.primary.main}`,
                //         },
                //         "&.Mui-disabled": {
                //           backgroundColor: "#f0f0f0",
                //           color: "rgba(0, 0, 0, 0.26)"
                //         }
                //       }}
                //       variant="outlined"
                //       disabled={loading || acc.connectionStatus === "WARMUP"}
                //       onClick={(e) => {
                //         if (!loading) handleClick(e);
                //       }}
                //     >
                //  {dailyLimit} {loading && <CircularProgress size={12} sx={{ marginLeft: "8px" }} />}

                //     </Button>

                //     <Tooltip title="Number of emails you can send per DAY" arrow>
                //       <InfoOutlined
                //         sx={{ color: theme.palette.text.secondary, fontSize: "16px" }}
                //       />
                //     </Tooltip>
                //   </Typography>
                // </Box>

                <Card
                  sx={{
                    p: 3,
                    width: 610,
                    height: 144,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: 2,
                    borderRadius: '8px',
                    backgroundColor: 'background.paper'
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 1,
                      color: 'text.primary',
                      fontWeight: 400
                    }}
                  >
                    Daily Sending Limit
                    <Tooltip
                      title="Number of emails you can send per DAY"
                      arrow
                      placement="top">
                      <InfoOutlined
                        sx={{
                          color: 'text.secondary',
                          fontSize: "18px",
                          marginLeft: "4px"
                        }}
                      />
                    </Tooltip>
                    : {dailyLimit}
                    {loading && (
                      <CircularProgress
                        size={16}
                        sx={{ marginLeft: "8px", color: 'primary.main' }}
                      />
                    )}
                  </Typography>

                  <Button
                    variant="outlined"
                    color="primary"
                    // startIcon={} 
                    disabled={loading || acc.connectionStatus === "WARMUP"}
                    onClick={(e) => {
                      if (!loading) handleClick(e);
                    }}
                    sx={{
                      textTransform: 'none',
                      borderRadius: '10px',
                      boxShadow: 1,
                      width: 160,
                      mt: 2,
                      fontWeight: 400
                    }}
                  >
                    Update Limit <Edit sx={{ marginLeft: "6px" }} />
                  </Button>
                </Card>




                :

                <Box>
                  {/* Main Button */}


                  {/* Popover Card */}
                  {/* <Popover
                id={id}
                open={openBox}
                anchorEl={anchorEl}
                onClose={handleClosed}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              > */}
                  <Card sx={{ p: 2, width: 610 }}>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {!isEditing ? <Typography variant="h6" component="div" fontWeight="bold">
                          {mode === 'custom' ? `Rampup Schedule` : `Sending Limit`
                          }
                        </Typography> :

                          <>
                            <IconButton
                              size="small"
                              onClick={() => setIsEditing(!isEditing)}
                              sx={{
                                color: "primary.main",
                                marginLeft: 1  // Added space between text and icon
                              }}
                            >
                              <MdArrowBack />
                            </IconButton>

                            <Typography variant="h6" component="div" fontWeight="bold">
                              Edit Rampup Schedule
                            </Typography>
                          </>
                        }

                        {(mode === 'custom' && !isEditing) && (
                          <IconButton
                            size="small"
                            onClick={() => setIsEditing(!isEditing)}
                            sx={{
                              color: "primary.main",
                              marginLeft: 1  // Added space between text and icon
                            }}
                          >
                            <Edit />
                          </IconButton>
                        )}
                      </Box>

                      {!isEditing &&
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'inline-flex',
                              position: 'relative',
                              border: '1px solid',
                              borderColor: 'divider',
                              borderRadius: '20px',
                              overflow: 'hidden',
                              cursor: 'pointer',
                              width: '200px',
                              height: '36px',
                              ml: 2 // Space between Typography and Switch
                            }}
                            onClick={() => {
                              setMode(mode === 'custom' ? 'default' : 'custom');

                            }}
                          >
                            {/* Default Side */}
                            <Box
                              sx={{
                                width: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '14px',
                                fontWeight: 500,
                                backgroundColor: mode === 'default' ? 'primary.main' : 'background.paper',
                                color: mode === 'default' ? 'primary.contrastText' : 'text.primary',
                                transition: 'all 0.3s ease',
                                userSelect: 'none',
                                padding: '4px 0'
                              }}
                            >
                              Default
                            </Box>

                            {/* Custom Side */}
                            <Box
                              sx={{
                                width: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '14px',
                                fontWeight: 500,
                                backgroundColor: mode === 'custom' ? 'primary.main' : 'background.paper',
                                color: mode === 'custom' ? 'primary.contrastText' : 'text.primary',
                                transition: 'all 0.3s ease',
                                userSelect: 'none',
                                padding: '4px 0'
                              }}
                            >
                              Custom
                            </Box>
                          </Box>
                        </Box>

                      }
                    </Box>

                    <>{mode === 'custom' ?

                      <>
                        {isEditing ? (

                          <Box sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            mb: 4,
                            gap: '240px'
                          }}>

                            <>
                              <Box>
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={(event) => setAnchorEl2(event.currentTarget)}
                                  endIcon={<KeyboardArrowDown />}
                                  sx={{ minWidth: '150px' }}
                                >
                                  Generate Schedule
                                </Button>

                                <Popover
                                  open={Boolean(anchorEl2)}
                                  anchorEl={anchorEl2}
                                  onClose={() => setAnchorEl2(null)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                >
                                  <Box sx={{ p: 2, width: '280px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                          size="small"
                                          label="Start"
                                          value={arrayGenerator.start}
                                          onChange={(e) => setArrayGenerator(prev => ({
                                            ...prev,
                                            start: e.target.value
                                          }))}
                                          fullWidth
                                        />
                                        <Tooltip title="daily sending limit to begin with" placement="right">
                                          <IconButton size="small" sx={{ ml: 0.5 }}>
                                            <InfoOutlined sx={{ fontSize: 16 }} />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>

                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                          size="small"
                                          label="Increment"
                                          value={arrayGenerator.increment}
                                          onChange={(e) => setArrayGenerator(prev => ({
                                            ...prev,
                                            increment: e.target.value
                                          }))}
                                          fullWidth
                                        />
                                        <Tooltip title={`number of emails to increase ${isWeekly ? 'weekly' : 'daily'} `} placement="right">
                                          <IconButton size="small" sx={{ ml: 0.5 }}>
                                            <InfoOutlined sx={{ fontSize: 16 }} />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>

                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                          size="small"
                                          label="Duration"
                                          value={arrayGenerator.duration}
                                          onChange={(e) => setArrayGenerator(prev => ({
                                            ...prev,
                                            duration: e.target.value
                                          }))}
                                          fullWidth
                                        />
                                        <Tooltip title={`number of ${isWeekly ? 'weekly' : 'daily'} to complete the rampup`} placement="right">
                                          <IconButton size="small" sx={{ ml: 0.5 }}>
                                            <InfoOutlined sx={{ fontSize: 16 }} />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>

                                      <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                          generateArray();
                                          setAnchorEl2(null);
                                        }}
                                        sx={{ mt: 1 }}
                                      >
                                        Generate
                                      </Button>
                                    </Box>
                                  </Box>
                                </Popover>
                              </Box>
                              <ToggleButtonGroup
                                value={tabValue}
                                exclusive
                                onChange={(e, newValue) => newValue !== null && handleTabChange(null, newValue)}
                                sx={{
                                  backgroundColor: "#f8f9fa",
                                  borderRadius: "6px",
                                  p: 0.3,
                                  width: "fit-content",
                                  "& .MuiToggleButton-root": {
                                    minWidth: "70px", // Adjust width to make it smaller
                                    padding: "4px 10px",
                                    fontSize: "0.85rem", // Slightly smaller text
                                    fontWeight: 500,
                                    border: "none",
                                    borderRadius: "6px !important",
                                    textTransform: "none",
                                    color: "#555",
                                    transition: "all 0.2s ease-in-out",
                                    "&.Mui-selected": {
                                      backgroundColor: theme.palette.primary.main,
                                      color: "#fff",
                                      fontWeight: "bold",
                                    },
                                    "&:hover": {
                                      backgroundColor: "#f8f9fa",
                                      color: "#555",
                                    },
                                  },
                                }}
                              >
                                <ToggleButton value={0}>Daily</ToggleButton>
                                <ToggleButton value={1}>Weekly</ToggleButton>
                              </ToggleButtonGroup>


                            </>

                          </Box>) : null}


                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'center', position: 'relative', marginLeft: isEditing ? '0%' : '0%' }}>
                          <IconButton
                            onClick={() => {
                              const newIndex = Math.max(0, startIndex - 5);
                              setStartIndex(newIndex);
                            }}
                            disabled={startIndex === 0}
                            sx={{
                              '&.Mui-disabled': {
                                opacity: 0.5
                              },
                              mb: '25px'
                            }}
                          >
                            <ChevronLeft />
                          </IconButton>{console.log(tempSchedule, 'tempSchedule')}

                          <Box sx={{ display: 'flex', gap: isEditing ? '5px' : '11px', position: 'relative' }}>
                            {/* Add this code right after the first Box opening tag */}
                            {isEditing && startIndex >= 0 && (
                              <Box sx={{ display: 'flex', alignItems: 'center', height: '44px', mr: 1 }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    color: theme.palette.primary.main,
                                    fontWeight: 'bold',
                                    opacity: hoverPosition === -1 ? 1 : 0.3,
                                    '&:hover': {
                                      opacity: 1
                                    }
                                  }}
                                  onMouseEnter={() => setHoverPosition(-1)}
                                  onMouseLeave={() => setHoverPosition(null)}
                                  onClick={() => setAddingPosition(-1)}
                                >
                                  +
                                </Box>

                                {addingPosition === -1 && (
                                  <Box sx={{ position: 'relative', textAlign: 'center', ml: 1, top: '12px' }}>
                                    <TextField
                                      sx={{
                                        width: "60px",
                                        marginBottom: "5px",
                                        "& .MuiOutlinedInput-root": {
                                          borderRadius: "6px",
                                          cursor: "text",
                                        },
                                        "& .MuiOutlinedInput-input": {
                                          p: 0,
                                          px: 1,
                                          height: "44px",
                                          borderRadius: "inherit",
                                          cursor: "text",
                                        },
                                        "& input": {
                                          fontWeight: "700",
                                          color: theme.palette.primary.main,
                                          fontSize: "14px",
                                          textAlign: 'center',
                                          userSelect: "text",
                                        },
                                      }}
                                      value={newPositionValue}
                                      onChange={(e) => setNewPositionValue(e.target.value)}
                                      onBlur={() => handleAddAtPosition(startIndex)}
                                      autoFocus
                                      variant="outlined"
                                    />
                                    <Box sx={{ fontSize: "12px", color: theme.palette.text.secondary, top: '10px' }}>
                                      {`${isWeekly ? "Week" : "Day"} ${startIndex + 1}`}
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            )}
                            {tempSchedule.slice(startIndex, startIndex + 5).map((value, index) => (
                              <React.Fragment key={index}>
                                <Box sx={{ position: 'relative', textAlign: 'center' }}>
                                  <Tooltip title={`${value} emails/day`} placement="top" arrow>
                                    <TextField
                                      sx={{
                                        width: "60px",
                                        "& .MuiOutlinedInput-root": {
                                          borderRadius: "6px",
                                          cursor: isEditing && editingIndex === index ? "text" : "pointer",
                                        },
                                        "& .MuiOutlinedInput-input": {
                                          p: 0,
                                          px: 1,
                                          height: "44px",
                                          border: value === dailyLimitTemp ? `2px solid ${theme.palette.primary.main}` : "none",
                                          backgroundColor: value === dailyLimitTemp ? theme.palette.primary.main : "none",
                                          color: value === dailyLimitTemp ? "#fff" : theme.palette.primary.main,
                                          borderRadius: "inherit",
                                          cursor: isEditing && editingIndex === index ? "text" : "pointer",
                                          caretColor: isEditing && editingIndex === index ? "auto" : "transparent",
                                        },
                                        "& input": {
                                          fontWeight: "700",
                                          color: theme.palette.primary.main,
                                          fontSize: "14px",
                                          textAlign: 'center',
                                          userSelect: isEditing && editingIndex === index ? "text" : "none",
                                        },
                                      }}
                                      value={value}
                                      onClick={() => {
                                        if (isEditing) {
                                          setEditingIndex(index);
                                        } else {
                                          setDailyLimitTemp(value);
                                          setDailyLimitInWeek(startIndex + index);
                                          handleClose();
                                        }
                                      }}
                                      onChange={(e) => {
                                        if (isEditing && editingIndex === index) {
                                          handleEditValueChange(index, e.target.value);
                                        }
                                      }}
                                      onBlur={handleBlurEditBox}
                                      variant="outlined"
                                      inputProps={{
                                        readOnly: !(isEditing && editingIndex === index),
                                      }}
                                    />
                                  </Tooltip>

                                  {isEditing && (
                                    <IconButton
                                      size="small"
                                      sx={{
                                        position: 'absolute',
                                        top: -8,
                                        right: -8,
                                        bgcolor: 'background.paper',
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        backgroundColor: '#fff',
                                        p: '2px',
                                        width: '20px',
                                        height: '20px',
                                      }}
                                      onClick={() => handleRemoveValue(index, dailyLimitTemp)}
                                    >
                                      <Close sx={{ fontSize: 14 }} />
                                    </IconButton>
                                  )}

                                  <Box sx={{ fontSize: "12px", color: theme.palette.text.secondary, mt: 0.5 }}>
                                    {`${isWeekly ? "Week" : "Day"} ${startIndex + index + 1}`}
                                  </Box>
                                </Box>

                                {/* Add "+" between fields when editing */}
                                {isEditing && index < tempSchedule.slice(startIndex, startIndex + 5).length - 1 && (
                                  <Box sx={{ display: 'flex', alignItems: 'center', height: '44px' }}>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        color: theme.palette.primary.main,
                                        fontWeight: 'bold',
                                        opacity: hoverPosition === index ? 1 : 0.3,
                                        '&:hover': {
                                          opacity: 1
                                        }
                                      }}
                                      onMouseEnter={() => setHoverPosition(index)}
                                      onMouseLeave={() => setHoverPosition(null)}
                                      onClick={() => setAddingPosition(index)}
                                    >
                                      +
                                    </Box>

                                    {addingPosition === index && (
                                      <Box sx={{ position: 'relative', textAlign: 'center', ml: 1, top: '12px' }}>
                                        <TextField
                                          sx={{
                                            width: "60px",
                                            marginBottom: "5px",
                                            "& .MuiOutlinedInput-root": {
                                              borderRadius: "6px",
                                              cursor: "text",
                                            },
                                            "& .MuiOutlinedInput-input": {
                                              p: 0,
                                              px: 1,
                                              height: "44px",
                                              borderRadius: "inherit",
                                              cursor: "text",
                                            },
                                            "& input": {
                                              fontWeight: "700",
                                              color: theme.palette.primary.main,
                                              fontSize: "14px",
                                              textAlign: 'center',
                                              userSelect: "text",
                                            },
                                          }}
                                          value={newPositionValue}
                                          onChange={(e) => setNewPositionValue(e.target.value)}
                                          onBlur={() => handleAddAtPosition(startIndex + index + 1)}
                                          autoFocus
                                          variant="outlined"
                                        />
                                        <Box sx={{ fontSize: "12px", color: theme.palette.text.secondary, top: '10px' }}>
                                          {`${isWeekly ? "Week" : "Day"} ${startIndex + index + 2}`}
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                )}
                              </React.Fragment>
                            ))}

                            {isEditing && (
                              <>
                                {newValues.map((value, index) => (
                                  <Box key={`new-${index}`} sx={{ position: 'relative', textAlign: 'center' }}>
                                    <TextField
                                      sx={{
                                        width: "60px",
                                        marginLeft: '10px',
                                        "& .MuiOutlinedInput-root": {
                                          borderRadius: "6px",
                                          height: "44px"
                                        },
                                        "& .MuiOutlinedInput-input": {
                                          textAlign: 'center'
                                        }
                                      }}
                                      value={value}
                                      onBlur={handleBlurAddBox}
                                      onChange={(e) => handleNewValueChange(index, e.target.value)}
                                      variant="outlined"
                                      size="small"
                                    />
                                    <IconButton
                                      size="small"
                                      sx={{
                                        position: 'absolute',
                                        top: -8,
                                        right: -8,
                                        bgcolor: 'background.paper',
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        p: '2px',
                                        width: '20px',
                                        height: '20px',
                                      }}
                                      onClick={() => handleRemoveNewValue(index)}
                                    >
                                      <Close sx={{ fontSize: 14 }} />
                                    </IconButton>
                                    <Box sx={{ fontSize: "12px", color: theme.palette.text.secondary, mt: 0.5 }}>
                                      {`${isWeekly ? "Week" : "Day"} ${tempSchedule.length + 1}`}
                                    </Box>
                                  </Box>
                                ))}
                              </>
                            )}
                          </Box>

                          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            {(isEditing && !clicked && addingPosition === null) && (
                              <IconButton
                                sx={{
                                  width: "60px",
                                  height: "50px",
                                  border: "1px dashed",
                                  borderColor: "divider",
                                  borderRadius: "6px",
                                  bgcolor: "white",
                                  marginLeft: '12px',
                                  mb: 3,
                                }}
                                onClick={handleAddNewValue}
                              >
                                +
                              </IconButton>
                            )}

                            <IconButton
                              onClick={() => {
                                const newIndex = Math.min(tempSchedule.length - 5, startIndex + 5);
                                setStartIndex(newIndex);
                              }}
                              disabled={startIndex + 5 >= tempSchedule.length}
                              sx={{
                                '&.Mui-disabled': {
                                  opacity: 0.5
                                },
                                mb: '25px'
                              }}
                            >
                              <ChevronRight />
                            </IconButton>
                          </Box>
                        </Box>



                        {(
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                            <Button
                              variant="outlined"
                              // startIcon={<SaveIcon />}
                              onClick={() => { setIsEditing(false); setAnchorEl(null) }}
                              // disabled={dailyLimitTemp === null}
                              size="small"
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              // startIcon={<SaveIcon />}
                              onClick={handleSave}
                              disabled={!isEditing && dailyLimitTemp === null}
                              size="small"
                            >
                              Save
                            </Button>
                          </Box>
                        )}</> : <>
                        <Typography >
                          Switch to custom for additional settings
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                          <Button
                            variant="outlined"
                            // startIcon={<SaveIcon />}
                            onClick={() => setAnchorEl(null)}
                            // disabled={dailyLimitTemp === null}
                            size="small"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            // startIcon={<SaveIcon />}
                            onClick={handleSave}
                            disabled={!isEditing && dailyLimitTemp === null}
                            size="small"
                          >
                            Save
                          </Button>
                        </Box>
                      </>}</>

                  </Card>
                  {/* </Popover> */}
                </Box>}
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />

            {/* <Box className="w-full max-w-2xl p-6">
      <Box className="flex flex-wrap gap-3 justify-center mb-6">
        {schedule.map((value, index) => (
          <button
            key={index}
            onClick={() => setSelectedIndex(index)}
            className={`
              w-12 h-12 
              rounded-full 
              flex items-center justify-center 
              text-sm font-medium 
              transition-all 
              duration-200 
              ${
                index === selectedIndex
                  ? 'bg-blue-500 text-white shadow-lg scale-110'
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
              }
            `}
          >
            {value}
          </button>
        ))}
      </Box>

      <Box className="w-full px-4">
        <input
          type="range"
          min={0}
          max={schedule.length - 1}
          value={selectedIndex}
          onChange={(e) => setSelectedIndex(parseInt(e.target.value))}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
          style={{
            backgroundImage: `linear-gradient(to right, #3b82f6 ${(selectedIndex / (schedule.length - 1)) * 100}%, #e5e7eb ${(selectedIndex / (schedule.length - 1)) * 100}%)`
          }}
        />
      </Box>

      <Typography className="text-center mt-4 text-gray-600">
        Selected: {schedule[selectedIndex]} weeks
      </Typography>
    </Box> */}
            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color:theme.palette.text.secondary,
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Hourly Sending Limits
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
               
                <TextField
                  sx={{
                    width: "40px",
                    border: "none",
                    "& .MuiOutlinedInput-input": {
                      p: 0,
                      px: 1,
                      border: "none",
                    },
                    "& input": {
                      fontWeight: "700",
                      color: theme.palette.text.primary,
                      fontSize: "14px",
                    },
                  }}
                  disabled={acc.connectionStatus === "WARMUP"}
                  InputProps={{
                    // readOnly: userType === "enterprise" ? false : true,
                    readOnly: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="This is a tooltip!" arrow>
                          <InfoOutlined
                            sx={{
                              color: alpha("#55597A", 0.5),
                              fontSize: "16px",
                            }}
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={hourlyLimit}
                  onChange={(e) => {
                    if (isValidInput(e.target.value)) {
                      const value =
                        e.target.value === ""
                          ? e.target.value
                          : parseInt(e.target.value);
                      setHourlyLimit(value);
                    }
                  }}
                />
                <Tooltip title="Number of emails you can send per HOUR" arrow>
                  <InfoOutlined
                    sx={{ color: theme.palette.text.secondary, fontSize: "16px" }}
                  />
                </Tooltip>
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.palette.text.secondary,
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Delay Between Emails
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#111927",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <span
                  style={{
                    fontWeight: "700",
                    color: theme.palette.text.primary,
                    fontSize: "14px",
                  }}
                >
                  <TextField
                    sx={{
                      width: "40px",
                      border: "none",
                      "& .MuiOutlinedInput-input": {
                        p: 0,
                        px: 1,
                        border: "none",
                      },
                      "& input": {
                        fontWeight: "700",
                        color: theme.palette.text.primary,
                        fontSize: "14px",
                      },
                    }}
                    InputProps={{
                      // readOnly: userType === "enterprise" ? false : true,
                      readOnly: true,
                    }}
                    disabled={acc.connectionStatus === "WARMUP"}
                    variant="outlined"
                    value={delay}
                    onChange={(e) => {
                      if (isValidInput(e.target.value)) {
                        const value =
                          e.target.value === ""
                            ? e.target.value
                            : parseInt(e.target.value);
                        setDelay(value);
                      }
                    }}
                  />{" "}
                  min
                </span>

                <Tooltip title="Random delay between the emails you send" arrow>
                  <InfoOutlined
                    sx={{ color: theme.palette.text.secondary, fontSize: "16px" }}
                  />
                </Tooltip>
              </Typography>
            </Box> */}
            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            /> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.palette.text.secondary,
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Click to Setup or Fix Errors
              </Typography>
              {/* <Stack
                rowGap={2}
                sx={{
                  border: "1px solid #ECE9F5",
                  display: "flex",
                  backgroundColor: "#ffffff",
                  p: 2,
                  borderRadius: 1,
                }}
              > */}

              <Stack
                rowGap={2}
                sx={{

                  border: "1px solid #ECE9F5",
                  backgroundColor: "#ffffff",
                  p: 2,
                  borderRadius: 1,
                }}
              >
                {/* <Typography
                  sx={{ fontWeight: "700", color: "#111927", fontSize: "12px" }}
                >
                  Click to Setup or Fix Errors:
                </Typography> */}
                <Box
                  sx={{

                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <CustomButton
                    label={"SPF"}
                    error={checkSetupAndFixErrorStatus("SPF") ? false : true}

                    disabled={acc.connectionStatus === "WARMUP"}
                    handleClick={() => { }}
                    acc={acc}
                  />
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      borderColor: alpha("#111927", 0.1),
                      display: { xs: "none", sm: "block" },
                    }}
                  />
                  {/* <CustomButton
                  label={"DKIM"}
                  error={
                    acc?.rapidApiData?.records?.DKIM?.length > 0 ? false : true
                  }
                  // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.DKIM === "Found") ? false : true}
                  handleClick={() => { }}
                  acc={acc}
                /> */}
                  <CustomButton
                    label={"DMARC"}
                    disabled={acc.connectionStatus === "WARMUP"}
                    error={checkSetupAndFixErrorStatus("DMARC") ? false : true}
                    // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.DMARC === "Found") ? false : true}
                    handleClick={() => { }}
                    acc={acc}
                  />
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      borderColor: alpha("#111927", 0.1),
                      display: { xs: "none", sm: "block" },
                    }}
                  />
                  <CustomButton
                    label={"MX"}
                    disabled={acc.connectionStatus === "WARMUP"}
                    error={checkSetupAndFixErrorStatus("MX") ? false : true}
                    handleClick={() => { }}
                    acc={acc}
                  />
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      borderColor: alpha("#111927", 0.1),
                      display: { xs: "none", sm: "block" },
                    }}
                  />
                  {/* <CustomButton
                  label={"Redirection"}
                  error={true}
                  handleClick={() => {}}
                /> */}
                  <CustomButton
                    label={"Custom Tracking"}
                    disabled={acc.connectionStatus === "WARMUP"}
                    error={checkSetupAndFixErrorStatus("Custom Tracking") ? false : true}
                    handleClick={() => { }}
                    acc={acc}
                    setOpenSnackbar={setOpenSnackbar}
                  />
                </Box>
              </Stack>
              {/* </Stack> */}
            </Box>



            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#55597A",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Delivery Score
              </Typography>
              <Box
                sx={{
                  borderRadius: 4,
                  px: 1,
                  backgroundColor: "#00AA38",
                  display: "inline",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "12px",
                    color: "#ffffff",
                  }}
                >
                  {acc?.performance?.sending?.value
                    ? acc?.performance?.sending?.value +
                      acc?.performance?.sending?.unit
                    : 0 + "%"}
                  {/* 99.5% */}
            {/* </Typography>
              </Box>
            </Box> */}
            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            /> */}
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#55597A",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Open Tracking
              </Typography>
              <IOSSwitch onChange={handleOpenTracking} checked={openTracking} disabled={acc.connectionStatus === "WARMUP"} />
            </Box> */}
            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: alpha("#111927", 0.1),
                display: { xs: "none", sm: "block" },
              }}
            /> */}
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                justifyContent: { xs: "space-between", sm: "center" },
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#55597A",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Click Tracking
              </Typography>
              <IOSSwitch
                onChange={handleClickTracking}
                checked={clickTracking}
                disabled={acc.connectionStatus === "WARMUP"}
              />
            </Box> */}
          </Box>
          {/* {expanded && (
            <Stack
              sx={{
                borderRadius: 2,
                p: 2,
                backgroundColor: "#F8F7FC",
                border: "1px solid #ECE9F5",
              }}
              rowGap={2}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#5761FE",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <LanguageOutlined />
                Domain:{" "}
                <span style={{ color: "#000000" }}>
                  {acc.senderEmail.split("@")?.[1]}
                </span>
              </Typography>
              <Stack
                rowGap={2}
                sx={{
                  border: "1px solid #ECE9F5",
                  backgroundColor: "#ffffff",
                  p: 2,
                  borderRadius: 1,
                }}
              >
                <Typography
                  sx={{ fontWeight: "700", color: "#111927", fontSize: "12px" }}
                >
                  Click to Setup or Fix Errors:
                </Typography>
                <Divider sx={{ borderColor: alpha("#111927", 0.1) }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <CustomButton
                    label={"SPF"}
                    error={
                      acc?.rapidApiData?.records?.SPF?.length > 0 ? false : true
                    }
                    
                    disabled={acc.connectionStatus === "WARMUP"}
                    handleClick={() => {}}
                    acc={acc}
                  />
                  <CustomButton
                  label={"DKIM"}
                  error={
                    acc?.rapidApiData?.records?.DKIM?.length > 0 ? false : true
                  }
                  // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.DKIM === "Found") ? false : true}
                  handleClick={() => { }}
                  acc={acc}
                />
                  <CustomButton
                    label={"DMARC"}
                    disabled={acc.connectionStatus === "WARMUP"}
                    error={
                      acc?.rapidApiData?.records?.DMARC?.length > 0
                        ? false
                        : true
                    }
                    // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.DMARC === "Found") ? false : true}
                    handleClick={() => {}}
                    acc={acc}
                  />
                  <CustomButton
                    label={"MX"}
                    disabled={acc.connectionStatus === "WARMUP"}
                    error={
                      acc?.rapidApiData?.records?.MX?.length > 0 ? false : true
                    }
                    // (acc?.rapidApiData?.summary || [])?.find((ele) => ele.MX === "Found") ? false : true}
                    handleClick={() => {}}
                    acc={acc}
                  />
                  <CustomButton
                  label={"Redirection"}
                  error={true}
                  handleClick={() => {}}
                />
                  <CustomButton
                    label={"Custom Tracking"}
                    disabled={acc.connectionStatus === "WARMUP"}
                    error={false}
                    handleClick={() => {}}
                    acc={acc}
                    setOpenSnackbar={setOpenSnackbar}
                  />
                </Box>
              </Stack>
              <GooglePostMaster />
            </Stack>
          )} */}
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6" component="div" fontWeight="bold">
            Heads up!
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" gutterBottom>
              Changing your limits can lead to blocks or deliverability issues.
            </Typography>
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={confirmed}
                onChange={(e) => setConfirmed(e.target.checked)}
                color="primary"
              />
            }
            label="By saving these changes, you confirm you understand the risks and won't hold B2B Rocket responsible for any negative outcomes."
            sx={{ marginTop: 1 }}
          />
        </DialogContent>

        <DialogActions sx={{ padding: 2 }}>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            sx={{ mr: 1 }}
          >
            Go Back
          </Button>
          <Button
            onClick={mailBoxUpdate}
            variant="contained"
            color="primary"
            disabled={!confirmed}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDeleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              color: "red",
            }}
          >
            <ReportProblemOutlined sx={{ mr: 1 }} />
            Delete Sending Account
          </Typography>
          <IconButton onClick={handleClose} sx={{ pr: 0, color: "#111927" }}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: "18px", color: theme.palette.text.primary }}
          >
            Are you sure you want to permanently delete the account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: 1 }}
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: 1,
              backgroundColor: "red",
              "&:hover": { backgroundColor: "#e30000" },
            }}
            onClick={() => handleDelete(acc._id)}
            autoFocus
            disabled={isDeleting}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer
        anchor={"right"}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          zIndex: 1300,
        }}
        PaperProps={{
          sx: { minWidth: 350 },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            px: 2,
            backgroundColor: "white",
            borderRadius: "12px",
          }}
        >
          {isFetching ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                height: "100%",
                width: "100%"
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {chartData?.length > 0 ? (
                <Stack spacing={1} sx={{ p: 2 }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>Mailbox Stats</Typography>
                  <Divider sx={{ borderColor: "rgba(233, 233, 236, 1)" }} />
                  <Box
                    sx={{
                      p: 2,
                      maxWidth: "70vw",
                      maxHeight: "90vh",
                      minWidth: "60vw",
                      minHeight: "60vh"
                    }}
                  >
                    <Bar
                      options={chartOptions}
                      data={{
                        labels: chartData?.map((data) => moment(data?.calculatedAt).format('MMM DD')),
                        datasets: [
                          {
                            label: "Max Allowed",
                            data: chartData?.map((data) => data?.maxAllowed),
                            backgroundColor: "rgba(233, 233, 236, 1)",
                          },
                          {
                            label: "Emails Sent",
                            data: chartData?.map((data) => data?.emailsSent),
                            backgroundColor: "#5761FE",
                          },
                        ]
                      }}
                    />
                  </Box>
                </Stack>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <QueryStatsOutlined sx={{ fontSize: "42px" }} />
                  <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>No stats found</Typography>
                </Box>
              )}
            </>

          )}
        </Box>
      </Drawer>
    </>
  );
};

const EmailAccounts = ({ sectionRef, id, userEmail, userType }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isSMTP, setIsSMTP] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const handleExpandAll = () => {
    setExpandAll(!expandAll);
  };
  const [mailAccounts, setMailAccounts] = useState([]);
  const [totalMailboxes, setTotalMailboxes] = useState(0);
  const connectedAccounts =
    mailAccounts?.filter((acc) => acc?.assigned?.campaignId !== null).length ||
    0;
  const [selected, setSelected] = useState([]);
  const [dialogType, setDialogType] = useState("");
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [mailBoxStatus, setMailBoxStatus] = useState("all");
  const [showInstructions, setShowInstructions] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const getSearchParams = (search) => new URLSearchParams(search);
  const searchParams = useMemo(
    () => getSearchParams(location?.search),
    [location?.search]
  );
  const [code, setCode] = useState(searchParams?.get("code"));
  // console.log("code=======>", code);
  const EmailProviders = Object.freeze({
    GOOGLE: "google",
    MICROSOFT: "microsoft",
  });
  const handleConnectGoogleAccount = async () => {
    // Accounts Page
    setSessionStorage("emailProviderType", EmailProviders.GOOGLE);
    const expirationTimeCamp = 2 * 60 * 1000;
    Cookies.set("campaignId", null, { expires: expirationTimeCamp });
    const expirationTime = 1 * 60 * 1000;
    Cookies.set("emailProviderType", EmailProviders.GOOGLE, {
      expires: expirationTime,
    });
    const stateData = JSON.stringify({
      userEmail,
    });
    const encodedState = encodeURIComponent(stateData);
    const params = new URLSearchParams({
      redirect_uri: googleWebAppConfig.redirect_uri,
      client_id: googleWebAppConfig.client_id,
      access_type: "offline",
      response_type: "code",
      prompt: "consent",
      scope: googleWebAppConfig.scopes,
      state: encodedState,
    });
    const url = `${googleWebAppConfig.oauth_url}?${params.toString()}`;
    window.open(url, "_self");
  };
  const handleConnectMicrosoftAccount = async () => {
    const expirationTimeCamp = 2 * 60 * 1000;
    Cookies.set("campaignId", null, { expires: expirationTimeCamp });
    setSessionStorage("emailProviderType", EmailProviders.MICROSOFT);
    const expirationTime = 5 * 60 * 1000;
    Cookies.set("emailProviderType", EmailProviders.MICROSOFT, {
      expires: expirationTime,
    });
    const params = new URLSearchParams({
      redirect_uri: microsoftWebAppConfig.redirect_uri,
      client_id: microsoftWebAppConfig.client_id_azure,
      access_type: "offline",
      response_type: "code",
      prompt: "consent",
      scope: microsoftWebAppConfig.scopes,
    });
    const url = `${microsoftWebAppConfig.oauth_url}?${params?.toString()}`;
    window.open(url, "_self");
  };

  const handleClose = () => {
    setOpen(false);
    setDialogType("");
  };

  const handleConnectEmailProvider = async (payload = {}) => {
    try {
      const res = await axios.post(`${API_SERVICE_BACKEND}/mailboxes/connectSMTP`, {
        ...payload
      });
      await fetchAllMailBoxes(userEmail);
      handleClose();
      toast.success(res?.data?.message, {
        autoClose: 3000,
      });
    } catch (error) {
      toast.error(error?.response?.data?.error ?? "Failed To Connect", {
        autoClose: 3000,
      });
    }
  }

  const [tostrDisplayed, setToastrDisplayed] = useState(false);
  const exchangeCodeForAccessToken = async (code) => {
    const accessTokenUrlsMap = {
      [EmailProviders.GOOGLE]: "/getAccessTokenEnterprice",
      [EmailProviders.MICROSOFT]: "/exchangeCodeForAccessTokenMicrosoft2",
    };
    if (!tostrDisplayed) {
      const emailProviderType = getSessionStorage("emailProviderType");
      console.log(
        "emailProviderType====>",
        accessTokenUrlsMap[emailProviderType]
      );
      const res = await axios.post(
        `${API_SERVICE_BACKEND}/mailbox${accessTokenUrlsMap[emailProviderType]}`,
        {
          code,
          createdBy: userEmail,
        }
      );
      // console.log("res======>", res);
      return res.data;
    }
  };

  // mailbox connection status filter handle
  const handleMailBoxFilterChange = (event) => {
    setMailBoxStatus(event.target.value);
  };
  useEffect(() => {
    const codeFromUrl = searchParams.get("code");
    // console.log("codeFromUrl========>", codeFromUrl);
    if (code && !tostrDisplayed) {
      setToastrDisplayed(true);
      setCode(code);
      setLoading(true);
      exchangeCodeForAccessToken(code)
        .then((res) => {
          let toastrCount = false;
          if (!toastrCount) {
            if (typeof res.resource == "number" && res.resource >= 1) {
              toast.error("Mail box already exist");
              toastrCount = true;
            } else {
              toast.success("Mail box added successfully");
              toastrCount = true;
              // console.log(res.resource.error, "=====error");
            }
            // setTimeout(() => {
            navigate("/dashboard/account", { replace: true });
            // }, 1000);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [
    tostrDisplayed,
    /*code*/
  ]);

  const handleClickOpen = (type) => {
    setDialogType(type);
    setOpen(true);
  };

  const handleChange = (e, acc) => {
    if (e.target.checked) {
      setSelected([...selected, acc]);
    } else {
      setSelected(selected.filter((e) => e !== acc));
    }
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const baseUrl = API_SERVICE_BACKEND;
  // const email = localStorage.getItem("userEmail");

  // const getUserInfo = async () => {
  //   try {
  //     const res = await axios.get(
  //       `https://campaign-app-server-azure-pipeline.azurewebsites.net/api/v1/main/getuserdetail/${email}`
  //     );
  //     const userData = res.data.data;
  //     setShowInstructions(userData?.userInstructions);
  //   } catch (error) {
  //     throw error; // You can choose to re-throw the error or handle it here
  //   }
  // };

  const fetchAllMailBoxes = async (account, limitLoad, setLoadingOfSave) => {
    try {
      !limitLoad && setLoading(true);
      // const res = await fetch(`${API_SERVICE_BACKEND}/getuserAnalytics?page=${page}&limit=${rowsPerPage}&search=${encodeURIComponent(searchText)}`);
      const url = mailBoxStatus !== 'all' ? `?connectionStatus=${mailBoxStatus}` : '';
      // // const res = await fetch(`${baseUrl}/usermailbox/${account}?connectionStatus=${mailBoxStatus}`);
      const res = await fetch(`${baseUrl}/usermailbox/${account}${url}`);
      const userdata = await res.json();
      const payload = { accountsEmails: userdata?.data?.map(item => item?.senderEmail) || [] }
      if (payload.accountsEmails.length > 0) {
        const UserHealthData = await axios.post(`${API_ADMIN_SERVICE_BACKEND}/allUserForMailboxes`, payload);
        setTotalMailboxes(userdata?.totalCount ?? 0);
        const result = userdata?.data?.map(itemA => {
          const match = UserHealthData?.data?.mailBoxHealthData?.find(itemB => itemB?.email === itemA?.senderEmail);
          return {
            ...itemA,
            health_score: match ? match.health_score : null,
          };
        });
        setMailAccounts(result);
      } else {
        setMailAccounts(userdata.data ?? []);
        setTotalMailboxes(0)
      }
      !limitLoad && setLoading(false);
      limitLoad && setLoadingOfSave(false)
    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  };
  useEffect(() => {
    // getUserInfo(userEmail)
    fetchAllMailBoxes(userEmail);
  }, [mailBoxStatus]);
  // console.log("mail accounts", mailAccounts);

  const handlePageChange = (e, value) => {
    setPage(value);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const filteredAccounts = mailAccounts.filter((acc) =>
    acc.senderEmail.includes(search)
  );
  const start = (page - 1) * limit;
  const end = limit * page;
  const paginatedAccounts = filteredAccounts.slice(start, end);

  // const handleDelete = async (mailboxId) => {
  //   const id = mailboxId
  //   try {
  //     const res = await axios.delete(
  //       `${API_SERVICE_BACKEND}/mailbox/${id}`,
  //     );
  //     if (res.status === 200) {
  //       await fetchAllMailBoxes(userEmail);

  //     } else {
  //       console.error('Failed to unassign the mailbox:', res);
  //       alert('Failed to unassign the mailbox');
  //     }
  //   } catch (err) {
  //     console.error('Error when deleting the mailbox:', err);
  //     alert('An error occurred while deleting the mailbox');
  //   } finally {
  //     setLoading(false);
  //   }

  // };

  const pageCount = Math.ceil(filteredAccounts.length / limit);
  useEffect(() => {
    const saveBtn = document.getElementById("save-settings-btn");
    if (saveBtn) {
      saveBtn.style.display = "none";
    }
    return () => {
      if (saveBtn) {
        saveBtn.style.display = "flex";
      }
    };
  }, []);
  return (
    <>
      <Box sx={{ paddingX: "30px" }}>
        <div
          ref={sectionRef}
          id={id}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "32px",
                  color: theme.palette.text.primary,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <span style={{ fontWeight: "700" }}>Email Accounts</span>
                {/* {!isSmDown && (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{ borderColor: alpha("#111927", 0.1) }}
                    />
                    <span style={{ fontWeight: "500", fontSize: "20px" }}>
                      Linked Accounts
                    </span>
                  </>
                )} */}
              </Typography>

              <Chip
                sx={{
                  backgroundColor: "#00AA38",
                }}
                label={
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "700",
                      fontSize: "14px",
                      // color: "#ffffff",
                      lineHeight: 1,
                      color: "#ffffff",
                    }}
                  >
                    {/* {mailBoxStatus== "connected" ? connectedAccounts : totalMailboxes - connectedAccounts} / */}
                    <span style={{ fontWeight: "500", opacity: 0.6 }}>
                      {totalMailboxes || 0}
                    </span>
                  </Typography>
                }
              />
              {/* <Box
                sx={{
                  borderRadius: 4,
                  px: 1,
                  backgroundColor: "#00AA38",
                  display: { xs: "none", sm: "inline" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#ffffff",
                  }}
                >
                  {mailBoxStatus== "connected" ? connectedAccounts : totalMailboxes - connectedAccounts} /
                  <span style={{ fontWeight: "500", opacity: 0.6 }}>
                    {totalMailboxes || 0}
                  </span>
                </Typography>
              </Box> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "none" },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#111927",
                  }}
                >
                  Linked Accounts
                </Typography>
                <Box
                  sx={{
                    borderRadius: 4,
                    px: 1,
                    backgroundColor: "#00AA38",
                    display: "inline",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "14px",
                      color: "#ffffff",
                    }}
                  >
                    {/* {mailBoxStatus== "connected" ? connectedAccounts : totalMailboxes - connectedAccounts} / */}
                    <span style={{ fontWeight: "500", opacity: 0.6 }}>
                      {totalMailboxes || 0}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Stack rowGap={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                placeholder="Search by email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined sx={{ color: theme.palette.text.primary, ml: "5px" }} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                sx={{
                  width: { xs: "80%", md: 300 },
                  height: 40,
                  backgroundColor: "white",
                  "& div": { pl: 0.3 },
                  "& div fieldset": {
                    borderRadius: "8px",
                    border: "1px solid #E4E4E5",
                  },
                  "& div input": {
                    py: 1.3,
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                    "&::placeholder": {
                      color: theme.palette.text.primary,
                    },
                  },
                }}
                value={search}
                onChange={handleSearchChange}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* connect disconnect filter dropdown */}
                <FormControl sx={{
                  // remove focus effect
                  width: "150px",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent"

                  },
                }}>
                  <InputLabel id="demo-simple-select-label">Connection Status</InputLabel>
                  <Select
                    // labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    label="Connection Status"
                    value={mailBoxStatus}
                    onChange={handleMailBoxFilterChange}
                    size="small"
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"connected"}>Connected</MenuItem>
                    <MenuItem value={"disconnected"}>Disconnected</MenuItem>
                  </Select>
                </FormControl>
                {/* <Button
                  sx={{
                    borderRadius: 1,
                    borderColor: "#ECE9F5",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#111927",
                    px: { xs: 1, sm: 2 },
                    backgroundColor: "#ffffff",
                    "&:hover": {
                      borderColor: "#ECE9F5",
                      backgroundColor: "#ffffff",
                    },
                  }}
                  variant="outlined"
                  disableRipple
                  endIcon={expandAll ? <ExpandLess /> : <ExpandMore />}
                  onClick={handleExpandAll}
                >
                  {expandAll ? "Shrink All" : "Expand All"}
                </Button> */}
                {selected.length > 0 ? (
                  <Button
                    variant="contained"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                      borderRadius: 1,
                      backgroundColor: "#5761FE",
                    }}
                    onClick={() => handleClickOpen("delete")}
                  >
                    <DeleteOutline />
                    {!isSmDown && <Typography>Delete</Typography>}{" "}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      display: userType === "enterprise" ? "flex" : "none",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                      borderRadius: 1,
                    }}
                    onClick={() => handleClickOpen("add")}
                  >
                    <AddOutlined />
                    {!isSmDown && (
                      <Typography style={{ fontSize: "14px" }}>
                        Add New
                      </Typography>
                    )}{" "}
                  </Button>
                )}
              </Box>
            </Box>

            {!loading && mailAccounts.length > 0 ? (
              <Stack
                rowGap={2}
                sx={{
                  minHeight: "300px",
                  // maxHeight: { xs: "90vh", md: "70vh" },
                  // overflow: "auto",
                  position: "relative",
                  ...scrollBarStyle,
                }}
              >
                {paginatedAccounts.length > 0 ? (
                  paginatedAccounts.map((acc, index) => (
                    <MailItem
                      index={index}
                      acc={acc}
                      selected={selected}
                      handleChange={handleChange}
                      expandAll={expandAll}
                      userType={userType}
                      setOpenSnackbar={setOpenSnackbar}
                      fetchAllMailBoxes={fetchAllMailBoxes}
                      userEmail={userEmail}
                    />
                  ))
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      No Results
                    </Typography>
                  </Box>
                )}
              </Stack>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography sx={{ textAlign: "center" }}>
                    No  {mailBoxStatus?.charAt(0)?.toUpperCase() + mailBoxStatus?.slice(1)} Accounts
                  </Typography>
                )}
              </Box>
            )}
            {!loading && paginatedAccounts.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 1,
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    Rows per page
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={limit}
                    //label="Rows per page"
                    onChange={handleLimitChange}
                    size="small"
                    sx={{ "& .MuiSelect-select": { width: "30px", py: "4px" } }}
                    variant="outlined"
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </Box>
              </Box>
            )}
          </Stack>
        </div>

        <Dialog open={open} maxWidth="md" onClose={handleClose} fullScreen={isSmDown}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {isSMTP ? <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "20px",
                mb: 1.5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Connect a New Email Account
            </Typography>
              : <Typography variant="h6">
                {dialogType === "add"
                  ? "Connect Your Account"
                  : dialogType === "delete"
                    ? "Delete Account"
                    : ""}{" "}
              </Typography>}
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              style={{
                marginLeft: "10px",
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            {dialogType === "add" ? (
              <>
                <ConnectNewAccount
                  handleConnectGoogleAccount={handleConnectGoogleAccount}
                  handleConnectMicrosoftAccount={handleConnectMicrosoftAccount}
                  handleConnectEmailProvider={handleConnectEmailProvider}
                  showInstructions={true}
                  setIsSMTP={setIsSMTP}
                />
                {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  width: "100%",
                }}
              >
                <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
                  <Button
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      width: { xs: "100px", sm: "auto" },
                      height: { xs: "100px", sm: "auto" },
                    }}
                    onClick={handleConnectGoogleAccount}
                  >
                    <GmailIcon />
                  </Button>

                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Google Workspace
                  </Typography>
                </Stack>
                <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
                  <Button
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      width: { xs: "100px", sm: "auto" },
                      height: { xs: "100px", sm: "auto" },
                      // height: "144px",
                      // width:'144px'
                    }}
                    onClick={handleConnectMicrosoftAccount}
                  >
                    <MicrosoftIcon />
                  </Button>

                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Microsoft
                  </Typography>
                </Stack>
              </Box> */}
              </>
            ) : dialogType === "delete" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Typography>
                  Are you sure you want to delete the
                  {selected.length > 1
                    ? ` ${selected.length} accounts`
                    : " account"}
                  ?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ borderRadius: 1 }}
                    color="error"
                    onClick={handleClose}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: 1 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          ContentProps={{
            sx: {
              background: "#5761FE",
            },
          }}
          open={openSnackbar}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
          message="Copied  Successfully!"
        />
      </Box>
    </>
  );
};

export default EmailAccounts;
