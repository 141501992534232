import React, { createContext, useEffect, useState } from "react";
import { API_ADMIN_SERVICE_BACKEND, API_SERVICE_BACKEND } from "src/config";
import B2BRocketLogo from "../assets/blue icon square.png";
import B2BFaviconLogo from "../assets/favicon.ico";
import toast from "react-hot-toast";

// Create the context
export const WhiteLabelConfigurationContext = createContext();
const WhiteLabelProvider = ({ children }) => {
  const [companyName, setCompanyName] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [faviconUrl, setFaviconUrl] = useState("");
  const [primaryBrandColor, setPrimaryBrandColor] = useState("");
  const [secondaryBrandColor, setSecondaryBrandColor] = useState("");
  const [primaryTextColor, setPrimaryTextColor] = useState("");
  const [secondaryTextColor, setSecondaryTextColor] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [tagline, setTagline] = useState("");
  const [whiteLableStatus, setWhiteLableStatus] = useState(false);
  const [subUserStatus, setSubUserStatus] = useState(false);
  const [whiteLableData, setWhiteLableData] = useState("");
  const [whiteLableId, setWhiteLableId] = useState(null);
  const [domainsQuota, setDomainsQuota] = useState();
  const [whiteLabelQuota, setwhiteLabelQuota] = useState(null);
  const [staticDomainValidation, setStaticDomainValidation] = useState(false);
  const [customEmailConfigDomain, setCustomEmailConfigDomain] = useState(null);
  const getDomainFromLocation = () => {
    const domain = window.location.hostname;
    return domain;
  };
  
  const domain = getDomainFromLocation();
  const getConfigDetails = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getWhiteLabelDomainConfig?domain=${domain}`
      );
      const data = await response.json();
      if (response.ok) {
        setCompanyName(data?.domainConfig?.info?.companyName ?? "B2B Rocket");
        // Default values in case logoUrl and favIcon are missing or empty
        setLogoUrl(data?.domainConfig?.info?.logoUrl || B2BRocketLogo);
        setFaviconUrl(data?.domainConfig?.info?.favIcon || B2BFaviconLogo);

        setPrimaryBrandColor(data?.domainConfig?.theme?.primaryBrandColor || "#004EEB");
        setSecondaryBrandColor(
          data?.domainConfig?.theme?.secondaryBrandColor || "#5862ff"
        );
        setPrimaryTextColor(data?.domainConfig?.theme?.primaryTextColor || "#000000");
        setSecondaryTextColor(data?.domainConfig?.theme?.secondaryTextColor || "#6C737F");

      setMetaTitle(data?.domainConfig?.seo?.title || "B2B Rocket");
      setMetaDescription(data?.domainConfig?.seo?.description || "description");
      setTagline(data?.domainConfig?.seo?.tagLine || "tagLine");
      }
      else{
      setCompanyName("B2B Rocket");
 
      setLogoUrl(B2BRocketLogo);
      setFaviconUrl(B2BFaviconLogo);
      setPrimaryBrandColor("#004EEB");
      setSecondaryBrandColor("#5862ff");
      setPrimaryTextColor("#000000");
      setSecondaryTextColor("#6C737F");
      setMetaTitle("B2B Rocket");
      setMetaDescription("description");
      setTagline("tagLine");
      }

    } catch (err) {
      setCompanyName("B2B Rocket");
      setLogoUrl(B2BRocketLogo);
      setFaviconUrl(B2BFaviconLogo);
      setPrimaryBrandColor("#004EEB");
      setSecondaryBrandColor("#5862ff");
      setPrimaryTextColor("#000000");
      setSecondaryTextColor("#6C737F");
      setMetaTitle("B2B Rocket");
      setMetaDescription("description");
      setTagline("tagLine");
      console.log("API fetch error:", err);
      // In case of an error during the fetch, set the default values
    }
  };

  const getUserDetails = async () => {
    try {
      const response = await fetch(
        `${API_ADMIN_SERVICE_BACKEND}/getuserdetail/${sessionStorage.getItem(
          "userEmail"
        )}`
      );
      const data = await response.json();
      setWhiteLableData(data?.data);
      setWhiteLableId(data?.data?.isWhiteLabel ? data?.data?._id : data?.data?.whiteLabelUserId)
      setWhiteLableStatus(data?.data?.isWhiteLabel ? true : false);
      setwhiteLabelQuota(data?.data?.subscription?.subscriptions?.base?.usersQuota ? data?.data?.subscription?.subscriptions?.base?.usersQuota : 5)
    } catch (err) {
      console.log("API fetch error:", err);
      // In case of an error during the fetch, set the default values
    }
  };
  const getUserInfo = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getuserdetail/${sessionStorage.getItem(
          "userEmail"
        )}`,
        {
          method: "GET",
        }
      );

      const data = await response.json();
      const domainsQuota = data?.data?.userSubscription?.subscriptions?.base?.domainsQuota;
      const subUserStatusValue = data?.data?.whiteLabelUserId ? true : false;
      if (!whiteLableId) {
        setWhiteLableId(data?.data?.isWhiteLabel ? data?.data?._id : data?.data?.whiteLabelUserId)
      }
      setSubUserStatus(data?.data?.whiteLabelUserId ? true : false);

      localStorage.setItem("subUserStatus", JSON.stringify(subUserStatusValue));

      if (domainsQuota !== undefined) {
        setStaticDomainValidation(false);
        setDomainsQuota(domainsQuota);
      } else {
        setStaticDomainValidation(true);
      }
    } catch (error) {
      console.log(error)
    }
  };
  const getAllWhiteLabelClientConfigurations = async (id) => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getwhiteLabelConfig/${id}`
      );
      const data = await response.json();
      // Check if logoUrl and favIcon are empty strings and set default values
      setCompanyName(data?.data?.info?.companyName ?? "B2B Rocket");
      setCustomEmailConfigDomain(data?.data?.domain)
      // Default values in case logoUrl and favIcon are missing or empty
      setLogoUrl(data?.data?.info?.logoUrl || B2BRocketLogo);
      setFaviconUrl(data?.data?.info?.favIcon || B2BFaviconLogo);

      setPrimaryBrandColor(data?.data?.theme?.primaryBrandColor || "#004EEB");
      setSecondaryBrandColor(
        data?.data?.theme?.secondaryBrandColor || "#5862ff"
      );
      setPrimaryTextColor(data?.data?.theme?.primaryTextColor || "#000000");
      setSecondaryTextColor(data?.data?.theme?.secondaryTextColor || "#6C737F");

      setMetaTitle(data?.data?.seo?.title || "B2B Rocket");
      setMetaDescription(data?.data?.seo?.description || "description");
      setTagline(data?.data?.seo?.tagLine || "tagLine");
    } catch (err) {
      console.log("API fetch error:", err);

      // Set default values in case of error
      setCompanyName("B2B Rocket");
      setLogoUrl(B2BRocketLogo);
      setFaviconUrl(B2BFaviconLogo);
      setPrimaryBrandColor("#004EEB");
      setSecondaryBrandColor("#5862ff");
      setPrimaryTextColor("#000000");
      setSecondaryTextColor("#6C737F");
      setMetaTitle("B2B Rocket");
      setMetaDescription("description");
      setTagline("tagLine");
    }
  };

  useEffect(() => {
    getUserDetails();
    getUserInfo();
    getConfigDetails();
  }, []);
  useEffect(() => {
    if (whiteLableId) {
      getAllWhiteLabelClientConfigurations(whiteLableId)
    }
  }, [whiteLableData, whiteLableId]);

  return (
    <WhiteLabelConfigurationContext.Provider
      value={{
        companyName,
        setCompanyName,
        logoUrl,
        getUserDetails,
        setLogoUrl,
        primaryBrandColor,
        setPrimaryBrandColor,
        secondaryBrandColor,
        setSecondaryBrandColor,
        whiteLableStatus,
        primaryTextColor,
        setPrimaryTextColor,
        secondaryTextColor,
        setSecondaryTextColor,
        metaTitle,
        faviconUrl,
        subUserStatus,
        whiteLableData,
        setMetaTitle,
        customEmailConfigDomain,
        whiteLableId,
        metaDescription,
        setMetaDescription,
        whiteLabelQuota,
        getAllWhiteLabelClientConfigurations,
        tagline,
        setTagline,
        getUserInfo,
        domainsQuota,
        staticDomainValidation
      }}
    >
      {children}
    </WhiteLabelConfigurationContext.Provider>
  );
};

export default WhiteLabelProvider;
