import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-hot-toast";

import React, { useState } from "react";
import moment from "moment";
import ConfirmDialog from "./ConfirmDialog";
import { API_SERVICE_BACKEND } from "src/config";
const SubscriptionsModal = ({ setOpen, open }) => {
  const handleClose = () => {
    setOpen({ open: false, data: null });
  };
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    data: null,
  });

  const handleCancel = async () => {
    const requestData = {
      userEmail: open?.data?.white_label_plans?.userEmail,
      whiteLabelUserId: open?.data?.whiteLabelUserId,
      subscriptionId: open?.data?.white_label_plans?.subscriptions?.base?.id,
      cancelImmediately: openDeleteModal?.data,
    };
    const url = `${API_SERVICE_BACKEND}/cancelWhiteLabelUserSubscription`;
    const method = "POST";

    try {
      const response = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();

      if (response.ok) {
        setOpenDeleteModal({ open: false, data: null });
        handleClose();
      } else {
        toast.error(data.error || "Failed to connect");
      }
    } catch (error) {
      toast.error("Error submitting data");
    }
  };

  return (
    <>
      <Dialog open={open.open} onClose={handleClose} maxWidth="sm">
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Subscription</Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {(open?.data?.white_label_plans?.subscriptions?.base
                ?.cancelled &&  open?.data?.white_label_plans?.planStatus === "active") ? (
                <Alert variant="outlined" severity="warning">
                  Subscription has been canceled and will remain active until {" "}
                  {moment(
                    open.data.white_label_plans.subscriptions.base.endDate
                  ).format("DD-MM-YYYY")}
                  .
                </Alert>
              ) : (open?.data?.white_label_plans?.subscriptions?.base
                ?.cancelled &&  open?.data?.white_label_plans?.planStatus !== "active") ? <Alert variant="outlined" severity="error">
                Subscription has been expired.
              </Alert> : ""}
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography>Package Name</Typography>
              </Box>
              <Box>
                <Typography>
                  {open?.data?.white_label_plans?.subscriptions?.base
                    ?.packageName || "N/A"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography>Start Date</Typography>
              </Box>
              <Box>
                <Typography>
                  {open?.data?.white_label_plans?.subscriptions?.base?.startDate
                    ? moment(
                        open.data.white_label_plans.subscriptions.base.startDate
                      ).format("DD-MM-YYYY")
                    : "N/A"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography>End Date</Typography>
              </Box>
              <Box>
                <Typography>
                  {open?.data?.white_label_plans?.subscriptions?.base?.endDate
                    ? moment(
                        open.data.white_label_plans.subscriptions.base.endDate
                      ).format("DD-MM-YYYY")
                    : "N/A"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {open?.data?.white_label_plans?.subscriptions?.base?.id?.startsWith('sub_') && <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  width: "100%",
                }}
              >
                {/* <Button
                disabled={
                  open?.data?.white_label_plans?.planStatus === "active" ? false : true
                }
                  variant="contained"
                  sx={{
                    width: "100%",
                    flexGrow: 1,
                    textTransform: "none",
                  }}
                  onClick={() => setOpenDeleteModal({ open: true, data: true })}
                >
                  Cancel Immediately
                </Button> */}
                <Button
                  variant="contained"
                  disabled={
                    open?.data?.white_label_plans?.subscriptions?.base
                      ?.cancelled
                  }
                  sx={{
                    width: "100%",
                    flexGrow: 1,
                    textTransform: "none",
                  }}
                  onClick={() =>
                    setOpenDeleteModal({ open: true, data: false })
                  }
                >
                  {/* End of the current period */}
                  Cancel Subscription
                </Button>
              </Box>}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        handleDelete={handleCancel}
        name={
          openDeleteModal.data === true
            ? "Are you sure you want to cancel the subscription immediately"
            : "The subscription will be scheduled for cancellation at the end of the subscription cycle. Are you sure you want to proceed?"
        }
        setOpen={setOpenDeleteModal}
        open={openDeleteModal}
      />
    </>
  );
};

export default SubscriptionsModal;
