import { useEffect, useState } from "react";
import {
  TableContainer,
  Box,
  Table,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Pagination,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import { LanguageOutlined } from "@mui/icons-material";
import moment from "moment";
import Socialicon from "src/pages/dashboard/emailAccounts/icons/socialicon.png";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { paths } from "src/paths";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import axios from "axios";
import { API_SERVICE_BACKEND } from "src/config";

const scrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: "14px",
    height: "14px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "60px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#E4E4E5",
    borderRadius: "10px",
    border: "4px solid rgba(0, 0, 0, 0)",
    backgroundClip: "padding-box",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#d5d5d5",
  },
};

const Viewdomain = () => {
  const location = useLocation();
  const { email } = queryString.parse(location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [userData, setUserData] = useState([]);


  const theme = useTheme();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const getDomain = async (email) => {
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/DFYInfraSetup/getUserOrders?email=${email}&page=${page}&limit=${rowsPerPage}`
      );
      if (!res?.data.status) return;
      setUserData(res?.data.data);
      setTotalCount(res?.data?.total ?? 0);
      setIsLoading(false);
      return res.data.data;
    } catch (error) {
      setIsLoading(false);
      return;
    }
  };
  useEffect(()=>{
    getDomain()
  },[email])
  return (
    <>
      <Box sx={{ paddingX: "30px" }}>
        <Box sx={{ display: "flex", alignItems: "center", mt: "20px", mb:'30px' ,justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <Button
              variant="outlined"
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={() => navigate(paths.dashboard.whiteLable.user)}
            >
              Back
            </Button>
          
          </Box>
        </Box>
        <Box>
        <Typography
              sx={{ fontSize: "32px", fontWeight: 700, color: "#111927" }}
            >
              View Domain
            </Typography>
        </Box>
        <Box sx={{ textAlign: "end", alignItems: "center" }}>
          <Typography variant="subtitle1" sx={{ alignItems: "center" }}>
            Total domain income.
          </Typography>
        </Box>
        <TableContainer sx={{ borderRadius: "0px", ...scrollBarStyle }}>
          <Table
            sx={{
              height: "fit-content",
              minWidth: "100%",
              borderCollapse: "separate",
              borderSpacing: "0px 15px !important ",
            }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead sx={{ background: "#E9E9EC", borderRadius: "10px" }}>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    backgroundColor: "#E9E9EC",
                    borderBottom: "1px solid #E9E9EC",
                    color: theme.palette.text.primary,
                  },
                  "& .MuiTableCell-root::after": {
                    content: '""',
                    position: "absolute",
                    top: 8,
                    right: 0,
                    bottom: 18,
                    borderRight: "2px solid #CECED3",
                    height: "50%",
                    backgroundColor: "#CECED3",
                  },
                  "& .MuiTableCell-root:last-child::after": {
                    content: "none",
                  },
                }}
              >
                <TableCell align="left">Domain Name</TableCell>
                <TableCell align="left">Provider</TableCell>
                <TableCell align="left">Sub Domain</TableCell>
                <TableCell align="left">Forward Domain</TableCell>
                <TableCell align="left">Purchase Date</TableCell>
              </TableRow>
            </TableHead>
            {!isLoading && userData.length > 0 ? (
              <TableBody>
                {userData.map((data, index) => {
                  return (
                    <TableRow
                      key={index}

                      sx={{
                        cursor: "pointer",
                        borderRadius: "10px",
                        background: "white",
                        marginBottom: "10px",
                        "& td": {
                          borderTop: "1px solid rgba(224, 224, 229, 1)",
                          borderBottom: "1px solid rgba(224, 224, 229, 1)",
                          borderRight: "none",
                        },
                        "& td:first-child": {
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                          borderLeft: "1px solid rgba(224, 224, 229, 1)",
                          borderRight: "none",
                        },
                        "& td:last-child": {
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderRight: "1px solid rgba(224, 224, 229, 1)",
                          borderLeft: "none",
                        },
                      }}
                    >
                      <TableCell
                        align="left"
                        sx={{ borderBottom: "none", width: "40%" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            fontSize: "500",
                          }}
                        >
                          <LanguageOutlined
                            sx={{
                              color: "rgba(88, 98, 255, 1)",
                              marginRight: "10px",
                            }}
                          />
                          {data.domain}
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "6px",
                            borderRadius: "10px",
                            p: "6px 12px",
                            border: "1px solid #E0E0E5",
                            width: "fit-content",
                          }}
                        >
                          {data.provider === "google" ? (
                            <>
                              <img src={Socialicon} />
                              <Typography
                                sx={{
                                  fontSize: "0.875rem",
                                  fontWeight: "500",
                                }}
                              >
                                Google
                              </Typography>
                            </>
                          ) : null}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {data?.trackDomain ?? "-"}
                      </TableCell>
                      <TableCell align="left">
                        {data?.forwardDomain ?? "-"}
                      </TableCell>
                      <TableCell align="left">
                        {data.createdAt
                          ? moment(data.createdAt).format("DD-MM-YYYY")
                          : moment(data.startDate).format("DD-MM-YYYY")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <CircularProgress
                      sx={{ width: "20px", height: "20px", padding: "10px" }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: 500,
                        color: theme.palette.text.secondary,
                        textAlign: "center",
                      }}
                    >
                      No Domain Found
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Stack spacing={3}>
          {!isLoading && data?.users?.length ? (
            <Pagination
              count={Math.ceil(totalCount / rowsPerPage)}
              page={page}
              onChange={(event, page) => setPage(page)}
              showFirstButton
              showLastButton
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              size="large"
              sx={{ mt: 2, display: "flex", justifyContent: "center" }}
              rowsPerPage={rowsPerPage}
            />
          ) : (
            ""
          )}
        </Stack>
      </Box>

    </>
  );
};

export default Viewdomain;
