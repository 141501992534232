import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  Switch,
  styled,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getSessionStorage } from "src/utils/storage";
import toast from "react-hot-toast";
import { API_SERVICE_BACKEND } from "src/config";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#296cf1",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      backgroundColor: "#ffffff",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#33cf4d",
      backgroundColor: "#ffffff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
    backgroundColor: "#ffffff",
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// Add all fields from your dataset to initialFormState
const initialFormState = [
  { name: "plan_name", label: "Plan Name", value: "", type: "text" },
  { name: "EMAIL_LOOKUPS", label: "Email Lookups", value: 0, type: "number" },
  { name: "EMAIL_EXPORTS", label: "Email Exports", value: 0, type: "number" },
  { name: "PHONE_LOOKUPS", label: "Phone Lookups", value: 0, type: "number" },
  { name: "PHONE_EXPORTS", label: "Phone Exports", value: 0, type: "number" },
  { name: "EMAIL_SENDING", label: "Email Sending", value: 0, type: "number" },
  { name: "EMAIL_VALIDATION", label: "Email Validation", value: 0, type: "number" },
  { name: "MULTICHANNEL_OUTREACH", label: "Multichannel Outreach", value: 0, type: "number" },
  { name: "ACTIVE_CAMPAIGNS_CONTACTS", label: "Active Campaigns Contacts", value: 0, type: "number" },
  { name: "AI", label: "AI", value: 0, type: "number" },
  { name: "AI_BDR", label: "AI BDR", value: 0, type: "number" },
  { name: "MAILBOX_WARMUP", label: "Mailbox Warmup", value: 0, type: "number" },
  { name: "INTENT_DATA", label: "Intent Data", value: false, type: "switch" },
  { name: "DATA_ENRICH", label: "Data Enrichment", value: false, type: "switch" },
  { name: "DATA_CLEAN_UP", label: "Data Clean Up", value: false, type: "switch" },
  { name: "AI_AUTO_REPLIES", label: "AI Auto Replies", value: false, type: "switch" },
  { name: "CRM", label: "CRM", value: false, type: "switch" },
  { name: "UNIFIED_INBOX", label: "Unified Inbox", value: false, type: "switch" },
  { name: "UNIFIED_CALENDAR", label: "Unified Calendar", value: false, type: "switch" },
  { name: "MEETING_SCHEDULER", label: "Meeting Scheduler", value: false, type: "switch" },
];

const AddPlans = ({ setOpen, open, getAllWhiteLabelClients }) => {
  const email = getSessionStorage("userEmail");
  const [userProviderData, setUserProviderData] = useState(initialFormState);

  useEffect(() => {
    if (open.data) {
      const updatedData = userProviderData.map((field) => {
        // Check if the field exists in open.data and map it to the corresponding value
        if (field.name === "plan_name") {
          // Set the plan_name field value from open.data.planName
          return { ...field, value: open.data.planName !== undefined ? open.data.planName : field.value };
        }

        if (open.data.monthlyCredits.hasOwnProperty(field.name)) {
          const fieldValue = field.name.includes("monthlyCredits")
            ? open.data.monthlyCredits?.[field.name]
            : open.data.monthlyCredits?.[field.name];
          return { ...field, value: fieldValue !== undefined ? fieldValue : field.value };
        }

        return field;
      });

      console.log("updatedData", updatedData);
      setUserProviderData(updatedData);
    } else {
      setUserProviderData(initialFormState);
    }
  }, [open.data]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserProviderData((prev) =>
      prev.map((field) =>
        field.name === name
          ? { ...field, value: type === "checkbox" ? checked : value }
          : field
      )
    );
  };

  const handleSubmit = async () => {
    const formData = userProviderData.reduce((acc, { name, value }) => {
      acc[name] = value;
      return acc;
    }, {});
    const requestData = {
      userEmail: email,
      planName: formData.plan_name,
      monthlyCredits: {
        EMAIL_LOOKUPS: +formData.EMAIL_LOOKUPS,
        EMAIL_EXPORTS: +formData.EMAIL_EXPORTS,
        PHONE_LOOKUPS: +formData.PHONE_LOOKUPS,
        PHONE_EXPORTS: +formData.PHONE_EXPORTS,
        EMAIL_SENDING: +formData.EMAIL_SENDING,
        MULTICHANNEL_OUTREACH: +formData.MULTICHANNEL_OUTREACH,
        ACTIVE_CAMPAIGNS_CONTACTS: +formData.ACTIVE_CAMPAIGNS_CONTACTS,
        DATA_ENRICH: formData.DATA_ENRICH,
        EMAIL_VALIDATION: +formData.EMAIL_VALIDATION,
        DATA_CLEAN_UP: formData.DATA_CLEAN_UP,
        AI: +formData.AI,
        AI_BDR: +formData.AI_BDR,
        MAILBOX_WARMUP: +formData.MAILBOX_WARMUP,
        AI_AUTO_REPLIES: formData.AI_AUTO_REPLIES,
        CRM: formData.CRM,
        UNIFIED_INBOX: formData.UNIFIED_INBOX,
        UNIFIED_CALENDAR: formData.UNIFIED_CALENDAR,
        MEETING_SCHEDULER: formData.MEETING_SCHEDULER,
      }
    };

    const url = open.data ? `${API_SERVICE_BACKEND}/plans/${open.data._id}` : `${API_SERVICE_BACKEND}/plans`;
    const method = open.data ? "PUT" : "POST";
    console.log("requestDatarequestDatarequestData", requestData)
    try {
      const response = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      if (response.ok) {
        getAllWhiteLabelClients();
        toast.success(data.message || (open.data ? "Edit Plan successfully!" : "Add New Plan successfully!"));
        setOpen({ open: false, data: null });
        setUserProviderData(initialFormState);
      } else {
        toast.error(data.error || "Failed to connect");
      }
    } catch (error) {
      toast.error("Error submitting data");
    }
  };

  return (
    <Dialog open={open.open} onClose={() => setOpen({ open: false, data: null })}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h6">{open.data ? "Edit" : "Add"} Plan</Typography>
        <IconButton edge="start" color="inherit" onClick={() => setOpen({ open: false, data: null })}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        {/* <form> */}
          <Grid container spacing={2} >
            {userProviderData.map(({ name, label, value, type }, index) => (
              <Grid item xs={name === "plan_name" || name === 'MAILBOX_WARMUP' ? 12 : type === "switch" ? 4 : 6} key={index}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <FormLabel sx={{ fontSize: "14px", fontWeight: 700, color: "#28287B" }}>{label}</FormLabel>
                  {type === "number" ? (
                    <TextField name={name} value={value} onChange={handleChange} type="number" fullWidth variant="outlined" />
                  ) : type === "switch" ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IOSSwitch checked={value}
                        onChange={(e) => handleChange(e)}
                        name={name}
                        color="primary" />


                    </Box>
                  ) : (
                    <TextField name={name} value={value} onChange={handleChange} fullWidth variant="outlined" />
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        {/* </form> */}
      </DialogContent>
             <DialogActions>

            {/* <Grid item xs={12}> */}
              <Button onClick={handleSubmit} variant="contained" sx={{ width: "100%" }}>
                {open.data ? "Update" : "Create"}
              </Button>
            {/* </Grid> */}
             </DialogActions>
    </Dialog>
  );
};

export default AddPlans;
