import { useContext, useEffect, useState } from "react";
import {
  TableContainer,
  Box,
  InputAdornment,
  OutlinedInput,
  SvgIcon,
  Table,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Pagination,
  CircularProgress,
  styled,
  Switch,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  IconButton,
  ListItemIcon,
  Chip,
  Tabs,
  Tab,
  useTheme,
} from "@mui/material";

import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import { Stack } from "@mui/system";

import CreateUser from "src/components/whiteLable/CreateUser";
import {
  ContentCopy,
  DeleteOutline,
  Domain,
  LockReset,
  MoreVertOutlined,
  Subscriptions,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ConfirmDialog from "src/components/whiteLable/ConfirmDialog";
import { API_ADMIN_SERVICE_BACKEND, API_SERVICE_BACKEND } from "src/config";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import moment from "moment";
import toast from "react-hot-toast";
import { deleteUser, getAuth } from "firebase/auth";
import { getSessionStorage } from "src/utils/storage";
import DomainLimitModel from "src/components/whiteLable/DomainLimitModel";
import SubscriptionsModal from "src/components/whiteLable/SubscriptionsModal";
import ResetPasswordModal from "src/components/whiteLable/ResetPasswordModal";
import { Seo } from "src/components/seo";
import PaymentConfirmation from "src/components/whiteLable/PaymentConfirmation";
import { paths } from "src/paths";
import { useNavigate } from "react-router";
import { CustomDropdown } from "./statusList";
import axios from "axios";

const ITEM_HEIGHT = 48;
const scrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: "14px",
    height: "14px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "60px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#E4E4E5",
    borderRadius: "10px",
    border: "4px solid rgba(0, 0, 0, 0)",
    backgroundClip: "padding-box",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#d5d5d5",
  },
};
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));


const tabs = [
  {
    label: "Free",
    value: "free",
  },
  {
    label: "Paid",
    value: "paid",
  },
  {
    label: "Disable",
    value: "disable",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];
const ManageUser = () => {
  const theme = useTheme();

  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [userLimit, setUserLimit] = useState(null);
  const [open, setOpen] = useState({
    open: false,
    data: null,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    data: null,
    anchorEl: null,
  });
  const [openDomainLimitModal, setOpenDomainLimitModal] = useState({
    open: false,
    data: null,
    anchorEl: null,
  });

  const [openSubscriptionsModal, setOpenSubscriptionsModal] = useState({
    open: false,
    data: null,
    anchorEl: null,
  });
  const [openResetPassword, setOpenResetPassword] = useState({
    open: false,
    data: null,
    anchorEl: null,
  });
  const [paymentConfirmationModal, setPaymentConfirmationModal] = useState({
    open: false,
    data: null,
    anchorEl: null,
    planId: null,
  });
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [userPlanCount, setUserPlanCount] = useState(0);
  const {
    whiteLableData,
    customEmailConfigDomain,
    companyName,
    metaTitle,
    whiteLabelQuota,
  } = useContext(WhiteLabelConfigurationContext);
  console.log(customEmailConfigDomain, "staticDomainValidation");
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("free");

  const [data, setData] = useState([]);
  const [plan, setPlan] = useState([]);
  const handleClose = () => {
    setMenuState({
      anchorEl: null,
      open: false,
      data: null,
    });
  };


  const email = getSessionStorage("userEmail");

  // Fetch data from API to populate form
  const getAllWhiteLabelClients = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getUsersWhiteLabel?whiteLabelUserId=${
          whiteLableData?._id
        }&limit=${rowsPerPage}&page=${page}&search=${encodeURIComponent(
          searchText
        )}`
      );
      const data = await response.json();
      setIsLoading(false);
      setData(data);
      const count =
        data?.users?.filter((user) => user?.white_label_plans?.subscriptionId)
          ?.length || 0;
      setUserPlanCount(count);
      // setTotalCount(data?.meta?.totalUsers ?? 0);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const getAllWhiteLabelClientsPlan = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/plans?userEmail=${email}`
      );
      const data = await response.json();
      setPlan(data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    setUserLimit(whiteLabelQuota - totalCount);
  }, [totalCount, whiteLabelQuota]);
  useEffect(() => {
    setIsLoading(true);
    getAllWhiteLabelClients();
    getAllWhiteLabelClientsPlan();
  }, [whiteLableData?._id, searchText, page]);

  const handleDelete = async (data) => {
    const url = `${API_SERVICE_BACKEND}/removeUserWhiteLabel/${data._id}`;
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      if (response.ok) {
        getAllWhiteLabelClients();
        toast.success(data.message || "Delete user successfully!");
        setOpenDeleteModal({ open: false, data: null });
        handleClose();
      } else {
        toast.error(data.error || "Failed to connect");
      }
    } catch (error) {
      toast.error("Error submitting data");
    }
  };

  const handleAccountStatus = async (data) => {
    const requestData = {
      email: data?.email,
      accountStatus: data?.accountStatus === 1 ? 0 : 1,
    };
    try {
      const url = `${API_SERVICE_BACKEND}/updateUserWhiteLabelAccess`;
      const method = "PUT";

      const response = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      if (response.ok) {
        getAllWhiteLabelClients();
        toast.success(
          data.message ||
            `Account ${
              data?.data?.accountStatus === 1 ? "enabled" : "disabled"
            } successfully`
        );
      } else {
        toast.error(data.error || "Failed to connect");
      }
    } catch (error) {
      toast.error("Error");
    }
  };
  const handleAssignPlan = async (e, user) => {
    const planId = e.target.value;
    if (
      whiteLabelQuota &&
      !user.white_label_plans.subscriptionId &&
      userPlanCount >= whiteLabelQuota
    ) {
      setPaymentConfirmationModal({
        open: true,
        data: user,
        planId: planId,
      });
    } else {
      if (planId) {
        try {
          const requestData = {
            userEmail: user.email,
            planId,
            whiteLabelUserId: whiteLableData?._id,
          };

          const url = `${API_SERVICE_BACKEND}/plans/assign-plan`;
          const method = "POST";

          const response = await fetch(url, {
            method,
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestData),
          });

          const data = await response.json();
          if (response.ok) {
            getAllWhiteLabelClients();
            toast.success("Plan assigned successfully");
          } else {
            toast.error(data.error || "Failed to assign plan.");
          }
        } catch (error) {
          toast.error("Error assigning plan.");
        }
      }
    }
  };

  const [menuState, setMenuState] = useState({
    open: false,
    anchorEl: null,
    data: null, // Track the row-specific data here
  });

  const handleMenuOpen = (event, data) => {
    setMenuState({
      anchorEl: event.currentTarget,
      open: true,
      data: data,
    });
  };
  const openModal = (setter) => {
    setter({
      open: true,
      data: menuState.data,
      anchorEl: menuState.anchorEl,
    });
  };

  const [userStatusData, setUserStatusData] = useState({ free: [], paid: [] });
  console.log(" ~ ManageUser ~ userStatusData:", userStatusData);
  useEffect(() => {
    if (data?.users) {
      let filteredData = [];
      if (currentTab === "free") {
        // Filter data for free plans (excluding "sub_" subscriptions)
        filteredData = data?.users?.filter(
          (item) =>

            !item.white_label_plans?.subscriptions?.base?.id?.startsWith("sub_") && item?.white_label_plans?.planStatus === "active" && item.accountStatus === 1
        );
      } else if (currentTab === "paid") {
        // Filter data for paid plans (only "sub_" subscriptions)
        filteredData = data?.users?.filter((item) =>
          item.white_label_plans?.subscriptions?.base?.id?.startsWith("sub_")
        );
      } else if (currentTab === "disable") {
        // Filter data for paid plans (only "sub_" subscriptions)
        filteredData = data?.users?.filter(
          (item) => item.accountStatus === 0
        );
      } else if (currentTab === "inactive") {
        // Filter data for active plans (excluding non-active planStatus)
        filteredData = data?.users?.filter(
          (item) => item?.white_label_plans?.planStatus !== "active"
        );
      }
      setUserStatusData(filteredData);
      setTotalCount(filteredData?.length ?? 0);
    }
  }, [data, currentTab]);
  const handleTabChangeFromTabs = (e, newValue) => {
    setCurrentTab(newValue);
    setIsLoading(true);
    getAllWhiteLabelClients();
    getAllWhiteLabelClientsPlan();
    setPage(1);
  };

  const pageTitle = `Partner/White Label: Manage Accounts - ${
    metaTitle || companyName
  }`;

  const LOGIN_PATH = "/auth-main/login/v2";
  const buildMagicLink = (token) => {

    const baseUrl = `https://${customEmailConfigDomain}${LOGIN_PATH}`;

    // if (isAiAgents) {
    //   return `${baseUrl}?returnTo=/dashboard/contacts?campaignId=${row?._id}&token=${token}`;
    // } else {
    return `${baseUrl}?token=${token}`;
    // }
  };
  const generateJWTToken = async (user) => {
    let payload = {
      email: user?.email,
      adminUserEmail: window.sessionStorage.getItem("userEmail"),
    };

    try {
      const res = await axios.post(
        `${API_ADMIN_SERVICE_BACKEND}/getJWTAuthToken`,
        {
          payload,
        }
      );

      return res.data.token;
    } catch (err) {
      console.log(err);
      alert(`Generation token failed!`);
    }
  };
  const handleManageUser = async (user) => {
    if(!customEmailConfigDomain){
      toast.error("Please set a custom domain first.");
      return;
    }
    // !isAiAgents && getUserDetails(user?.email, user?.organization_id);
    let token = await generateJWTToken(user);
    const magicLink = buildMagicLink(token);
    window.open(magicLink);
  };
  const copyToClipboard = async (row) => {
    const token = await generateJWTToken(row);
    const magicLink = buildMagicLink(token);
    const link = await navigator.clipboard
      .writeText(magicLink)
      .then(() => {
        toast.success("Text copied to clipboard");
        return true;
      })
      .catch((error) => {
        toast.success("Error copying text to clipboard: " + error);
        return false;
      });
    return link ?? "";
  };
  return (
    <>
      <Seo title={pageTitle} />
      <Box sx={{ paddingX: "30px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
            }}
          >
            <Typography sx={{ fontSize: "32px", fontWeight: "700" }}>
              User
            </Typography>
            <Chip
              sx={{
                backgroundColor:
                  currentTab === "paid"
                    ? "#00AA38"
                    : currentTab === "inactive"
                    ? "red"
                    : currentTab === "free"
                    ? theme.palette.primary.main
                    : "#00AA38",
              }}
              label={
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: 1,
                    color: "#ffffff",
                  }}
                >
                  {totalCount}
                  {currentTab === "free" &&
                    whiteLableData?.subscription?.subscriptions?.base
                      ?.usersQuota && (
                      <>
                        /
                        {
                          whiteLableData?.subscription?.subscriptions?.base
                            ?.usersQuota
                        }
                      </>
                    )}
                </Typography>
              }
            />
          </Box>
          <Button
            variant="contained"
            onClick={() => setOpen({ open: true, data: null })}
            sx={{
              marginLeft: "auto",
              color: "#ffffff",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {" "}
            Add New User
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <OutlinedInput
            size="small"
            // disabled={loader}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <SvgIcon>
                  <SearchMdIcon />
                </SvgIcon>
              </InputAdornment>
            }
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            indicatorColor="primary"
            onChange={handleTabChangeFromTabs}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                sx={{ color: theme.palette.text.primary }}
              />
            ))}
          </Tabs>
          {whiteLableData?.subscription?.subscriptions?.base?.usersQuota && (
            <Box>
              <Typography variant="subtitle1">
                You can assign up to{" "}
                {whiteLableData?.subscription?.subscriptions?.base?.usersQuota}{" "}
                free plan for users.
              </Typography>
            </Box>
          )}
        </Box>
        <TableContainer sx={{ borderRadius: "0px", ...scrollBarStyle }}>
          <Table
            sx={{
              height: "fit-content",
              minWidth: "100%",
              borderCollapse: "separate",
              borderSpacing: "0px 15px !important ",
            }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead sx={{ background: "#E9E9EC", borderRadius: "10px" }}>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    backgroundColor: "#E9E9EC",
                    borderBottom: "1px solid #E9E9EC",
                    color: "#61616F",
                  },
                }}
              >
                <TableCell align="left" pl={5}>Magic Link</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }} align="left">
                  Name
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }} align="left">
                  Assign Plan
                </TableCell>

                <TableCell sx={{ whiteSpace: "nowrap" }} align="left">
                  Subscription Status
                </TableCell>
                {/* <TableCell sx={{ whiteSpace: "nowrap" }} align="left">
                  Validationg
                </TableCell> */}
                <TableCell sx={{ whiteSpace: "nowrap" }} align="left">
                  Created at
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }} align="left">
                  Last Login
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                  Account Status
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                  Action
                </TableCell>
                {/* <TableCell sx={{ whiteSpace: "nowrap" }} align="left">
                  Delete
                </TableCell> */}
              </TableRow>
            </TableHead>
            {!isLoading && userStatusData?.length > 0 ? (
              <TableBody>
                {userStatusData?.map((data, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        cursor: "pointer",
                        borderRadius: "10px",
                        background: "white",
                        marginBottom: "10px",
                        "& td": {
                          borderTop: "1px solid rgba(224, 224, 229, 1)",
                          borderBottom: "1px solid rgba(224, 224, 229, 1)",
                          borderRight: "none",
                        },
                        "& td:first-child": {
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                          borderLeft: "1px solid rgba(224, 224, 229, 1)",
                          borderRight: "none",
                        },
                        "& td:last-child": {
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          borderRight: "1px solid rgba(224, 224, 229, 1)",
                          borderLeft: "none",
                        },
                      }}
                    >
                      <TableCell align="left" sx={{ borderBottom: "none" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Button
                            onClick={() => {
                              handleManageUser(data);
                            }}
                          >
                            Manage
                          </Button>
                          <Button
                            onClick={async () => await copyToClipboard(data)}
                          >
                            <ContentCopy
                              fontSize="small"
                              sx={{
                                ml: 0.5,
                                width: "14px",
                              }}
                            />
                          </Button>
                        </Box>
                      </TableCell>
                      <TableCell align="left" sx={{ borderBottom: "none" }}>
                        {data?.email}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }} align="left">
                        {data?.name || "-"}
                      </TableCell>

                      <TableCell align="left">
                        {/* {data?.plan} */}
                        <FormControl sx={{ m: 1, minWidth: 130 }} size="small">
                          <InputLabel id="demo-select-small-label">
                            Choose Plan
                          </InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            defaultValue={
                              data?.white_label_plans?.subscriptionId
                            }
                            label="Plan"
                            onChange={(e) => handleAssignPlan(e, data)}
                          >
                            <MenuItem value="" disabled>
                              <em>Choose Plan</em>
                            </MenuItem>
                            {plan.length > 0 &&
                              plan.map((item) => (
                                <MenuItem value={item._id} key={item._id}>
                                  {item.planName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }} align="left">
                        <Chip
                          style={{
                            fontSize: "12px",
                            height: "25px",
                          }}
                          color={
                            data?.white_label_plans?.planStatus === "active"
                              ? "success"
                              : "error"
                          }
                          label={
                            data?.white_label_plans?.planStatus === "active"
                              ? "Active"
                              : "Inactive"
                          }
                        />
                        {data?.white_label_plans?.planStatus === "active" &&
                          (data?.white_label_plans?.subscriptions?.base?.id?.startsWith(
                            "sub_"
                          ) ? (
                            <Chip
                              style={{
                                fontSize: "12px",
                                height: "25px",
                              }}
                              sx={{ marginLeft: "5px" }}
                              color={"primary"}
                              label={"Stripe"}
                            />
                          ) : (
                            <Chip
                              style={{
                                fontSize: "12px",
                                height: "25px",
                              }}
                              sx={{ marginLeft: "5px" }}
                              color={"primary"}
                              label={"Free"}
                            />
                          ))}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }} align="left">
                        {moment(data.createdAt).format("D MMM YYYY hh:mm A")}
                      </TableCell>

                      {/* <TableCell align="left">
                        <IOSSwitch
                          sx={{ m: 1 }}
                          defaultChecked={data?.accountStatus === 1}
                          onChange={() => handleAccountStatus(data)}
                        />
                      </TableCell> */}
                      <TableCell sx={{ whiteSpace: "nowrap" }} align="left">
                        {/* Dec 20 2024 */}
                        {data.lastLoggedInAt
                          ? moment(data.lastLoggedInAt).format(
                              "D MMM YYYY hh:mm A"
                            )
                          : "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                          style={{
                            fontSize: "12px",
                            height: "25px",
                          }}
                          color={
                            data?.accountStatus === 0 ? "default" : "success"
                          }
                          label={
                            data?.accountStatus === 0 ? "Disable" : "Enable"
                          }
                        />
                      </TableCell>
                      {/* <TableCell align="left">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setOpenChangePasswordModal(true)}
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          Change Password
                        </Button>
                      </TableCell> */}

                      {/* <TableCell align="left">
                        <Button
                          onClick={() => setOpen({ open: true, data: data })}
                          variant="contained"
                          color="primary"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          Manage
                        </Button>
                      </TableCell> */}
                      <TableCell align="center">
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={
                            menuState.open ? "long-menu" : undefined
                          }
                          aria-expanded={menuState.open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(event) => handleMenuOpen(event, data)}
                        >
                          <MoreVertOutlined />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={menuState.anchorEl}
                          open={menuState.open}
                          onClose={handleClose}
                          slotProps={{
                            paper: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                              },
                            },
                          }}
                        >
                          <MenuItem>
                            <IOSSwitch
                              sx={{ m: 1 }}
                              defaultChecked={
                                menuState?.data?.accountStatus === 1
                              }
                              onChange={() =>
                                handleAccountStatus(menuState.data)
                              }
                            />
                            Status
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              openModal(setOpenDomainLimitModal);
                            }}
                          >
                            <ListItemIcon>
                              <Domain sx={{ fontSize: "20px", mr: 0.5 }} />
                            </ListItemIcon>
                            Assign Domain
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              navigate(
                                `${paths.dashboard.whiteLable.viewdomain}?email=${menuState.data.email}`
                              )
                            }
                          >
                            <ListItemIcon>
                              <VisibilityIcon
                                sx={{ fontSize: "20px", mr: 0.5 }}
                              />
                            </ListItemIcon>
                            View Domain
                          </MenuItem>
                          <MenuItem
                            onClick={() => openModal(setOpenSubscriptionsModal)}
                          >
                            <ListItemIcon>
                              <Subscriptions
                                sx={{ fontSize: "20px", mr: 0.5 }}
                              />
                            </ListItemIcon>
                            Subscription
                          </MenuItem>
                          <MenuItem
                            onClick={() => openModal(setOpenResetPassword)}
                          >
                            <ListItemIcon>
                              <LockReset sx={{ fontSize: "20px", mr: 0.5 }} />
                            </ListItemIcon>
                            Reset Password
                          </MenuItem>
                          <MenuItem
                            onClick={() => openModal(setOpenDeleteModal)}
                          >
                            <ListItemIcon>
                              <DeleteOutline
                                sx={{ fontSize: "20px", mr: 0.5 }}
                              />
                            </ListItemIcon>
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>

                      {/* <TableCell align="left">
                        <Button
                          onClick={() =>
                            setOpenDeleteModal({ open: true, data: data })
                          }
                          variant="contained"
                          color="error"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          Delete
                        </Button>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <CircularProgress
                      sx={{ width: "20px", height: "20px", padding: "10px" }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <Typography
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: 500,
                        color: "#61616F",
                        textAlign: "center",
                      }}
                    >
                      No User Found
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Stack spacing={3}>
          {!isLoading && userStatusData?.length && totalCount > 10 ? (
            <Pagination
              count={Math.ceil(totalCount / rowsPerPage)}
              page={page}
              onChange={(event, page) => setPage(page)}
              showFirstButton
              showLastButton
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              size="large"
              sx={{ mt: 2, display: "flex", justifyContent: "center" }}
              rowsPerPage={rowsPerPage}
            />
          ) : (
            ""
          )}
        </Stack>
      </Box>

      <CreateUser
        setOpen={setOpen}
        userLimit={userLimit}
        open={open}
        getAllWhiteLabelClients={getAllWhiteLabelClients}
      />
      <ConfirmDialog
        handleDelete={handleDelete}
        name={`  Are you sure you want to delete the user "${openDeleteModal?.data?.email}"`}
        setOpen={setOpenDeleteModal}
        open={openDeleteModal}
      />
      <PaymentConfirmation
        getAllWhiteLabelClients={getAllWhiteLabelClients}
        setOpen={setPaymentConfirmationModal}
        open={paymentConfirmationModal}
      />
      <DomainLimitModel
        close={handleClose}
        setOpen={setOpenDomainLimitModal}
        open={openDomainLimitModal}
        getAllWhiteLabelClients={getAllWhiteLabelClients}
      />
      <ResetPasswordModal
        setOpen={setOpenResetPassword}
        open={openResetPassword}
      />
      <SubscriptionsModal
        setOpen={setOpenSubscriptionsModal}
        open={openSubscriptionsModal}
      />
    </>
  );
};

export default ManageUser;
