import React, { useState, useRef } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const UploadFile = ({selectedFile,setStatus,status,debouncedSubmit, setSelectedFile}) => {

  const fileInputRef = useRef(null);

  const acceptedFileTypesString = ".jpg,.jpeg,.png";

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFile(file);
   
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleFile(file);

  };

  const handleFile = (file) => {
    setSelectedFile(file);
    debouncedSubmit();
    return () => debouncedSubmit.cancel();
  };

  const handleFileDelete = () => {
    setSelectedFile(null);
    debouncedSubmit();
    return () => debouncedSubmit.cancel();
  };

  

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box>
      {/* Drag-and-Drop Section */}
      {selectedFile ? (
        <Box
          border={2}
          borderColor="grey.300"
          borderRadius="16px"
          minHeight="170px"
          maxHeight="170px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <Box display="flex" alignItems="center">
            {typeof selectedFile === 'string' ? (
                <img
                  src={selectedFile}  // If it's a URL, just display it
                  alt="File Icon"
                  style={{ width: "134px", height: "134px" }}
                />
              ) : (
                <img
                  src={URL.createObjectURL(selectedFile)}  // If it's a file, use object URL
                  alt="File Icon"
                  style={{ width: "134px", height: "134px" }}
                />
              )}
            </Box>
            <IconButton edge="end" color="error" onClick={handleFileDelete}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          border={"2px dashed"}
          borderColor="grey.300"
          borderRadius="16px"
          minHeight="170px"
          maxHeight="170px"
          overflow="auto"
          p={2}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => handleDrop(e)}
        >
          <Box textAlign="center">
            <CloudUploadIcon color="grey.300" />
            <Typography variant="body1" fontWeight="600">
              Drag and Drop the file
            </Typography>
            <Typography variant="body1" fontWeight="700">
              or
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleCustomButtonClick}
            >
              Upload File
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              accept={acceptedFileTypesString}
              style={{ display: "none" }}
              onChange={handleFileChange}
              onClick={(e) => (e.target.value = null)} // To allow re-uploading same file
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UploadFile;
