import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useContext , useState } from "react";
import toast from "react-hot-toast";
import { API_SERVICE_BACKEND } from "src/config";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";

const PaymentConfirmation = ({ setOpen, open , getAllWhiteLabelClients }) => {
  const [isLoading , setIsLoading] = useState(false)
  const { whiteLableData } = useContext(
      WhiteLabelConfigurationContext
    );
  const handleClose = () => {
    setOpen({ open: false, data: null,planId:null });
    getAllWhiteLabelClients();
  };

  const handleConfirm = async() => {
    setIsLoading(true)
    try {
      const requestData = {
        userEmail: open.data.email,
        planId:open.planId,
        whiteLabelUserId: whiteLableData?._id,
      };

      const url = `${API_SERVICE_BACKEND}/plans/assign-plan`;
      const method = "POST";

      const response = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      if (response.ok) {
        getAllWhiteLabelClients();
        toast.success("Plan assigned successfully");
        setIsLoading(false);
      } else {
        toast.error(data.error || "Failed to assign plan.");
        setIsLoading(false);
      }
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error("Error assigning plan.");
    }
  
  };

  return (
    <Dialog open={open.open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Payment Confirm</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>
        To proceed with the selected plan, payment is required. Please confirm if you want to continue with the payment.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button onClick={() => handleConfirm()} color="primary" variant="contained">
          {isLoading && <CircularProgress size={20} mr={1}/>}
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentConfirmation;
