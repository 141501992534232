import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField
} from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { API_SERVICE_BACKEND } from "src/config";

const DomainLimitModel = ({ setOpen,  close, getAllWhiteLabelClients,open }) => {
  const [domainLimit, setDomainLimit] = useState(0);
  useEffect(()=>{
    if(open?.data?.white_label_plans?.subscriptions?.base?.domainsQuota){
      setDomainLimit(open?.data?.white_label_plans?.subscriptions?.base?.domainsQuota)
    }
  },[open?.data?.white_label_plans?.subscriptions?.base?.domainsQuota])
  const handleClose = () => {
    setOpen({ open: false, data: null })
    close();
  };

  const handleDomainLimit = async () => {
    const { email } = open.data;

    if (!domainLimit) {
      console.log("show the error");
    } else {
      try {
        const requestData = {
          userEmail: email,
          domainsQuota: Number(domainLimit)
        }
        const response = await fetch(`${API_SERVICE_BACKEND}/plans/assign-domains-quota`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });
        if (response.ok) {
          toast.success("Domain Limit added successfully!");
          getAllWhiteLabelClients();
          setDomainLimit(0);
          handleClose();
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.message || "Failed to add domain limit."}`);
        }
      } catch (error) {
        toast.error(error.message || "An unexpected error occurred.");
      }
    }
  };

  return (
    <Dialog open={open.open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Assign Domain Limit</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <TextField
          label="Enter Domain Limit"
          variant="outlined"
          fullWidth
          type="number"
          value={domainLimit}
          onChange={(e) => setDomainLimit(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleDomainLimit} color="error" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DomainLimitModel;
